// @flow
import * as React from 'react'
import { HotKeys } from 'react-hotkeys'

function _belongsTo(element, ancestor) {
  if (element === null || element === document) {
    return false
  }

  if (element === ancestor) {
    return true
  }

  return _belongsTo(element.parentNode, ancestor)
}

type Props = {
  children: any
}

export default class GlobalHotkeys extends React.Component<Props> {
  hotKeys: any

  componentDidMount() {
    // This override of the stopCallback function is needed because of a bug/
    // different opinion on correct behaviour of hotkeys. The default implementation
    // ignores all events when focussing on an input. Our implementation only ignores
    // those if they don't contain a modifier key, so you can have special hotkeys
    // functioning as expected but are still able to type.
    // See: https://github.com/greena13/react-hotkeys/issues/100#issuecomment-382297439
    const mousetrap = this.hotKeys.__mousetrap__
    // eslint-disable-next-line
    mousetrap.__proto__.stopCallback = (e, element) => {
      // if the element has the class "mousetrap" then no need to stop
      if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
        return false
      }

      if (_belongsTo(element, e.target)) {
        return false
      }

      // never stop hotkeys when command or control are used.
      if (e.metaKey || e.ctrlKey) return false

      // stop for input, select, and textarea
      return (
        element.tagName === 'INPUT' ||
        element.tagName === 'SELECT' ||
        element.tagName === 'TEXTAREA' ||
        element.isContentEditable
      )
    }
  }

  render() {
    return (
      <HotKeys
        keyMap={{ save: ['command+s', 'ctrl+s'] }}
        handlers={{
          save: ev => {
            if (
              ev.target.tagName === 'INPUT' ||
              ev.target.tagName === 'SELECT' ||
              ev.target.tagName === 'TEXTAREA' ||
              ev.target.isContentEditable
            ) {
              ev.target.blur()
            }
            ev.preventDefault()
          }
        }}
        ref={r => (this.hotKeys = r)}
        focused={true}
        target={window}
      >
        {this.props.children}
      </HotKeys>
    )
  }
}
