// @flow
import * as React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import RefreshLink from './RefreshLink'

import type { WithRouterProps } from '../../types'

type OwnProps = { persistor: any }
type Props = OwnProps & WithRouterProps
type NavLinkProps = {
  to: string,
  name: string,
  active: boolean,
  className?: ?string
}

const NavLink = ({ to, name, active, className, ...rest }: NavLinkProps) => (
  <li
    key={to}
    className={`nav_item ${className != null ? className : ''} ${
      active ? 'active' : ''
    }`}
    {...rest}
  >
    <Link className="nav-link" to={to}>
      {name}
    </Link>
  </li>
)

const Header = (props: Props) => {
  const currentRoute = '/' + props.location.pathname.split('/')[1]
  return (
    <nav className="main-menu-navbar flex-shrink-0">
      <div className="container">
        <ToastContainer />
        <Link className="navbar-brand" to="/">
          Jachthaven
        </Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav">
            {process.env.REACT_APP_FEATURE_BOATS === 'active' ? (
              <NavLink
                name="Boten"
                to="/boats"
                active={currentRoute === '/boats'}
              />
            ) : null}
            <NavLink
              name="Werkopdrachten"
              to="/work-orders"
              active={currentRoute === '/work-orders'}
            />
            <NavLink
              name="Rekeningen"
              to="/bills"
              active={currentRoute === '/bills'}
            />
          </ul>
          <ul className="navbar-nav ml-auto">
            <RefreshLink persistor={props.persistor} />
            <NavLink name="Uitloggen" to="/logout" active={false} />
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default withRouter<$Exact<OwnProps>>(Header)
