// @flow
import * as React from 'react'
import _ from 'lodash'
import TT from '../Tooltip'

type Props = {
  onBlur: (curr: number | null) => Promise<void>,
  placeholder?: string | number,
  value: ?number,
  className?: string,
  disabled: boolean,
  precision: { min: number, max: number },
  roundingPrecision: ?number,
  factor: number,
  allowNull: boolean,
  allowNegative: boolean,
  tip?: ?string,
  tipDelay?: ?number
}

type State = {
  value: ?string,
  loading: boolean
}

export default class NumberInput extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
    precision: { min: 0, max: 4 },
    roundingPrecision: null,
    factor: 1,
    allowNull: false,
    allowNegative: true
  }

  state = {
    // Note: this value should only NOT be null when the user is inputting
    // a new value. When the user is done, the state should ALWAYS be reset.
    value: null,
    loading: false
  }

  getValueFromProps(props: Props = this.props): ?string {
    const { value, factor } = props
    return this.numberToString(value, { factor: factor })
  }

  numberToString(value?: number | null, { factor = 1 }: { factor?: number }) {
    if (!_.isFinite(value) && this.props.allowNull) return ''
    if (!_.isFinite(value)) value = 0

    return (Number(value) / factor).toLocaleString('nl-NL', {
      minimumFractionDigits: this.props.precision.min,
      maximumFractionDigits: this.props.precision.max
    })
  }

  stringToNumber(str: string) {
    let lastDecimal = str.lastIndexOf(',')
    lastDecimal = lastDecimal >= 0 ? lastDecimal : str.length
    const decimalPart = str.substring(lastDecimal).replace(/[^0-9]*/g, '')
    const integerPart = str.substring(0, lastDecimal).replace(/[^0-9]*/g, '')
    const isNegative = this.props.allowNegative && str.indexOf('-') > -1

    const value = _.toNumber([integerPart, decimalPart].join('.'))
    return _.round(isNegative ? -value : value, this.props.precision.max)
  }

  async onBlur(str: string) {
    // Parse input as a number
    let current = Number(this.stringToNumber(str)) * this.props.factor
    if (this.props.roundingPrecision != null) {
      const { roundingPrecision } = this.props
      current = Math.round(current / roundingPrecision) * roundingPrecision
    }

    await new Promise(resolve => this.setState({ loading: true }, resolve()))

    // Case 1: No real input
    if (_.isEmpty(str) || _.isNil(current)) {
      // Return either null (if allowed) or zero
      if (this.props.allowNull) {
        await this.props.onBlur(null)
      } else {
        await this.props.onBlur(0)
      }
      return this.setState({ value: null, loading: false })
    }

    // Case 2: Unparsable input
    if (_.isNaN(current)) {
      // Just reset this field and let the user try again
      return this.setState({ value: null, loading: false })
    }

    // Case 3: actual number
    if (_.isFinite(current)) {
      // Robust check if the value actually changed, accounting for precision
      if (this.props.value != null) {
        const previous = Number(this.props.value)
        if (
          _.round(current, this.props.precision.max) !==
          _.round(previous, this.props.precision.max)
        )
          await this.props.onBlur(current)
      }

      await this.props.onBlur(current)
    }
    // Reset the local state
    return this.setState({ value: null, loading: false })
  }

  onEscape() {
    // Rest user input
    this.setState({ value: null })
  }

  onKeyDown({ keyCode }: SyntheticKeyboardEvent<HTMLInputElement>) {
    if (keyCode === 27) {
      // Escape key
      return this.onEscape()
    }
    if (keyCode === 13) {
      // Enter key
      return this.onBlur(this.state.value || '')
    }
  }

  render() {
    if (this.props.tip != null) {
      return (
        <TT
          tip={this.props.tip}
          delay={this.props.tipDelay || 0}
          render={id => this.renderInput(id)}
        />
      )
    } else {
      return this.renderInput()
    }
  }

  renderInput(id: ?string) {
    const value =
      this.state.value != null ? this.state.value : this.getValueFromProps()
    return (
      <input
        id={id}
        type="text"
        className={['form-control', 'number-input', this.props.className].join(
          ' '
        )}
        placeholder={
          typeof this.props.placeholder === 'number'
            ? this.numberToString(this.props.placeholder, {
                factor: this.props.factor
              })
            : this.props.placeholder
        }
        value={value}
        disabled={this.props.disabled || this.state.loading}
        onBlur={({ currentTarget: { value } }) => this.onBlur(value)}
        onChange={({ currentTarget: { value } }) => this.setState({ value })}
        onKeyDown={this.onKeyDown.bind(this)}
      />
    )
  }
}
