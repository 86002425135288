// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import Fuse from 'fuse.js'
import { Helmet } from 'react-helmet'
import SearchBar from '../Search/SearchBar'
import BillListItem from './BillListItem'
import BillListHeaders from './BillListHeaders'
import { orderByFromQuery } from '../../utils'

import type {
  Bill,
  BillLineItem,
  BillProgress,
  Boat,
  Customer,
  Dispatch,
  ListFilters,
  State as ReduxState,
  StoreSlice
} from '../../types'

type OwnProps = {||}
type S = {
  +bills: Bill[],
  +filters: ListFilters
}
type D = {
  setFilters: ListFilters => void
}
type Props = OwnProps & S & D

const progress = memoizeOne(
  (blis: ?(BillLineItem[])): BillProgress => {
    if (blis == null || _.size(blis) === 0) return 'empty'
    if (blis != null && _.every(blis, { state: 'invoiced' })) return 'invoiced'
    return 'open'
  }
)

class Bills extends React.Component<Props, {}> {
  handleFilterChoice(choice: ?BillProgress) {
    const current = this.props.filters.billProgress
    if (choice !== current) {
      const filters = { ...this.props.filters, billProgress: choice }
      return this.props.setFilters(filters)
    }
  }

  renderFilterTabs() {
    const tabState = this.props.filters.billProgress
    const tabs = [
      { name: 'Nog geen items', progress: 'empty' },
      { name: 'Te factureren', progress: 'open' },
      { name: 'Gefactureerd', progress: 'invoiced' },
      { name: 'Alles', progress: undefined }
    ]
    return (
      <ul className="nav nav-tabs mb-2">
        {_.map(tabs, ({ name, progress }) => (
          <li className="nav-item" key={progress || 'all'}>
            <div
              onClick={() => this.handleFilterChoice(progress)}
              className={_.compact([
                'nav-link clickable',
                tabState === progress ? 'active' : undefined
              ]).join(' ')}
            >
              {name}
            </div>
          </li>
        ))}
      </ul>
    )
  }
  render() {
    const { bills, filters } = this.props
    return (
      <div className="container">
        <Helmet title="Rekeningen" />
        <div className="d-flex align-items-center my-2 pt-2">
          <h1 className="m-0">Rekeningen</h1>
        </div>
        <div className="sticky-top bg-white pt-2">
          {this.renderFilterTabs()}
          <SearchBar
            filters={filters}
            setFilters={this.props.setFilters}
            placeholder="Filter op klant, boot, rekening..."
          />
          <BillListHeaders />
        </div>
        <div className="list bill-list">
          {_.map(bills, bill => (
            <BillListItem key={bill.id} billId={bill.id} bill={bill} />
          ))}
        </div>
      </div>
    )
  }
}

// Direct copy (atm) of BillSideList
const getFilterOptions = (filters: ListFilters) => {
  const filterableFields = ['customerId', 'boatId', 'state']
  return _.pick(filters, filterableFields)
}
const getSortOptions = orderByFromQuery({ defaultSort: 'openedOn' })
const getBills = memoizeOne((bills, filters, orderBy) =>
  _(bills)
    .filter(filters)
    .orderBy(...orderBy)
    .value()
)
const getFuseFilter = memoizeOne(
  (
    bills: StoreSlice<Bill>,
    boats: StoreSlice<Boat>,
    customers: StoreSlice<Customer>
  ) => {
    const list = _.map(bills, bill => ({
      ...bill,
      boat: boats[bill.boatId],
      customer: customers[bill.customerId]
    }))
    const options = {
      threshold: 0.25,
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      minMatchCharLength: 2,
      keys: [
        'name',
        'boat.name',
        'boat.boatType',
        'customer.firstName',
        'customer.lastNamePrefix',
        'customer.lastName',
        'customer.city',
        'customer.email',
        'customer.companyName'
      ]
    }
    return new Fuse<Bill>(list, options)
  }
)
const groupedBillLineItems = memoizeOne((workOrders, blis) =>
  _.mapValues(blis.idsByBill, ids => ids.map(id => blis.byId[id]))
)

const mapStateToProps = (state: ReduxState): S => {
  const filters = state.listFilters.bills
  const blisByBill = groupedBillLineItems(
    state.resources.bills,
    state.resources.billLineItems
  )
  const fuse = getFuseFilter(
    state.resources.bills,
    state.resources.boats,
    state.resources.customers
  )
  const { q, billProgress } = filters
  const queriedBills = q == null ? state.resources.bills : fuse.search(q)
  let bills = getBills(
    queriedBills,
    getFilterOptions(filters),
    getSortOptions(filters)
  )
  if (billProgress != null) {
    bills = _.pickBy(
      bills,
      ({ id }) => progress(blisByBill[id]) === billProgress
    )
  }
  return {
    bills,
    filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch): D => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'bills', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bills)
