// @flow
import * as React from 'react'
import BoatList from '../components/Boat/BoatList'
import BoatSideList from '../components/Boat/BoatSideList'
import BoatDetail from '../components/Boat/BoatDetail'

type Props = {
  boatId: ?string
}

const Boats = ({ boatId }: Props) => {
  if (boatId == null) return <BoatList />
  return (
    <div className="list-and-detail-container">
      <div className="side-list d-print-none">
        <BoatSideList activeBoatId={Number(boatId)} />
      </div>
      {boatId != null ? (
        <div className="detail-page">
          <BoatDetail boatId={Number(boatId)} />
        </div>
      ) : null}
    </div>
  )
}

export default Boats
