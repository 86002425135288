// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Select from 'react-select'

import { findStands } from '../../actions'

import type {
  BoatCategory,
  Dispatch,
  Stand,
  State as ReduxState,
  StandState
} from '../../types'
type OwnProps = {
  value: ?number,
  stands?: StandState,
  disabled?: ?boolean,
  onSelect: (number | null) => void,
  forBoatCategory: ?BoatCategory
}
type S = {
  stands: StandState
}
type D = {
  loadStands: () => any
}
type Props = OwnProps & S & D
type State = {
  isOpen: boolean
}

const isRestricted = (
  forBoatCategory: ?BoatCategory,
  restriction: ?BoatCategory
) => {
  if (forBoatCategory == null) return false
  if (restriction == null) return false
  if (forBoatCategory !== restriction) return true
  return false
}

class StandSelect extends React.Component<Props, State> {
  renderAvatar: Function
  renderSelector: Function
  static defaultProps = {
    disabled: false
  }

  componentDidMount() {
    this.props.loadStands()
  }

  handleSelect(stand: ?Stand) {
    if (stand == null) return this.props.onSelect(null)
    this.setState({ isOpen: false })
    this.props.onSelect(stand.id)
  }

  renderOption(stand: $Shape<Stand>) {
    const notAllowed = isRestricted(
      this.props.forBoatCategory,
      stand.categoryRestriction
    ) ? (
      <span className="ml-2 text-danger small">(Onjuist type)</span>
    ) : null
    return (
      <span>
        {stand.name}
        {notAllowed}
      </span>
    )
  }

  render() {
    return (
      <Select
        className="stand-select-selector"
        options={_.map(this.props.stands)}
        value={this.props.value}
        disabled={this.props.disabled ? this.props.disabled : false}
        onChange={this.handleSelect.bind(this)}
        onBlur={() => this.setState({ isOpen: false })}
        valueKey="id"
        labelKey="name"
        openOnFocus
        valueRenderer={this.renderOption.bind(this)}
        optionRenderer={this.renderOption.bind(this)}
      />
    )
  }
}

const mapState = (state: ReduxState, ownProps: OwnProps): S => {
  const stands =
    ownProps.stands != null ? ownProps.stands : state.resources.stands
  return { stands }
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  function loadStands() {
    if (ownProps.stands != null) return null
    return dispatch(findStands({ paginate: false }))
  }
  return { loadStands }
}

export default connect(
  mapState,
  mapDispatch
)(StandSelect)
