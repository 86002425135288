// @flow
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import { persistConfig } from './utils/persist'

import type { Store } from './types'
type Output = {
  store: Store,
  persistor: any
}

function configureStore(): Output {
  let store: Store
  let persistor

  const persistedReducer = persistReducer(persistConfig('root'), rootReducer)

  if (process.env.NODE_ENV === 'development') {
    // DEBUG
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const enhancer = composeEnhancers(applyMiddleware(thunk))
    // $FlowFixMe
    store = createStore(persistedReducer, enhancer)
    persistor = persistStore(store)

    // $FlowFixMe
    if (module && module.hot && typeof module.hot.accept === 'function') {
      module.hot.accept(() => {
        const nextRootReducer = require('./reducers')
          .default /* .default if you use Babel 6+ */
        // $FlowFixMe
        store.replaceReducer(persistReducer(persistConfig, nextRootReducer))
      })
    }
  } else {
    // PRODUCTION
    const enhancer = applyMiddleware(thunk)
    // $FlowFixMe
    store = createStore(persistedReducer, enhancer)
    persistor = persistStore(store)
  }

  return { store, persistor }
}

export const { store, persistor } = configureStore()
