// @flow
import _ from 'lodash'
import restService from '../services/api'
import apiService from '../services/feathers'
import type { DropzoneFile as Dropzone } from 'react-dropzone'
import type { Dispatch, Attachment } from '../types'

const relationFields = [
  'billId',
  'billLineItemId',
  'boatId',
  'customerId',
  'fileId',
  'invoiceId',
  'invoiceLineItemId',
  'productId',
  'userId',
  'workOrderId',
  'workOrderTaskId'
]

export function createFiles(
  files: Dropzone[],
  metadata: $Shape<Attachment>
): Dispatch => Promise<Attachment[]> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      // Change the blob files (while cleaning up), and upload them
      const newFiles = await Promise.all(
        _(files)
          .filter(f => f instanceof File)
          .map(f => {
            const formData = new FormData()
            formData.append('file', f)
            _.forEach(_.pick(metadata, relationFields), (value, key) => {
              if (value != null) formData.append(key, value)
            })
            return restService.service('files').create(formData)
          })
          .value()
      )
      return newFiles
    } catch (err) {
      console.error(err)
      dispatch({ type: 'CREATE_ERROR' })
      return err
    }
  }
}

export function deleteFiles(ids: string[]): Dispatch => Promise<string[]> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const deletion = await Promise.all(
        ids.map(id =>
          // $FlowFixMe
          apiService.files.remove(id).catch(error => {
            if (error.code === 404) {
              console.warn('Gracefully handled error:', error)
              // If not found we assume it's already deleted
              dispatch({
                type: 'DELETE_RESOURCES_SUCCESS',
                resource: 'FILE',
                ids: [id]
              })
              return id
            } else {
              throw new Error(error)
            }
          })
        )
      )
      return deletion
    } catch (err) {
      console.error(err)
      dispatch({ type: 'DELETE_ERROR', message: err.message })
      return err
    }
  }
}
