// @flow
import * as React from 'react'
import _ from 'lodash'

import type { Boat, Customer, HullMaterial, BoatCategory } from '../types'

type CurrOptionsType = {
  currency?: string,
  locale?: string
}
const currencyDefaults = {
  currency: 'EUR',
  locale: 'nl-NL'
}
export const currencyString = (
  amount: number,
  {
    currency = currencyDefaults.currency,
    locale = currencyDefaults.locale
  }: CurrOptionsType = currencyDefaults
) => {
  if (currency === 'none') {
    return Number(amount).toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  return Number(amount).toLocaleString(locale, {
    style: 'currency',
    currency
  })
}

export const boatDimensions = (boat: Boat): string => {
  const formatter = x =>
    x
      ? Number(x).toLocaleString('nl-NL', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        })
      : '-'
  return (
    [boat.length, boat.beam, boat.draught].map(formatter).join(' x ') + ' m'
  )
}

export const boatSurfaceArea = (boat: Boat): string => {
  if (boat.length != null && boat.beam != null) {
    const area = Number(boat.length * boat.beam).toLocaleString('nl-NL', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    })
    return `${area} m²`
  } else return '- m²'
}

export const boatDisplacement = (boat: Boat): string =>
  (boat.displacement
    ? Number(boat.displacement).toLocaleString('nl-NL', {
        maximumFractionDigits: 0
      })
    : '-') + ' kg'

export const boatInsurance = (boat: Boat): ?string => {
  const { insuranceType, insuranceCompany, insurancePolicyNumber } = boat
  if (
    insuranceType == null &&
    insuranceCompany == null &&
    insurancePolicyNumber == null
  ) {
    return null
  }
  let policyNumber
  if (insurancePolicyNumber != null) {
    policyNumber = `(no. ${insurancePolicyNumber})`
  }
  return _.trim(
    _.compact([insuranceType, insuranceCompany, policyNumber]).join(' ')
  )
}

export const boatCategory = ({
  boatCategory
}: {
  boatCategory: BoatCategory
}): string => {
  switch (boatCategory) {
    case 'sail':
      return 'Zeilboot'
    case 'motor':
      return 'Motorboot'
    case 'open':
      return 'Open boot'
    default:
      return ''
  }
}

export const boatMaterial = ({
  hullMaterial
}: {
  hullMaterial: HullMaterial
}): string => {
  switch (hullMaterial) {
    case 'steel':
      return 'Staal'
    case 'wood':
      return 'Hout'
    case 'polyester':
      return 'Polyester'
    case 'aluminium':
      return 'Aluminium'
    default:
      return ''
  }
}

/**
 * Create string representation of customer name
 */
export const customerName = (
  customer: ?Customer,
  options: { withCity: boolean } = { withCity: false }
): string | React.Element<'span'> => {
  if (customer == null) return '...'
  const customerName = _.compact([
    customer.firstName || customer.initials,
    customer.lastNamePrefix,
    customer.lastName
  ]).join(' ')
  // if (customerName == null) return '...'
  const name = (customerName || customer.companyName || '...').trim()
  const nameWithCity = (
    <span className="customer-name">
      {name}
      <span className="name-comma">,</span>
      <span className="name-space"> </span>
      <span className="name-city">{customer.city}</span>
    </span>
  )
  return options.withCity ? nameWithCity : name
}

export const defaultWOName = (boat: Boat): string =>
  boat != null ? _.compact(['WO', boat.boatType, boat.name]).join(' ') : ''
