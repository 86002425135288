// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import BoatEditable from './BoatEditable'
import { CustomerInfo } from '../InfoBlocks'
import PaintHistory from './BoatPaintHistory'
import MaintenanceHistory from './BoatMaintenanceHistory'
import { getBoat, patchBoat } from '../../actions'
import { customerName, isAdmin } from '../../utils'

// Types
import type {
  Boat,
  Customer,
  Dispatch,
  State as ReduxState,
  WithRouterProps as R
} from '../../types'
import type { RouterHistory } from 'react-router-dom'
type OwnProps = {
  boatId: number
}
type S = {
  admin: boolean,
  boat: Boat,
  customer: ?Customer
}
type D = {
  loadBoat: () => Promise<Boat>,
  patchBoat: ($Shape<Boat>) => Promise<void>
}
type Props = OwnProps & S & D & R

// Components

const GeneralTab = ({ boat, customer }: Props): React.Node => (
  <React.Fragment>
    <BoatEditable boat={boat} />
    <div className="my-3" />
    {customer != null ? <CustomerInfo customer={customer} /> : null}
  </React.Fragment>
)

type TabType = {
  title: string,
  to: string,
  Component: React.StatelessFunctionalComponent<Props>,
  exact?: boolean,
  prefix: string
}
const tabs = (boat: Boat): TabType[] => {
  const prefix = `/boats/${boat.id}`
  return [
    {
      title: 'Overzicht',
      to: '',
      Component: GeneralTab,
      exact: true,
      prefix
    },
    {
      title: 'Verfgeschiedenis',
      to: '/verfgeschiedenis',
      Component: PaintHistory,
      prefix
    },
    {
      title: 'Onderhoud',
      to: '/onderhoud',
      Component: MaintenanceHistory,
      prefix
    }
  ]
}

type TabsProps = {
  tabs: TabType[],
  active: string,
  history: RouterHistory
}

export const BoatTabs = ({ tabs, active, history }: TabsProps) => (
  <div className="mobile-scrolling-tabs mb-3">
    <div className="nav nav-tabs mb-3 justify-content-end flex-nowrap px-2 px-sm-3">
      {tabs.map(({ to, prefix, title }) => (
        <div className="nav-item" key={'tab-' + to}>
          <div
            onClick={() => history.push(prefix + to)}
            className={['nav-link clickable']
              .concat(prefix + to === active ? ['active'] : [])
              .join(' ')}
          >
            {title}
          </div>
        </div>
      ))}
    </div>
  </div>
)

class BoatDetail extends React.Component<Props> {
  componentDidMount() {
    this.props.loadBoat()
  }

  render() {
    const { boat, customer, match, history } = this.props
    if (boat == null || customer == null) {
      return (
        <div className="container">
          <h2>Loading...</h2>
        </div>
      )
    }
    return (
      <div className="container">
        <Helmet
          title={`${boat.name} ${
            boat.boatType != null ? boat.boatType : ''
          } (Boot)`}
        />
        <h5 className="text-muted mb-1 d-print-none">BOOT</h5>
        <div className="row align-items-center">
          <div className="col-auto">
            <h1>
              {boat.name}{' '}
              <span className="font-weight-normal">{boat.boatType}</span>
            </h1>
            <h4 className="font-weight-normal">
              {customerName(customer, { withCity: true })}
            </h4>
          </div>
        </div>
        <Switch>
          {tabs(boat).map(({ to, exact, Component }) => (
            <Route
              exact={exact}
              key={to}
              path={match.url + to}
              render={routeProps => (
                <React.Fragment>
                  <BoatTabs
                    tabs={tabs(boat)}
                    active={match.url + to}
                    history={history}
                  />
                  <Component {...routeProps} {...this.props} />
                </React.Fragment>
              )}
            />
          ))}
        </Switch>
      </div>
    )
  }
}

// Redux connect

const makeMapState = () => {
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const { boatId } = ownProps
    const boat = state.resources.boats[boatId]
    const customer =
      boat != null && boat.ownerId != null
        ? state.resources.customers[boat.ownerId]
        : undefined
    const admin = isAdmin(state)
    return {
      admin,
      boat,
      customer
    }
  }
  return mapState
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  const { boatId: id } = ownProps
  return {
    loadBoat: () => dispatch(getBoat(id)),
    patchBoat: (updates: $Shape<Boat>) => dispatch(patchBoat(id, updates))
  }
}

export default withRouter(
  connect(
    makeMapState,
    mapDispatch
  )(BoatDetail)
)
