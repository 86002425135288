// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import type { ListFilters, Dispatch, State as ReduxState } from '../../types'

type Props = {
  +filters: ListFilters,
  setFilters: ListFilters => void
}

const BillListHeaders = (props: Props) => {
  return (
    <div className="list-header">
      <div className="col">naam</div>
      <div className="col-3">eigenaar / boot</div>
      <div className="col-2 text-right">openstaand</div>
      <div className="list-item-state-header">
        <span>status</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => {
  return {
    filters: state.listFilters.bills
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'bills', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillListHeaders)
