// @flow
import apiService from '../services/feathers'
import type { Dispatch, GetState, QueryObject, Product, State } from '../types'

export function findProducts(
  filters: ?QueryObject
): (Dispatch, () => State) => Promise<Product[]> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const data = await apiService.products.find(filters)
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'PRODUCT',
        data
      })
      return data
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_ERROR' })
      return err
    }
  }
}

export function getProduct(
  id: string | number
): (Dispatch, GetState) => Promise<Product> {
  return async (dispatch: Dispatch, getState: GetState) => {
    const product = getState().resources.products[id]
    if (product != null) return product
    await apiService.ready
    const apiProduct = await apiService.products.get(id)
    dispatch({
      type: 'FIND_RESOURCES_SUCCESS',
      resource: 'PRODUCT',
      data: [apiProduct]
    })
    return apiProduct
  }
}
