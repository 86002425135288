// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Select from 'react-select'

import TT from '../Tooltip'

import { findUsers } from '../../actions'

import type {
  Dispatch,
  User,
  State as ReduxState,
  StoreSlice
} from '../../types'
type OwnProps = {
  value: ?string,
  users?: StoreSlice<User>,
  disabled?: ?boolean,
  onSelect: (string | null) => void
}
type S = {
  users: StoreSlice<User>
}
type D = {
  loadUsers: () => any
}
type Props = OwnProps & S & D
type State = {
  isOpen: boolean
}

const UserAvatar = ({ user, id }: { user?: ?User, id: string }) => {
  if (user == null) {
    return (
      <div id={id} className="user-avatar add">
        <i className="material-icons">add</i>
      </div>
    )
  }
  if (user.profileImageUrl != null) {
    return (
      <div
        id={id}
        className="user-avatar image"
        style={{ backgroundImage: `url(${user.profileImageUrl})` }}
      />
    )
  }
  if (user.name != null) {
    let initials = user.name
      .split(' ')
      .map(s => s[0])
      .join('')
    if (initials.length === 1 && user.name != null) {
      initials = user.name.slice(0, 2)
    }
    return (
      <div id={id} className="user-avatar text">
        {initials}
      </div>
    )
  }
  return (
    <div id={id} className="user-avatar email">
      {user.email[0].toUpperCase()}
    </div>
  )
}

class UserSelect extends React.Component<Props, State> {
  renderAvatar: Function
  renderSelector: Function
  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)
    this.renderAvatar = this.renderAvatar.bind(this)
    this.renderSelector = this.renderSelector.bind(this)
    this.state = {
      isOpen: false
    }
  }

  componentDidMount() {
    this.props.loadUsers()
  }

  handleSelect(user: ?User) {
    if (user == null) return this.props.onSelect(null)
    this.setState({ isOpen: false })
    this.props.onSelect(user.id)
  }

  renderAvatar(user) {
    if (user == null) {
      // return empty selector
      return (
        <TT
          tip="Wie heeft het werk gedaan?"
          render={id => <UserAvatar id={id} />}
        />
      )
    }
    return (
      <TT
        tip={user.name || user.email}
        delay={0}
        render={id => <UserAvatar id={id} user={user} />}
      />
    )
  }

  renderSelector() {
    if (!this.state.isOpen) return null

    return (
      <Select
        className="user-select-selector"
        options={_.map(this.props.users)}
        value={this.props.value}
        onChange={this.handleSelect.bind(this)}
        onBlur={() => this.setState({ isOpen: false })}
        valueKey="id"
        labelKey="email"
        autoFocus
        openOnFocus
        valueRenderer={(user: $Shape<User>) => {
          return (
            <div className="d-flex align-items-center">
              <span>{this.renderAvatar(user)}</span>
              <span className="ml-2">{user.name || user.email}</span>
            </div>
          )
        }}
        optionRenderer={(user: $Shape<User>) => {
          return (
            <div className="d-flex align-items-center">
              <span>{this.renderAvatar(user)}</span>
              <span className="ml-2">{user.name || user.email}</span>
            </div>
          )
        }}
      />
    )
  }

  render() {
    const { users, value, disabled } = this.props
    const user = _.get(users, value)

    if (users == null || _.size(users) === 0) return null

    if (disabled != null && disabled) {
      return (
        <div className="user-select disabled">{this.renderAvatar(user)}</div>
      )
    }

    return (
      <div
        tabIndex="0"
        className="user-select"
        onFocus={() => this.setState({ isOpen: true })}
        onClick={() => this.setState({ isOpen: true })}
      >
        {this.renderAvatar(user)}
        {this.renderSelector()}
      </div>
    )
  }
}

const s = (state: ReduxState, ownProps: OwnProps): S => {
  const users = ownProps.users != null ? ownProps.users : state.resources.users
  return { users }
}

const d = (dispatch: Dispatch, ownProps: OwnProps): D => {
  function loadUsers() {
    if (ownProps.users != null) return null
    return dispatch(findUsers({ paginate: false }))
  }
  return { loadUsers }
}

export default connect(
  s,
  d
)(UserSelect)
