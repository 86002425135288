// @flow
import * as React from 'react'

type SortArrowProps = {
  onWhat: string,
  order: 'asc' | 'desc',
  thisResource: string
}

const upArrow = 'keyboard_arrow_up'
const downArrow = 'keyboard_arrow_down'

const Arrow = ({ onWhat, order, thisResource }: SortArrowProps) => {
  if (onWhat === thisResource) {
    return (
      <i className="material-icons inline text-primary font-weight-bold">
        {order === 'asc' ? upArrow : downArrow}
      </i>
    )
  }
  return (
    <span className="text-muted">
      <i className="material-icons inline">{upArrow}</i>
      <i className="material-icons inline">{downArrow}</i>
    </span>
  )
}

export const SortArrows = (props: SortArrowProps) => (
  <span className="sorting-arrows">
    <Arrow {...props} />
  </span>
)

export default SortArrows
