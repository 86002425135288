// @flow
import * as React from 'react'
import uuid from 'uuid/v4'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import NumberInput from '../Input/NumberInput'
import CurrencyInput from '../Input/CurrencyInput'
import ProductSelect from '../Select/ProductSelect'
import UserSelect from '../Select/UserSelect'
import TT from '../Tooltip'

import { hiddenFactor100 } from '../../utils'

// TODO update props a bit
import type { Product, BillLineItem } from '../../types'
type Props = {
  onCreate: BillLineItem => void,
  disabled?: boolean,
  requiredData?: $Shape<BillLineItem>,
  columns: number
}
type State = {
  product: ?Product,
  quantity: ?number,
  price: ?number,
  date: string, // saved here as ISOString
  addRightNow: boolean,
  userId: ?string
}

const isoDate = (date: ?string): string =>
  date != null ? new Date(date).toISOString() : new Date().toISOString()

export default class NewBillLineItem extends React.Component<Props, State> {
  state = {
    product: null,
    quantity: 1,
    price: null,
    date: isoDate(),
    addRightNow: false,
    userId: null
  }

  selectProduct(product: ?Product) {
    let quantity = this.state.quantity || 1
    const prevHiddenFactor100 = hiddenFactor100(this.state.product)
    if (product != null) {
      if (hiddenFactor100(product) && !prevHiddenFactor100) {
        quantity = quantity * 100
      }
      if (!hiddenFactor100(product) && prevHiddenFactor100) {
        quantity = quantity / 100
      }
    } else if (prevHiddenFactor100) {
      quantity = quantity / 100
    }
    this.setState({ product, quantity }, () => {
      if (this.state.addRightNow) {
        return this.setState(
          {
            addRightNow: false
          },
          this.generateBLI
        )
      }
    })
  }

  async generateBLI() {
    if (this.state.product == null) return
    if (this.props.disabled != null && this.props.disabled) return
    const {
      id: productId,
      name,
      price: standardPrice,
      priceUnit,
      productGroupId,
      productCode,
      vatPercentage: taxPercentage
    } = this.state.product
    const quantity = this.state.quantity || 1
    const bookedAt = isoDate(this.state.date)
    const userId = this.state.userId
    const price =
      this.state.price != null
        ? this.state.price / (1 + Number(taxPercentage))
        : standardPrice
    const lineItem = {
      id: uuid(),
      bookedAt,
      description: name,
      price,
      priceUnit,
      productCode,
      productGroupId,
      productId,
      quantity,
      standardPrice,
      state: 'open',
      taxPercentage,
      userId,
      ...this.props.requiredData
    }
    await this.props.onCreate(lineItem)
    this.setState({ product: null, quantity: 1, price: null })
  }

  render() {
    const { product } = this.state
    return (
      <React.Fragment>
        <tr className="new-bli-title-row">
          <td colSpan="100">
            <span
              style={
                this.props.disabled
                  ? { textDecoration: 'line-through' }
                  : undefined
              }
            >
              Regel toevoegen
            </span>
          </td>
        </tr>
        <tr
          className="bill-line-item new-bli-row"
          onKeyPress={({ key }) => {
            if (key === 'Enter' && product) {
              return this.generateBLI()
            }
          }}
        >
          <td className="bli-date">
            <DatePicker
              className="form-control date-picker"
              dateFormat="DD-MM-YYYY"
              selected={this.state.date ? moment(this.state.date) : null}
              placeholderText="Klik om te wijzigen"
              onChange={e => {
                if (e == null) {
                  return this.setState({ date: isoDate() })
                }
                return this.setState({ date: isoDate(e.toDate()) })
              }}
            />
          </td>
          <td className="bli-assignee">
            <UserSelect
              disabled={this.props.disabled}
              onSelect={userId => this.setState({ userId })}
              value={this.state.userId}
            />
          </td>
          <td colSpan="2" className="pl-0">
            <ProductSelect
              product={product}
              disabled={this.props.disabled}
              onChange={this.selectProduct.bind(this)}
              onInputKeyDown={event => {
                if (event.keyCode === 13) {
                  // Enter
                  this.setState({
                    addRightNow: true
                  })
                } else if (this.state.addRightNow) {
                  // Not enter
                  this.setState({
                    addRightNow: false
                  })
                }
              }}
            />
          </td>
          <td
            className={[
              'bli-price',
              product != null ? undefined : 'no-symbol'
            ].join(' ')}
          >
            {product != null ? (
              <CurrencyInput
                value={
                  this.state.price != null
                    ? this.state.price
                    : product.price * (1 + Number(product.vatPercentage))
                }
                className="bli-form-control"
                onBlur={(price: number | null) =>
                  Promise.resolve(this.setState({ price }))
                }
              />
            ) : null}
          </td>
          <td className="bli-priceUnit">
            {product != null &&
            product.priceUnit !== 1 &&
            !hiddenFactor100(product) ? (
              <span className="small text-muted">
                {'/' + product.priceUnit}
              </span>
            ) : null}
          </td>
          <td colSpan={this.props.columns - 6} className="bli-quantity">
            <NumberInput
              disabled={this.props.disabled}
              className="bli-form-control"
              value={this.state.quantity}
              onBlur={quantity => Promise.resolve(this.setState({ quantity }))}
              factor={product != null && hiddenFactor100(product) ? 100 : 1}
              allowNull={false}
            />
          </td>
          <td colSpan="1" className="bli-action-buttons">
            <TT
              tip="Nieuwe regel toevoegen"
              render={id => (
                <div
                  id={id}
                  className="bli-add"
                  onClick={this.generateBLI.bind(this)}
                  tabIndex="0"
                >
                  <i className="material-icons">add</i>
                </div>
              )}
            />
          </td>
        </tr>
      </React.Fragment>
    )
  }
}
