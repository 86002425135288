// @flow
import * as React from 'react'
import ReactLoading from 'react-loading'
import logo from '../assets/Logo@2x.png'

type ReactLoadingProps = {
  type?: string,
  color?: string,
  delay?: string,
  height?: number | string,
  width?: number | string,
  className?: string
}
export const LoadingSpinner = (props: ReactLoadingProps) => (
  <ReactLoading type="spin" color="#1d89d6" {...props} />
)

export const LoadingPage = ({ message }: { message?: string }) => (
  <div className="vw-100 vh-100 d-flex justify-content-center align-items-center flex-column">
    <div className="row justify-content-center mb-4">
      <div className="col-md-6 col-lg-4">
        <img className="logo w-100" src={logo} alt="WaterScape" />
      </div>
    </div>
    <LoadingSpinner />
    {message != null ? <h3 className="mt-4">{message}</h3> : null}
    <div className="h-25" />
  </div>
)

export const LoadingOverlay = () => (
  <div
    className="d-flex justify-content-center align-items-center flex-column"
    style={{
      position: 'absolute',
      zIndex: '1000',
      backgroundColor: 'rgba(0, 22, 33, 0.2)',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }}
  >
    <LoadingSpinner />
  </div>
)
