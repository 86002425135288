// @flow
import * as React from 'react'
import TT from './Tooltip'

export const ListStateIcon = ({
  state,
  color,
  icon,
  tip,
  small
}: {
  state: ?string,
  color: ?string,
  icon: ?string,
  tip?: ?string,
  small?: ?boolean
}) =>
  tip != null ? (
    <TT
      tip={tip}
      delay={0}
      render={id => (
        <div
          id={id}
          className={`list-state-icon ${
            state != null ? state : ''
          } state-${color || 'undefined'} ${small ? 'small' : ''}`}
        >
          <i className="material-icons">{icon}</i>
        </div>
      )}
    />
  ) : (
    <div
      className={`list-state-icon ${state != null ? state : ''} state-${color ||
        'undefined'} ${small ? 'small' : ''}`}
    >
      <i className="material-icons">{icon}</i>
    </div>
  )
