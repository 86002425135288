// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'

import { checkLogin, backgroundReload } from '../actions'
import Customers from '../components/Customers'
import BoatPage from './Boats'
import BillPage from './Bills'
import WorkOrderPage from './WorkOrders'
import Navbar from '../components/Navigation/Navbar'
import LoginPage from '../pages/LoginPage'
import LogoutPage from '../pages/LogoutPage'
import GlobalHotkeys from '../components/GlobalHotkeys'

import type { Dispatch, State, ActiveUser } from '../types'

type Props = {
  activeUser: ?ActiveUser,
  checkLogin: () => void,
  backgroundReload: () => void,
  persistor: any
}

class App extends React.Component<Props> {
  reloadJob: ?IntervalID

  componentDidMount() {
    this.props.checkLogin()
    this.reloadJob = setInterval(this.props.backgroundReload, 1000 * 60 * 10)
  }

  componentWillUnmount() {
    if (this.reloadJob != null) clearInterval(this.reloadJob)
  }

  render() {
    if (this.props.activeUser == null) {
      return <LoginPage />
    }
    return (
      <Router>
        <GlobalHotkeys>
          <Helmet
            defaultTitle="Jachthaven ~ Waterscape"
            titleTemplate="%s | Jachthaven ~ Waterscape"
          />
          <div className="d-flex flex-column justify-content-stretch full-page">
            <Navbar persistor={this.props.persistor} />
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Redirect
                    to={{
                      pathname: '/work-orders'
                    }}
                  />
                )}
              />
              <Route
                path="/customers"
                render={props => <Customers {...props} />}
              />

              {/* Boats */}
              {process.env.REACT_APP_FEATURE_BOATS === 'active' ? (
                <Route
                  path="/boats/:id"
                  render={props => (
                    <BoatPage
                      {...props}
                      boatId={
                        props.match != null ? props.match.params.id : null
                      }
                    />
                  )}
                />
              ) : null}
              {process.env.REACT_APP_FEATURE_BOATS === 'active' ? (
                <Route path="/boats" component={BoatPage} />
              ) : null}

              {/* Bills */}
              <Route
                path="/bills/:id"
                render={props => (
                  <BillPage
                    {...props}
                    billId={props.match != null ? props.match.params.id : null}
                  />
                )}
              />
              <Route path="/bills" component={BillPage} />

              {/* Work Orders */}
              <Route
                path="/work-orders/:id"
                render={props => (
                  <WorkOrderPage
                    {...props}
                    workOrderId={
                      props.match != null ? props.match.params.id : null
                    }
                  />
                )}
              />
              <Route path="/work-orders" component={WorkOrderPage} />

              {/* Logout Screen */}
              <Route path="/logout" component={LogoutPage} />
            </Switch>
          </div>
        </GlobalHotkeys>
      </Router>
    )
  }
}

function mapStateToProps(state: State): Object {
  return { activeUser: state.activeUser }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: Props) {
  return {
    checkLogin: () => dispatch(checkLogin(ownProps.persistor)),
    backgroundReload: () => dispatch(backgroundReload(ownProps.persistor))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
