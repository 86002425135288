// @flow
import * as React from 'react'
import _ from 'lodash'

type Props = {
  print?: boolean,
  children?: React.Node,
  component?: React.ComponentType<any>,
  tag?: string,
  className?: string,
  printClassName?: ?string
}

const Print = ({
  print = true,
  children,
  component,
  tag = 'div',
  className: addClasses = '',
  printClassName,
  ...props
}: Props) => {
  const Component = component != null ? component : tag
  const className = _.compact([
    addClasses,
    print ? printClassName || 'd-print-block' : 'd-print-none'
  ]).join(' ')
  return React.createElement(Component, { className, ...props }, children)
}

export default Print
