// @flow
import uuid from 'uuid/v4'
import apiService from '../services/feathers'
import type { Dispatch, WorkOrderTask } from '../types'

/* --------------------
    REGULAR OPERATIONS
   -------------------- */

export function patchWorkOrderTask(
  task: $Shape<WorkOrderTask>
): Dispatch => Promise<WorkOrderTask> {
  return async (dispatch: Dispatch): Promise<WorkOrderTask> => {
    try {
      await apiService.ready
      const result = await apiService.workOrderTasks.patch(task.id, task)
      return result
    } catch (err) {
      console.error(err)
      dispatch({ type: 'PATCH_ERROR', error: err })
      return err
    }
  }
}

export function createWorkOrderTask(
  task: WorkOrderTask
): Dispatch => Promise<WorkOrderTask> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const result = await apiService.workOrderTasks.create(task)
      return result
    } catch (err) {
      console.error(err)
      dispatch({ type: 'CREATE_ERROR' })
      return err
    }
  }
}

export function deleteWorkOrderTask(task: WorkOrderTask) {
  return async (dispatch: Dispatch): Promise<string | number> => {
    try {
      await apiService.ready
      const result = await apiService.workOrderTasks
        .remove(task.id)
        .catch(error => {
          if (error.code === 404) {
            console.warn('Gracefully handled error:', error)
            // If not found we assume it's already deleted
            dispatch({
              type: 'DELETE_RESOURCES_SUCCESS',
              resource: 'WORK_ORDER_TASK',
              ids: [task.id]
            })
            return task.id
          } else {
            return error
          }
        })
      return result
    } catch (err) {
      console.error(err)
      dispatch({ type: 'DELETE_ERROR', error: err })
      return err
    }
  }
}

/* ------------------
    OTHER OPERATIONS
   ------------------ */

export function addWorkOrderTask(
  category: string,
  workOrderId: string
): Dispatch => Promise<WorkOrderTask> {
  return (dispatch: Dispatch) => {
    const task = {
      id: uuid(),
      state: 'open',
      indicatedPrice: null,
      workOrderId,
      category,
      name: 'Nieuwe taak',
      description: '',
      notes: '',
      completedAt: null
    }
    return dispatch(createWorkOrderTask(task))
  }
}
