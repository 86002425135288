// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import Fuse from 'fuse.js'
import { Helmet } from 'react-helmet'
import SearchBar from '../Search/SearchBar'
import BoatListItem from './BoatListItem'
import BoatListHeaders from './BoatListHeaders'
import { orderByFromQuery } from '../../utils'

import type {
  Boat,
  Customer,
  Dispatch,
  ListFilters,
  State as ReduxState,
  StoreSlice
} from '../../types'

type OwnProps = {||}
type S = {
  +boats: Boat[],
  +filters: ListFilters
}
type D = {
  setFilters: ListFilters => void
}
type Props = OwnProps & S & D

class Boats extends React.Component<Props, {}> {
  render() {
    const { boats, filters } = this.props
    return (
      <div className="container">
        <Helmet title="Boten" />
        <div className="d-flex align-items-center my-2 pt-2">
          <h1 className="m-0">Boten</h1>
        </div>
        <div className="sticky-top bg-white pt-2">
          <SearchBar
            filters={filters}
            setFilters={this.props.setFilters}
            placeholder="Filter op klant, boot..."
          />
          <BoatListHeaders />
        </div>
        <div className="list boat-list">
          {_.map(boats, boat => (
            <BoatListItem key={boat.id} boatId={boat.id} boat={boat} />
          ))}
        </div>
      </div>
    )
  }
}

// Direct copy (atm) of BoatSideList
const getFilterOptions = (filters: ListFilters) => {
  const filterableFields = ['ownerId', 'boatId', 'state']
  return _.pick(filters, filterableFields)
}
const getSortOptions = orderByFromQuery({ defaultSort: 'openedOn' })
const getBoats = memoizeOne((boats, filters, orderBy) =>
  _(boats)
    .filter(filters)
    .orderBy(...orderBy)
    .value()
)
const getFuseFilter = memoizeOne(
  (boats: StoreSlice<Boat>, customers: StoreSlice<Customer>) => {
    const list = _.map(boats, boat => ({
      ...boat,
      customer: customers[boat.ownerId]
    }))
    const options = {
      threshold: 0.25,
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      minMatchCharLength: 2,
      keys: [
        'name',
        'boatType',
        'customer.firstName',
        'customer.lastNamePrefix',
        'customer.lastName',
        'customer.city',
        'customer.email',
        'customer.companyName'
      ]
    }
    return new Fuse<Boat>(list, options)
  }
)

const mapStateToProps = (state: ReduxState): S => {
  const filters = state.listFilters.boats
  const fuse = getFuseFilter(state.resources.boats, state.resources.customers)
  const { q } = filters
  const queriedBoats = q == null ? state.resources.boats : fuse.search(q)
  let boats = getBoats(
    queriedBoats,
    getFilterOptions(filters),
    getSortOptions(filters)
  )
  return {
    boats,
    filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch): D => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'boats', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Boats)
