// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import ChildRow from './ChildRow'
import { makeGetBLIsForParentId } from '../../utils'

import type { State as ReduxState, BillLineItem } from '../../types'
import type { BLIProps } from './BillLineItem'

type Props = {
  billLineItem: BillLineItem,
  children: ?(BillLineItem[]),
  state: string,
  selected: boolean,
  onClick: () => void
}

const Children = ({ billLineItem, ...props }: Props) => {
  if (props.children != null)
    return (
      <React.Fragment>
        {_.map(props.children, c => (
          <ChildRow
            state={billLineItem.state}
            child={c}
            key={`child-${c.id}`}
            {...props}
          />
        ))}
      </React.Fragment>
    )
  return null
}

const makeMapState = () => {
  const getBLIs = makeGetBLIsForParentId()
  const mapState = (state: ReduxState, ownProps: BLIProps) => {
    const { id: parentId } = ownProps.billLineItem
    const children = getBLIs(state, { parentId })
    return { children }
  }
  return mapState
}

export default connect(makeMapState)(Children)
