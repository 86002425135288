// @flow
import * as React from 'react'
import BillList from '../components/Bill/BillList'
import BillSideList from '../components/Bill/BillSideList'
import BillDetail from '../components/Bill/BillDetail'

type Props = {
  billId: ?string
}

const Bills = ({ billId }: Props) => {
  if (billId == null) return <BillList />
  return (
    <div className="list-and-detail-container">
      <div className="side-list d-print-none">
        <BillSideList activeBillId={billId} />
      </div>
      {billId != null ? (
        <div className="detail-page">
          <BillDetail billId={billId} />
        </div>
      ) : null}
    </div>
  )
}

export default Bills
