// @flow

import type { UserStateAction, ActiveUser } from '../types'

const defaultState = null

export default function activeUserReducer(
  state: ActiveUser = defaultState,
  action: UserStateAction
): ActiveUser {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.token }
    case 'SET_USER':
      return { ...state, user: action.user }
    case 'LOGOUT':
      return defaultState
    default:
      return state
  }
}
