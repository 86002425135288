// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { logout, forceReload } from '../../actions'
import { purge } from '../../utils/persist'
import TT from '../Tooltip'
import { LoadingSpinner } from '../Loading'
import type { Dispatch, WithRouterProps as R } from '../../types'

type OwnProps = { persistor: any }
type D = {
  logout: () => void,
  forceReload: () => Promise<void>
}
type Props = OwnProps & D & R
type State = {
  isReloading: boolean
}

class RefreshLink extends React.Component<Props, State> {
  state = {
    isReloading: false
  }

  async reload() {
    await new Promise(resolve => this.setState({ isReloading: true }, resolve))

    purge(this.props.persistor)

    await this.props.forceReload()
    this.setState({ isReloading: false })
  }

  render() {
    return (
      <li className={`nav_item ${this.state.isReloading ? 'bg-sunset' : ''}`}>
        {this.state.isReloading ? (
          <TT
            tip="Aan het herladen..."
            delay={0}
            render={id => (
              <div id={id} className="nav-link position-relative">
                <i className="material-icons small" style={{ opacity: '0' }}>
                  refresh
                </i>
                <div
                  className="position-absolute d-flex justify-content-center align-items-center"
                  style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    fontSize: 0
                  }}
                >
                  <LoadingSpinner height={15} width={15} color={'white'} />
                </div>
              </div>
            )}
          />
        ) : (
          <TT
            tip="Data herladen van server"
            delay={0}
            render={id => (
              <div
                id={id}
                className="nav-link"
                onClick={this.reload.bind(this)}
              >
                <i className="material-icons small">refresh</i>
              </div>
            )}
          />
        )}
      </li>
    )
  }
}

const d = (dispatch: Dispatch, ownProps: R): D => {
  return {
    logout: () => {
      dispatch(logout())
      ownProps.history.push('/')
    },
    forceReload: async () => {
      await dispatch(forceReload())
    }
  }
}

export default withRouter<{| persistor: any |}>(
  connect(
    undefined,
    d
  )(RefreshLink)
)
