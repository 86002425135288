// @flow
import { combineReducers } from 'redux'
import _ from 'lodash'
import user from './user'
import billLineItems from './billLineItems'
import syncLog from './databaseSync'
import listFilters from './listFilters'

import type {
  Action,
  StoreSlice,
  Attachment,
  Bill,
  Boat,
  BoatLegacyMaintenance,
  Customer,
  Invoice,
  InvoiceLineItem,
  Product,
  Stand,
  User,
  WorkOrder,
  WorkOrderTask
} from '../types'
type DefaultReducer<T> = <T>(StoreSlice<T>, Action) => StoreSlice<T>

const defaultReducer = <T>(resource: string): DefaultReducer<T> => <T>(
  state: StoreSlice<T> = {},
  action: Action
): StoreSlice<T> => {
  if (action.type === 'LOGOUT') return {}
  if (action.resource !== resource) return state
  switch (action.type) {
    case 'FIND_RESOURCES_SUCCESS':
    case 'CREATE_RESOURCES_SUCCESS': {
      if (action.data == null) return state
      let dataSlice
      if (Array.isArray(action.data)) {
        const data = _.filter(action.data, d => d != null)
        dataSlice = _.keyBy(data, 'id')
      } else {
        dataSlice = { [action.data.id]: action.data }
      }
      return {
        ...state,
        ...dataSlice
      }
    }
    case 'PATCH_RESOURCES_SUCCESS': {
      let dataSlice
      if (Array.isArray(action.data)) {
        const data = _.filter(action.data, d => d != null)
        dataSlice = _.keyBy(data, 'id')
      } else if (action.data.id != null) {
        dataSlice = {
          [action.data.id]: {
            ...state[action.data.id],
            ...action.data
          }
        }
      } else {
        dataSlice = { [action.data.id]: action.data }
      }
      return {
        ...state,
        ...dataSlice
      }
    }
    case 'DELETE_RESOURCES_SUCCESS': {
      // $FlowFixMe
      const arr = Array.isArray(action.ids) ? action.ids : [action.ids]
      return _.omit(state, arr)
    }
    default:
      return state
  }
}

const reducers = {
  activeUser: user,
  resources: combineReducers({
    billLineItems,
    bills: defaultReducer<Bill>('BILL'),
    workOrders: defaultReducer<WorkOrder>('WORK_ORDER'),
    workOrderTasks: defaultReducer<WorkOrderTask>('WORK_ORDER_TASK'),
    boats: defaultReducer<Boat>('BOAT'),
    boatLegacyMaintenance: defaultReducer<BoatLegacyMaintenance>(
      'LEGACY_MAINTENANCE'
    ),
    invoices: defaultReducer<Invoice>('INVOICE'),
    invoiceLineItems: defaultReducer<InvoiceLineItem>('INVOICE_LINE_ITEM'),
    customers: defaultReducer<Customer>('CUSTOMER'),
    products: defaultReducer<Product>('PRODUCT'),
    stands: defaultReducer<Stand>('STAND'),
    files: defaultReducer<Attachment>('FILE'),
    users: defaultReducer<User>('USER')
  }),
  syncLog,
  listFilters
}

const rootReducer = combineReducers<_, Action>(reducers)

export default rootReducer
