// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

import Confirmation from '../Confirmation'
import { patchWorkOrder, deleteWorkOrder } from '../../actions'

import type { WorkOrder, Dispatch, WithRouterProps as R } from '../../types'

type OwnProps = {
  workOrder: WorkOrder,
  disabled?: boolean
}
type S = {}
type D = {
  closeWorkOrder: () => void,
  reopenWorkOrder: () => void,
  deleteWorkOrder: () => void
}

type Props = OwnProps & S & D & R

const Status = ({
  workOrder,
  closeWorkOrder,
  reopenWorkOrder,
  deleteWorkOrder,
  disabled
}: Props) => {
  let status = { color: 'light', label: 'Onbekend' }
  if (workOrder.state != null && workOrder.state === 'open')
    status = { color: 'success', label: 'Open' }
  else if (workOrder.state != null && workOrder.state === 'closed')
    status = { color: 'info', label: 'Gesloten' }
  if (disabled) {
    return (
      <h2>
        <span
          className={`small badge badge-pill badge-${status.color} text-white`}
        >
          {status.label}
        </span>
      </h2>
    )
  }
  return (
    <Confirmation
      title="Werkopdracht definitief verwijderen?"
      text={`Weet u zeker dat de werkopdracht verwijderd kan worden?\nHiermee worden ook de bijbehorende rekening en alle boekregels verwijderd. Dit alles kan niet ongedaan worden gemaakt.\n\n(Verwijderen kan alleen zolang er nog geen regels gefactureerd zijn.)`}
      onConfirm={deleteWorkOrder}
      render={onClick => (
        <h2>
          <UncontrolledDropdown>
            <DropdownToggle
              caret
              tag="span"
              className={`small badge badge-pill badge-${
                status.color
              } text-white`}
            >
              {status.label}
            </DropdownToggle>
            <DropdownMenu right>
              {workOrder.state !== 'closed' && (
                <DropdownItem
                  onClick={closeWorkOrder}
                  className="btn btn-primary"
                >
                  Sluit werkopdracht
                </DropdownItem>
              )}
              {workOrder.state !== 'open' && (
                <DropdownItem
                  onClick={reopenWorkOrder}
                  className="btn btn-primary"
                >
                  Heropen werkopdracht
                </DropdownItem>
              )}
              <DropdownItem onClick={onClick} className="btn btn-primary">
                Verwijder werkopdracht
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </h2>
      )}
    />
  )
}

const mapStateToProps = (): S => ({})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps & R): D => {
  const { id } = ownProps.workOrder
  return {
    closeWorkOrder: () => dispatch(patchWorkOrder(id, { state: 'closed' })),
    reopenWorkOrder: () => dispatch(patchWorkOrder(id, { state: 'open' })),
    deleteWorkOrder: () =>
      dispatch(deleteWorkOrder(id)).then(success => {
        if (success) {
          toast.success('Werkopdracht verwijderd!')
          ownProps.history.push('/work-orders')
        } else {
          toast.error('Oeps! De werkopdracht kon niet worden verwijderd. :/')
        }
      })
  }
}

export default withRouter<OwnProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Status)
)
