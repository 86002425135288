// @flow
import _ from 'lodash'
import type { Dispatch, GetState } from '../types'
import feathers from '../services/feathers'
import { purge } from '../utils/persist'

const serviceTypes = [
  'bill-line-items',
  'bills',
  'boats',
  'customers',
  'files',
  'invoice-line-items',
  'invoices',
  'legacy-maintenance',
  'stands',
  'users',
  'work-order-tasks',
  'work-orders'
  // 'products', // -- we fetch these separately for memory purposes
]

const resourceName = serviceName => {
  let resource = _.toUpper(_.snakeCase(serviceName))
  if (resource[resource.length - 1] === 'S') resource = resource.slice(0, -1)
  return resource
}

export function setupServiceListeners(app: any) {
  return async (dispatch: Dispatch) => {
    const addListenersFor = (serviceName: string) => {
      const service = app.service(serviceName)
      const resource = resourceName(serviceName)
      service.on('created', data =>
        // $FlowFixMe
        dispatch({
          type: 'CREATE_RESOURCES_SUCCESS',
          resource,
          data
        })
      )
      service.on('updated', data =>
        // $FlowFixMe
        dispatch({
          type: 'UPDATE_RESOURCES_SUCCESS',
          resource,
          data
        })
      )
      service.on('patched', data =>
        // $FlowFixMe
        dispatch({
          type: 'PATCH_RESOURCES_SUCCESS',
          resource,
          data
        })
      )
      service.on('removed', data => {
        // $FlowFixMe
        dispatch({
          type: 'DELETE_RESOURCES_SUCCESS',
          resource,
          ids: Array.isArray(data) ? _.map(data, 'id') : _.get(data, 'id')
        })
      })
    }

    _.forEach(serviceTypes, type => {
      addListenersFor(type)
    })
    app.service('products').timeout = 10000
    return true
  }
}

export function fetchAllFromApi(app: any, serviceName: string, query?: Object) {
  return async (dispatch: Dispatch) => {
    try {
      await app.ready
      let resource = resourceName(serviceName)
      let result = await app.service(serviceName).find({ query })

      // If the api doesn't return an array, it will return an object including
      // the pagination variables and some of the data.
      if (!_.isArray(result)) {
        const { total, limit, data } = result
        data.length > 0 &&
          // $FlowFixMe
          dispatch({
            type: 'FIND_RESOURCES_SUCCESS',
            resource,
            data
          })
        if (total > limit) {
          // There's more that we have to fetch
          const steps = Math.floor(total / limit)
          for (var i = 1; i <= steps; i++) {
            await app
              .service(serviceName)
              .find({ query: { ...query, $skip: limit * i } })
              .then(
                ({ data }) =>
                  data.length > 0 &&
                  dispatch({
                    // $FlowFixMe
                    type: 'FIND_RESOURCES_SUCCESS',
                    resource,
                    data
                  })
              )
          }
        }
      } else {
        result.length > 0 &&
          // $FlowFixMe
          dispatch({
            type: 'FIND_RESOURCES_SUCCESS',
            resource,
            data: result
          })
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: 'FETCH_ERROR', error })
      return error
    }
  }
}

export function fetchDeletedFromApi(
  app: any,
  serviceName: string,
  query?: Object
) {
  return async (dispatch: Dispatch) => {
    try {
      await app.ready
      const resource = resourceName(serviceName)
      let result = await app.service(serviceName).find({ query })

      // If the api doesn't return an array, it will return an object including
      // the pagination variables and some of the data.
      if (!_.isArray(result)) {
        const { total, limit, data } = result
        data.lenth > 0 &&
          // $FlowFixMe
          dispatch({
            type: 'DELETE_RESOURCES_SUCCESS',
            resource,
            ids: data.map(d => d.id)
          })
        if (total > limit) {
          // There's more that we have to fetch
          const steps = Math.floor(total / limit)
          for (var i = 1; i <= steps; i++) {
            await app
              .service(serviceName)
              .find({ query: { ...query, $skip: limit * i } })
              .then(
                ({ data }) =>
                  data.length > 0 &&
                  // $FlowFixMe
                  dispatch({
                    type: 'DELETE_RESOURCES_SUCCESS',
                    resource,
                    ids: data.map(d => d.id)
                  })
              )
          }
        }
      } else {
        result.length > 0 &&
          // $FlowFixMe
          dispatch({
            type: 'DELETE_RESOURCES_SUCCESS',
            resource,
            ids: result.map(d => d.id)
          })
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: 'FETCH_ERROR', error })
      return error
    }
  }
}

export function fetchUpdates(app: any, resource: string, query?: Object) {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      // Get all records newer than our newest update
      const latestRevision = _.get(getState(), [
        'syncLog',
        resource,
        'latestRevision'
      ])
      await dispatch(
        fetchAllFromApi(app, resource, {
          updatedAt: { $gt: latestRevision },
          ...query
        })
      )
      await dispatch(
        fetchDeletedFromApi(app, resource, {
          deletedAt: { $gt: latestRevision },
          ...query
        })
      )
      dispatch({
        type: 'SET_LATEST_REVISION',
        resource,
        latestRevision: new Date().toISOString()
      })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'FETCH_ERROR', error })
      return error
    }
  }
}

export function reloadRemote(app: any) {
  return async (dispatch: Dispatch, getState: GetState) => {
    for (const type of serviceTypes) {
      await dispatch(fetchUpdates(app, type))
    }
    const state = getState()
    for (const type of serviceTypes) {
      if (_.size(state.resources[_.camelCase(type)]) === 0) {
        await dispatch(fetchAllFromApi(app, type))
      }
    }
    const { billLineItems, products } = getState().resources
    const productIds = _.uniq(_.map(billLineItems.byId, 'productId'))
    const newProductIds = _.without(productIds, ..._.map(products, 'id'))
    await dispatch(
      fetchAllFromApi(app, 'products', { id: { $in: newProductIds } })
    )
    await dispatch(fetchUpdates(app, 'products', { id: { $in: productIds } }))
  }
}

export function loadRemote(app: any) {
  return async (dispatch: Dispatch, getState: GetState) => {
    for (const type of serviceTypes) {
      await dispatch(fetchAllFromApi(app, type))
    }
    const { billLineItems } = getState().resources
    const productIds = _.uniq(_.map(billLineItems.byId, 'productId'))
    await dispatch(
      fetchAllFromApi(app, 'products', { id: { $in: productIds } })
    )
  }
}

export function forceReload() {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (feathers != null && feathers.app != null) {
      await dispatch(loadRemote(feathers.app))
      const { billLineItems } = getState().resources
      const productIds = _.uniq(_.map(billLineItems.byId, 'productId'))
      await dispatch(
        fetchUpdates(feathers.app, 'products', { id: { $in: productIds } })
      )
    }
  }
}

export function backgroundReload(persistor: any) {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (feathers != null && feathers.app != null) {
      purge(persistor)
      await dispatch(reloadRemote(feathers.app))
      const { billLineItems } = getState().resources
      const productIds = _.uniq(_.map(billLineItems.byId, 'productId'))
      await dispatch(
        fetchUpdates(feathers.app, 'products', { id: { $in: productIds } })
      )
    }
  }
}
