// @flow
import * as React from 'react'
import { Button } from '../Button'
import Confirmation from '../Confirmation'
import TT from '../Tooltip'

type ActionProps = {
  selectAll: () => void,
  selectNone: () => void,
  group: boolean => void,
  groupAvailable: boolean,
  canInvoice?: boolean,
  createInvoice?: string => Promise<void>
}

export const ActionButtons = ({
  selectAll,
  selectNone,
  group,
  groupAvailable,
  canInvoice,
  createInvoice
}: ActionProps) => (
  <React.Fragment>
    <Button
      className="btn btn-sm btn-sunset text-white"
      containerClassName="px-1"
      onClick={selectAll}
    >
      alles
    </Button>
    <Button
      className="btn btn-sm btn-sunset text-white"
      containerClassName="px-1"
      onClick={selectNone}
    >
      geen
    </Button>
    <TT
      tip={
        groupAvailable
          ? 'Groepeer alle geselecteerde regels binnen dezelfde productgroep'
          : 'Selecteer minstens 2 regels om te groeperen'
      }
      render={id => (
        <Button
          id={id}
          className="btn btn-primary btn-sm"
          containerClassName="px-1"
          onClick={() => group(true)}
          disabled={!groupAvailable}
          title={
            groupAvailable
              ? 'Groepeer alle geselecteerde regels van dezelfde taak op de werkkaart, per productgroep'
              : 'Selecteer minstens 2 regels om te groeperen'
          }
        >
          Groepeer regels <strong>per taak</strong>
        </Button>
      )}
    />
    <TT
      tip={
        groupAvailable
          ? 'Groepeer alle geselecteerde regels binnen dezelfde productgroep'
          : 'Selecteer minstens 2 regels om te groeperen'
      }
      render={id => (
        <Button
          id={id}
          className="btn btn-primary btn-sm"
          containerClassName="px-1"
          onClick={() => group(false)}
          disabled={!groupAvailable}
          title={
            groupAvailable
              ? 'Groepeer alle geselecteerde regels binnen dezelfde productgroep'
              : 'Selecteer minstens 2 regels om te groeperen'
          }
        >
          Groepeer regels
        </Button>
      )}
    />
    {canInvoice != null && createInvoice != null ? (
      <React.Fragment>
        <TT
          tip={
            canInvoice
              ? 'Maak van de geselecteerde regels een order'
              : 'Selecteer regels om een order te maken'
          }
          render={id => (
            <Confirmation
              text="Weet u zeker dat u van de geselecteerde regels een order wilt maken?"
              onConfirm={() => createInvoice('order')}
              render={onClick => (
                <Button
                  id={id}
                  disabled={!canInvoice}
                  containerClassName="px-1"
                  onClick={onClick}
                  className="btn btn-secondary btn-sm text-white"
                >
                  Maak order
                </Button>
              )}
            />
          )}
        />
        <TT
          tip={
            canInvoice
              ? 'Geselecteerde regels factureren'
              : 'Selecteer regels om te factureren'
          }
          render={id => (
            <Confirmation
              text="Weet u zeker dat u de geselecteerde regels wilt factureren?"
              onConfirm={() => createInvoice('invoice')}
              render={onClick => (
                <Button
                  id={id}
                  disabled={!canInvoice}
                  containerClassName="px-1"
                  onClick={onClick}
                  className="btn btn-secondary btn-sm text-white"
                >
                  Factureer
                </Button>
              )}
            />
          )}
        />
        <TT
          tip={
            canInvoice
              ? 'Geselecteerde regels doorsturen naar kassa'
              : 'Selecteer regels om door te sturen naar de kassa'
          }
          render={id => (
            <Confirmation
              text="Weet u zeker dat u de geselecteerde regels naar het kassasysteem wilt sturen?"
              onConfirm={() => createInvoice('register')}
              render={onClick => (
                <Button
                  id={id}
                  disabled={!canInvoice}
                  containerClassName="px-1"
                  onClick={onClick}
                  className="btn btn-secondary btn-sm text-white"
                >
                  Stuur naar kassa
                </Button>
              )}
            />
          )}
        />
      </React.Fragment>
    ) : null}
  </React.Fragment>
)
