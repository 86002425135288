// @flow
import * as React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { logout } from '../actions'
import { LoadingPage } from '../components/Loading'
import type { Dispatch, WithRouterProps as R } from '../types'

type OwnProps = { persistor: any }
type D = {
  logout: () => Promise<void>
}
type Props = OwnProps & D & R

class LogoutPage extends React.Component<Props> {
  async componentDidMount() {
    await new Promise(resolve => setTimeout(resolve, 500))
    await this.props.logout()
    this.props.history.push('/')
    window.location.reload()
  }
  render() {
    return (
      <React.Fragment>
        <LoadingPage message={'Je wordt uitgelogd...'} />
      </React.Fragment>
    )
  }
}

const d = (dispatch: Dispatch): D => {
  return {
    logout: async () => {
      return dispatch(logout())
    }
  }
}

export default withRouter<{| persistor: any |}>(
  connect(
    undefined,
    d
  )(LogoutPage)
)
