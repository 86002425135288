// @flow
import _ from 'lodash'

import type { ListFilters } from '../types'
type OrderByType = [[string], ('asc' | 'desc')[]]

export function orderByFromQuery({
  defaultSort,
  defaultOrder = 'asc',
  sortableFields
}: {
  defaultSort?: string,
  defaultOrder?: string,
  sortableFields?: string[]
} = {}): (query: ListFilters) => OrderByType {
  return function(query: ListFilters): OrderByType {
    const onWhat = _.get(query, 'orderBy', defaultSort)
    const order = _.get(query, 'order', defaultOrder)
    if (sortableFields != null && defaultSort != null) {
      if (!sortableFields.includes(onWhat)) return [[defaultSort], [order]]
    }
    return [[onWhat], [order]]
  }
}

export function cleanFilters(query: ListFilters): ListFilters {
  return _.reduce(
    query,
    (acc, value, key) => {
      if (_.isNil(value) || _.isEmpty(value)) return acc
      return {
        ...acc,
        [key]: value
      }
    },
    {}
  )
}
