// @flow
import apiService from '../services/feathers'
import _ from 'lodash'
import type { Dispatch, WorkOrder } from '../types'

/* ----------------
    API OPERATIONS
   ---------------- */

export function createWorkOrder(
  boatId: number | string,
  customerId: number | string,
  fields: $Shape<WorkOrder>
) {
  return async (dispatch: Dispatch): Promise<WorkOrder> => {
    try {
      await apiService.ready
      const workOrder: WorkOrder = await apiService.workOrders.create({
        name: 'Werkopdracht zonder naam',
        ...fields,
        boatId: `${boatId}`,
        customerId: `${customerId}`
      })
      return workOrder
    } catch (error) {
      console.error(error)
      dispatch({ type: 'FETCH_ERROR', error })
      return error
    }
  }
}

export function getWorkOrder(workOrderId: string) {
  return async (dispatch: Dispatch): Promise<WorkOrder> => {
    try {
      await apiService.ready
      // Reload resources from API
      const workOrder = await apiService.workOrders.get(workOrderId)
      const boatId = workOrder.boatId
      const customerId = workOrder.customerId
      const boat = await apiService.boats.get(boatId)
      const customer = await apiService.customers.get(customerId)
      const workOrderTasks = await apiService.workOrderTasks.find({
        query: { workOrderId }
      })

      // Dispatch to Redux
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'WORK_ORDER',
        data: workOrder
      })
      if (boat != null)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'BOAT',
          data: boat
        })
      if (customer != null)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'CUSTOMER',
          data: customer
        })
      if (workOrderTasks.length > 0)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'WORK_ORDER_TASK',
          data: workOrderTasks
        })

      // If there are tasks, gather related resources
      if (workOrderTasks.length > 0) {
        const workOrderTaskIds = _.map(workOrderTasks, 'id')
        const files = await apiService.files.find({
          query: { workOrderTaskId: { $in: workOrderTaskIds } }
        })
        const billLineItems = await apiService.billLineItems.find({
          query: { workOrderTaskId: { $in: workOrderTaskIds } }
        })

        // and dispatch those
        if (files.length > 0)
          dispatch({
            type: 'FIND_RESOURCES_SUCCESS',
            resource: 'FILE',
            data: files
          })
        if (billLineItems.length > 0)
          dispatch({
            type: 'FIND_RESOURCES_SUCCESS',
            resource: 'BILL_LINE_ITEM',
            data: billLineItems
          })
      }
      return workOrder
    } catch (error) {
      console.error(error)
      dispatch({ type: 'FETCH_ERROR', error })
      return error
    }
  }
}

export function patchWorkOrder(
  id: string,
  updatedWorkOrder: $Shape<WorkOrder>
): Dispatch => Promise<WorkOrder> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      if (_.isNil(id)) throw new Error(`invalid patch id ${id}`)
      const workOrder: WorkOrder = await apiService.workOrders.patch(
        id,
        updatedWorkOrder
      )
      return workOrder
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PATCH_ERROR', error })
      return error
    }
  }
}

export function deleteWorkOrder(id: string): Dispatch => Promise<boolean> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      if (_.isNil(id)) throw new Error(`invalid delete id ${id}`)
      await apiService.workOrders.remove(id).catch(error => {
        if (error.code === 404) {
          console.warn('Gracefully handled error:', error)
          // If not found we assume it's already deleted
          dispatch({
            type: 'DELETE_RESOURCES_SUCCESS',
            resource: 'WORK_ORDER',
            ids: [id]
          })
        } else {
          return error
        }
      })
      return true
    } catch (error) {
      console.error(error)
      dispatch({ type: 'DELETE_ERROR', error })
      return error
    }
  }
}
