// @flow
import * as React from 'react'
import _ from 'lodash'
import { Tooltip } from 'reactstrap'

type Props = {|
  render: Function,
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end',
  tip: string,
  delay?: number,
  hideDelay?: number,
  disabled?: boolean,
  className?: string
|}

type State = {
  tooltipOpen: boolean
}

export class TT extends React.Component<Props, State> {
  id: string
  toggle: Function

  static defaultProps = {
    delay: 500,
    hideDelay: 150,
    disabled: false
  }

  constructor(props: Props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      tooltipOpen: false
    }
    this.id = `target-${_.random(1e7)}`
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }

  render() {
    if (this.props.disabled) {
      return this.props.render(this.id)
    }
    return (
      <React.Fragment>
        {this.props.render(this.id)}
        <Tooltip
          placement={this.props.placement || 'top-start'}
          isOpen={this.state.tooltipOpen}
          target={this.id}
          toggle={this.toggle}
          className={_.compact(['d-print-none', this.props.className]).join(
            ' '
          )}
          delay={{ show: this.props.delay, hide: this.props.hideDelay }}
          {..._.omit(this.props, [
            'placement',
            'delay',
            'hideDelay',
            'tip',
            'render',
            'className'
          ])}
        >
          {this.props.tip}
        </Tooltip>
      </React.Fragment>
    )
  }
}

export default TT
