// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import SortArrows from '../SortArrows'

import type { ListFilters, Dispatch, State as ReduxState } from '../../types'

type Props = {
  +filters: ListFilters,
  setFilters: ListFilters => void
}

const BillListHeaders = (props: Props) => {
  const { filters } = props
  const onWhat = _.get(filters, 'orderBy', 'plannedCompletionAt')
  const order = _.get(filters, 'order', 'asc')
  return (
    <div className="list-header">
      <div className="col">naam</div>
      <div className="col-3">eigenaar / boot</div>
      <div className="col-2 text-right">openstaand</div>
      <div
        className="col-2 sortable justify-content-end"
        onClick={() => {
          const newQuery = { ...filters }
          if (onWhat === 'openedAt') {
            newQuery.orderBy = 'openedAt'
            newQuery.order = order === 'asc' ? 'desc' : 'asc'
          } else {
            newQuery.orderBy = 'openedAt'
            newQuery.order = 'asc'
          }
          props.setFilters(newQuery)
        }}
      >
        geopend
        <SortArrows onWhat={onWhat} order={order} thisResource="openedAt" />
      </div>
      <div className="list-item-state-header">
        <span>status</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => {
  return {
    filters: state.listFilters.bills
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'bills', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillListHeaders)
