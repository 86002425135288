// @flow
import 'babel-polyfill'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// $FlowFixMe - temporary fix for flow error in redux-persist
import { PersistGate } from 'redux-persist/integration/react'
// $FlowFixMe
import './styles/index.scss'
import { store, persistor } from './store'
import App from './pages/App'

import 'moment/locale/nl'

const host = process.env.REACT_APP_API_HOST || 'localhost'
const port = process.env.REACT_APP_API_PORT || '8081'

export const endpoint =
  window.apiHost !== 'REPLACE_API_HOST' ? window.apiHost : `${host}:${port}`

const root = document.getElementById('root')
if (root) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate laoding={null} persistor={persistor}>
        <App persistor={persistor} />
      </PersistGate>
    </Provider>,
    root
  )
}
