// @flow
import qs from 'qs'
import _ from 'lodash'
import type { QueryObject, ResourceArray, ResponseError } from '../types'

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export class Api {
  hostName: string
  headers: Headers

  constructor() {
    this.headers = new Headers({
      'Content-Type': 'application/json'
    })
  }

  connect(token: string, host: string): void {
    this.hostName = host
    this.headers.set('Authorization', `Bearer ${token}`)
  }

  async _call(
    resource: string,
    method: Method,
    id: ?string,
    body: ?Object | ?Array<Object> | FormData,
    filters: ?QueryObject,
    subResource: ?string
  ): Promise<any> {
    let path: string = resource
    if (id != null) path += `/${id}`
    if (subResource != null) path += `/${subResource}`
    if (filters != null) {
      const qf: any = Object.assign({}, filters)
      if (qf.$skip != null) qf.$skip = qf.$skip.toString()
      if (qf.$limit != null) qf.$limit = qf.$limit.toString()
      path += `?${qs.stringify(_.pickBy(qf, _.identity))}`
    }

    const params: any = {
      method,
      headers: this.headers,
      body: null
    }
    if (body != null && body instanceof FormData) {
      params['body'] = body
      // Fetch flips its shit when you define content-type
      params['headers'].delete('Content-Type')
    } else if (body != null && _.isObject(body)) {
      params['body'] = JSON.stringify(body)
    }
    const res = await fetch(`${this.hostName}/${path}`, params)
    const result: any = await res.json()
    if (!res.ok) throw new Error((result: ResponseError).message)
    if (method === 'GET' && id == null) {
      return (result: ResourceArray)
    }
    return (result: Object)
  }

  service(resource: string) {
    return {
      get: (
        id: string,
        filters: ?QueryObject,
        subResource: ?string
      ): Promise<Object> => {
        return this._call(resource, 'GET', id, null, filters, subResource)
      },
      find: async (
        filters: ?QueryObject,
        subResource: ?string
      ): Promise<ResourceArray> => {
        return this._call(resource, 'GET', null, null, filters, subResource)
      },
      create: async (
        body: Object | FormData,
        filters: ?QueryObject,
        subResource: ?string
      ): Promise<Object> => {
        return this._call(resource, 'POST', null, body, filters, subResource)
      },
      update: (
        id: ?string,
        body: Object,
        filters: ?QueryObject,
        subResource: ?string
      ): Promise<Object> => {
        return this._call(resource, 'PUT', id, body, filters, subResource)
      },
      patch: (
        id: string,
        body: Object,
        filters: ?QueryObject,
        subResource: ?string
      ): Promise<Object> => {
        return this._call(resource, 'PATCH', id, body, filters, subResource)
      },
      remove: (
        id: ?string,
        filters: ?QueryObject,
        subResource: ?string
      ): Promise<ResourceArray> => {
        return this._call(resource, 'DELETE', id, null, filters, subResource)
      }
    }
  }
}

const api: Api = new Api()
export default api
