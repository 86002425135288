// @flow
import * as React from 'react'
import _ from 'lodash'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import FileIcon, { defaultStyles } from 'react-file-icon'
import PdfPreview from './PdfPreview'
import Button from './Button'

import type { Attachment } from '../types'
import { endpoint } from '../'

type Props = {
  className?: string,
  files: ?(Attachment[]),
  removeFile: ?(id: string) => void
}

type State = {
  modalOpen: boolean,
  fileInModal: ?string
}

export default class Files extends React.Component<Props, State> {
  apiHost: string
  toggle: () => void
  openModal: string => void
  url: Attachment => ?string
  renderPreview: Attachment => React.Element<'div'>
  renderFullsized: (?string) => React.Element<'div'>

  static defaultProps = {
    files: []
  }

  constructor(props: Props) {
    super(props)
    this.apiHost = endpoint
    this.toggle = this.toggle.bind(this)
    this.openModal = this.openModal.bind(this)
    this.renderPreview = this.renderPreview.bind(this)
    this.renderFullsized = this.renderFullsized.bind(this)
    this.state = {
      modalOpen: false,
      fileInModal: null
    }
  }

  toggle() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  openModal(id: string) {
    this.setState({
      modalOpen: true,
      fileInModal: id
    })
  }

  fileType(file: File | Attachment) {
    if (file.type == null) return null
    if (file.type.slice(0, 5) === 'image') return 'image'
    if (file.type.slice(-3) === 'pdf') return 'pdf'
    return null
  }

  url(file: Attachment) {
    if (_.get(file, 'preview') != null) {
      return _.get(file, 'preview')
    }
    if (_.get(file, 'url') != null) {
      return `${endpoint}/files/${_.get(file, 'url')}`
    }
    return null
  }

  renderPreview(file: Attachment) {
    const url = this.url(file)
    if (url == null) return null
    return (
      <div
        className="file"
        key={file.id}
        onClick={() => this.openModal(file.id)}
      >
        {this.fileTypePreview(file, url)}
        {this.props.removeFile != null ? (
          <div
            className="close-button bg-coral"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              this.props.removeFile != null && this.props.removeFile(file.id)
              return false
            }}
          >
            <i className="material-icons">clear</i>
          </div>
        ) : null}
      </div>
    )
  }

  fileTypePreview(file: Attachment, url: string) {
    const ext = _.last(url.split('.'))
    switch (this.fileType(file)) {
      case 'image':
        return (
          <img
            style={{ maxHeight: '100px' }}
            src={url}
            alt=""
            className="img-thumbnail file-thumbnail"
          />
        )
      case 'pdf':
        return (
          <PdfPreview filename={url} height={100} className="file-thumbnail" />
        )
      default:
        return (
          <FileIcon
            extension={ext}
            {..._.get(defaultStyles, ext)}
            className="file-thumbnail"
            size={90}
          />
        )
    }
  }

  renderFullsized(id: ?string) {
    const file = _.find(this.props.files, { id })
    if (file == null) return null
    const url = this.url(file)
    if (url == null) return null
    switch (this.fileType(file)) {
      case 'image':
        return <img className="img-fluid" src={url} alt="" />
      case 'pdf':
        return (
          <React.Fragment>
            <PdfPreview filename={url} displayPages />
            <Button className="btn-primary mt-2" href={url} target="_blank">
              Download
            </Button>
          </React.Fragment>
        )
      default:
        return (
          <Button className="btn-primary" href={url} target="_blank">
            Download
          </Button>
        )
    }
  }

  render() {
    const { files, className } = this.props
    if (files == null || _.isEmpty(files))
      return <div className={_.compact([className, 'files']).join(' ')} />
    return (
      <React.Fragment>
        <div className={_.compact([className, 'files']).join(' ')}>
          {_.map(files, this.renderPreview.bind(this))}
        </div>
        <Modal
          size="xl"
          className="text-center"
          centered
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle} />
          <ModalBody>
            <div className="full-sized-file text-center">
              {this.renderFullsized(this.state.fileInModal)}
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}
