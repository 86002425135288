// @flow
import feathers from '@feathersjs/client'
import io from 'socket.io-client'

import type {
  Attachment,
  DropzoneFile,
  Bill,
  BillLineItem,
  Boat,
  BoatLegacyMaintenance,
  Customer,
  Invoice,
  InvoiceLineItem,
  Product,
  Stand,
  User,
  WorkOrder,
  WorkOrderTask,
  CreateInvoice
} from '../types'

type Service<T, I = T, F = Array<T>> = {
  find: (params: Object) => Promise<F>,
  get: (id: string | number, params: ?Object) => Promise<T>,
  create: (data: I, params: ?Object) => Promise<T>,
  update: (id: ?(string | number), data: T, params: ?Object) => Promise<T>,
  patch: (
    id: ?(string | number),
    data: $Shape<T>,
    params: ?Object
  ) => Promise<T>,
  remove: (id: ?(string | number), params: ?Object) => Promise<string | number>
}

export class WS {
  ready: Promise<void>
  isReady: () => void
  app: any
  socket: any
  billLineItems: Service<BillLineItem>
  bills: Service<Bill>
  boats: Service<Boat>
  boatLegacyMaintenance: Service<BoatLegacyMaintenance>
  customers: Service<Customer>
  invoices: Service<Invoice, CreateInvoice>
  invoiceLineItems: Service<InvoiceLineItem>
  files: Service<Attachment, DropzoneFile>
  products: Service<Product>
  stands: Service<Stand>
  users: Service<User>
  workOrders: Service<WorkOrder>
  workOrderTasks: Service<WorkOrderTask>

  constructor() {
    this.ready = new Promise(resolve => {
      this.isReady = resolve
    })
  }
  async connect(host: string, { storage, storageKey }: Object) {
    this.socket = io(host)
    this.app = feathers()

    this.app.configure(feathers.socketio(this.socket, { timeout: 15000 }))
    this.app.configure(
      feathers.authentication({
        storageKey,
        storage
      })
    )
    return this.app.authenticate().then(() => {
      this.billLineItems = this.app.service('bill-line-items')
      this.bills = this.app.service('bills')
      this.boats = this.app.service('boats')
      this.boatLegacyMaintenance = this.app.service('legacy-maintenance')
      this.customers = this.app.service('customers')
      this.files = this.app.service('files')
      this.invoices = this.app.service('invoices')
      this.invoiceLineItems = this.app.service('invoice-line-items')
      this.products = this.app.service('products')
      this.stands = this.app.service('stands')
      this.users = this.app.service('users')
      this.workOrders = this.app.service('work-orders')
      this.workOrderTasks = this.app.service('work-order-tasks')
      this.isReady()
      // this.app
      //   .service('products')
      //   .find({ query: { $or: [{ id: { $in: [10] } }] } })
      //   .then(console.log)
    })
  }
}

const api = new WS()
export default api
