// @flow
import * as React from 'react'
import NumberInput from './NumberInput'

type Props = {
  onBlur: (curr: number | null) => Promise<void>,
  placeholder?: string | number,
  value: ?number,
  className?: string,
  disabled: boolean,
  precision: { min: number, max: number },
  allowNull: boolean,
  allowNegative: boolean
}

export default class CurrencyInput extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
    precision: { min: 2, max: 2 },
    allowNull: false,
    allowNegative: true
  }

  render() {
    if (this.props.allowNull) {
      const value = Number(this.props.value)
      if (value === 0) {
        return <NumberInput {...this.props} value={null} />
      }
    }
    return <NumberInput {...this.props} value={Number(this.props.value)} />
  }
}
