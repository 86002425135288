// @flow
import * as React from 'react'
import TextArea from 'react-textarea-autosize'
import Print from '../Print'
import { TT } from '../Tooltip'

type Props = {
  onBlur: (value: string) => Promise<void>,
  value: ?string,
  className?: string,
  placeholder?: string,
  disabled?: boolean,
  textarea?: boolean,
  rows?: number,
  cols?: number,
  tip?: ?string,
  tipDelay?: ?number
}

type State = {
  value: ?string
}

class Input extends React.Component<Props, State> {
  state = {
    value: null
  }

  static defaultProps = {
    disabled: false,
    rows: 3,
    cols: 10
  }

  componentWillUnmount() {
    if (this.state.value != null && this.state.value !== this.props.value) {
      this.props.onBlur(this.state.value)
    }
  }

  async onBlur({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) {
    if (value !== this.props.value) {
      await this.props.onBlur(value)
    }
    this.setState({ value: null })
  }

  onChange({ target }: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ value: target.value })
  }

  handleFocus(event: SyntheticInputEvent<HTMLInputElement>) {
    event.target.select()
  }

  render() {
    const value =
      this.state.value != null
        ? this.state.value
        : this.props.value != null
        ? this.props.value
        : ''
    return (
      <React.Fragment>
        <TT
          disabled={this.props.disabled}
          tip={this.props.tip || 'Klik om te wijzigen'}
          delay={
            this.props.tipDelay || (this.props.tip != null ? 0 : undefined)
          }
          render={id => this.renderInput(id, value)}
        />
        <Print className={['d-none', this.props.className].join(' ')}>
          {value}
        </Print>
      </React.Fragment>
    )
  }

  renderInput(id: ?string, value: string) {
    if (this.props.textarea) {
      return (
        <TextArea
          id={id}
          className={['form-control d-print-none', this.props.className].join(
            ' '
          )}
          placeholder={this.props.placeholder}
          onBlur={this.onBlur.bind(this)}
          onChange={this.onChange.bind(this)}
          value={value}
          disabled={this.props.disabled}
          cols={this.props.cols}
          rows={this.props.rows}
          onFocus={this.handleFocus}
        />
      )
    } else {
      return (
        <input
          id={id}
          className={['form-control d-print-none', this.props.className].join(
            ' '
          )}
          type="text"
          onBlur={this.onBlur.bind(this)}
          onChange={this.onChange.bind(this)}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          value={value}
          onFocus={this.handleFocus}
        />
      )
    }
  }
}

export default Input
