// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import { orderByFromQuery } from '../utils/query'

import type { Customer, State, ListFilters } from '../types'

type OwnProps = { query: ListFilters }
type S = {
  customers: Customer[]
}
type Props = OwnProps & S

class Customers extends React.Component<Props> {
  // TODO: replace this placeholder content!
  render() {
    const { customers } = this.props
    return [
      customers.map(d => (
        <div key={d.id}>
          Customer {d.id} - {d.firstName}
        </div>
      ))
    ]
  }
}

const getSortOptions = orderByFromQuery({ defaultOrder: 'lastName' })
const getCustomers = memoizeOne((customers, orderBy) =>
  _.orderBy(customers, ...orderBy)
)

const mapStateToProps = (state: State, ownProps: OwnProps): S => {
  const customers = getCustomers(
    state.resources.customers,
    getSortOptions(ownProps.query)
  )
  return { customers }
}

export default connect(mapStateToProps)(Customers)
