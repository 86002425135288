// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import Fuse from 'fuse.js'
import { InputGroup } from 'reactstrap'

import SearchBar from '../Search/SearchBar'
import BoatSideListItem from './BoatSideListItem'
import { orderByFromQuery } from '../../utils'

import type {
  Boat,
  Customer,
  Dispatch,
  ListFilters,
  State as ReduxState,
  StoreSlice
} from '../../types'

type OwnProps = {|
  activeBoatId: string
|}
type S = {
  +boats: Boat[],
  +filters: ListFilters
}
type D = {
  setFilters: ListFilters => void
}
type Props = OwnProps & S & D

class Boats extends React.Component<Props, {}> {
  render() {
    const { boats, filters, activeBoatId } = this.props
    return (
      <div className="container p-0">
        <div className="sticky-top bg-white border-bottom-grey">
          <InputGroup className="rounded-0">
            <SearchBar filters={filters} setFilters={this.props.setFilters} />
          </InputGroup>
          <div className="list-header small">
            <div className="col">naam / eigenaar / boot</div>
            <div className="col-auto ml-auto">openstaand</div>
            <div className="col-auto pr-2">status</div>
          </div>
        </div>
        {_.map(boats, boat => (
          <BoatSideListItem
            key={boat.id}
            boatId={boat.id}
            boat={boat}
            active={boat.id === activeBoatId}
          />
        ))}
      </div>
    )
  }
}

// Direct copy (atm) of BoatList
const getFilterOptions = (filters: ListFilters) => {
  const filterableFields = ['customerId', 'boatId', 'state']
  return _.pick(filters, filterableFields)
}
const getSortOptions = orderByFromQuery({ defaultSort: 'openedOn' })
const getBoats = memoizeOne((boats, filters, orderBy) =>
  _(boats)
    .filter(filters)
    .orderBy(...orderBy)
    .value()
)
const getFuseFilter = memoizeOne(
  (boats: StoreSlice<Boat>, customers: StoreSlice<Customer>) => {
    const list = _.map(boats, boat => ({
      ...boat,
      customer: customers[boat.ownerId]
    }))
    const options = {
      threshold: 0.25,
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      minMatchCharLength: 2,
      keys: [
        'name',
        'boatType',
        'customer.firstName',
        'customer.lastNamePrefix',
        'customer.lastName',
        'customer.city',
        'customer.email',
        'customer.companyName'
      ]
    }
    return new Fuse<Boat>(list, options)
  }
)

const mapStateToProps = (state: ReduxState): S => {
  const filters = state.listFilters.boats
  const fuse = getFuseFilter(state.resources.boats, state.resources.customers)
  const { q } = filters
  const queriedBoats = q == null ? state.resources.boats : fuse.search(q)
  let boats = getBoats(
    queriedBoats,
    getFilterOptions(filters),
    getSortOptions(filters)
  )
  return {
    boats,
    filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch): D => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'boats', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Boats)
