// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, ModalHeader } from 'reactstrap'
import BoatSelect from '../Select/BoatSelect'
import Button from '../Button'
import Input from '../Input/TextInput'
import { createWorkOrder } from '../../actions'
import { customerName, defaultWOName } from '../../utils'

import type { RouterHistory } from 'react-router'
import type {
  Dispatch,
  WorkOrder,
  Boat,
  Customer,
  StoreSlice,
  State as ReduxState
} from '../../types'

type OwnProps = {
  history: RouterHistory
}
type S = {
  customers: StoreSlice<Customer>
}
type D = {
  createWorkOrder: (
    boatId: number,
    customerId: string,
    name: string
  ) => Promise<WorkOrder>
}
type Props = OwnProps & S & D

type State = {
  selectedBoat: ?Boat,
  name: ?string,
  modal: boolean
}

class NewWorkOrderButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      modal: false,
      selectedBoat: null,
      name: null
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle: Function
  toggle() {
    this.setState({
      modal: !this.state.modal
    })
  }

  async createWorkOrder() {
    if (this.state.selectedBoat != null) {
      const { id: boatId, ownerId: customerId } = this.state.selectedBoat
      let name = defaultWOName(this.state.selectedBoat)
      if (this.state.name != null) {
        name = this.state.name
      }
      const newWorkOrder: ?WorkOrder = await this.props.createWorkOrder(
        boatId,
        customerId,
        name
      )
      if (newWorkOrder != null) {
        this.toggle()
        this.props.history.push(`/work-orders/${newWorkOrder.id}`)
      }
    }
  }

  render() {
    let namePlaceholder = 'Werkopdracht naam (optioneel)'
    let customer = null
    if (this.state.selectedBoat) {
      customer = this.props.customers[this.state.selectedBoat.ownerId]
      namePlaceholder = defaultWOName(this.state.selectedBoat)
    }
    return (
      <React.Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Nieuwe werkopdracht</ModalHeader>
          <div className="modal-body">
            <BoatSelect
              autoFocus
              tabIndex="0"
              onSelect={(boat: ?Boat) => this.setState({ selectedBoat: boat })}
            />
            {customer != null ? (
              <div className="small p-2">
                <span className="text-muted">Eigenaar: </span>
                {customerName(customer, { withCity: true })} (
                {customer.customerReference})
              </div>
            ) : null}
            <Input
              tabIndex="0"
              className="mt-2 border px-2"
              value={this.state.name}
              placeholder={namePlaceholder}
              onBlur={value => {
                if (value !== '') this.setState({ name: value })
                return Promise.resolve()
              }}
            />
          </div>
          <div className="modal-footer">
            <Button
              tabIndex="1"
              className="btn btn-secondary"
              onClick={this.toggle}
            >
              Sluiten
            </Button>
            <Button
              tabIndex="0"
              className="btn btn-primary"
              disabled={this.state.selectedBoat == null}
              onClick={this.createWorkOrder.bind(this)}
            >
              Nieuwe werkopdracht
            </Button>
          </div>
        </Modal>
        <Button
          containerClassName="ml-3"
          onClick={this.toggle}
          className="btn btn-secondary btn-sm text-white"
          innerClassName="d-flex align-items-center"
        >
          <i className="material-icons small mr-1">add</i>
          Nieuw
        </Button>
      </React.Fragment>
    )
  }
}

const mapState = (state: ReduxState): S => {
  return {
    customers: state.resources.customers
  }
}

const mapDispatch = (dispatch: Dispatch): D => {
  return {
    createWorkOrder: (
      boatId: number,
      customerId: string,
      name: string
    ): Promise<WorkOrder> =>
      dispatch(createWorkOrder(boatId, customerId, { name }))
  }
}

export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(NewWorkOrderButton)
)
