// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { customerName } from '../../utils'

import type { Boat, Customer, State as ReduxState } from '../../types'

type OwnProps = {
  boatId: string,
  boat: Boat,
  active: ?boolean
}
type S = {
  customer: ?Customer
}
type Props = OwnProps & S

const BoatListItem = (props: Props) => {
  const {
    boat: { id, name, boatType },
    customer,
    active
  } = props
  return (
    <Link
      className={`list-item boat-list-item short ${active ? ' active' : ''}`}
      to={`/boats/${id}`}
    >
      <div className="col">
        <h6>
          {name} <span className="font-weight-normal">{boatType}</span>
        </h6>
        <div className="d-flex">
          <div className="d-flex align-items-center small">
            <i className="material-icons inline">person</i>
            {customerName(customer, { withCity: true })}
          </div>
        </div>
      </div>
    </Link>
  )
}

// Direct copy (atm) of BoatListItem
const makeMapState = () => {
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const { ownerId } = ownProps.boat
    const { customers } = state.resources
    return {
      customer: ownerId != null ? customers[ownerId] : null
    }
  }
  return mapState
}

export default connect(makeMapState)(BoatListItem)
