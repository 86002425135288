// @flow
import * as React from 'react'
import Dropzone from 'react-dropzone'

import type { DropzoneFile } from 'react-dropzone'

type OwnProps = {
  addFiles: (files: DropzoneFile[]) => void,
  children: any,
  setRef: (?Dropzone) => void,
  disabled?: boolean
}
type DispatchProps = {
  addFiles: (DropzoneFile[]) => void
}
type Props = OwnProps & DispatchProps

type State = {
  dropzoneActive: boolean
}

export default class TaskFilesDropzone extends React.Component<Props, State> {
  static defaultProps = {
    files: [],
    setRef: () => {}
  }

  state = {
    dropzoneActive: false
  }

  addFile(acceptedFiles: DropzoneFile[]) {
    this.setState({ dropzoneActive: false })
    this.props.addFiles(acceptedFiles)
  }

  render() {
    if (this.props.disabled) return <div>{this.props.children}</div>
    return (
      <>
        <Dropzone
          ref={this.props.setRef}
          onDrop={this.addFile.bind(this)}
          style={{}}
          disableClick
          onDragEnter={() => this.setState({ dropzoneActive: true })}
          onDragLeave={() => this.setState({ dropzoneActive: false })}
          className="d-print-none"
        >
          {(false || this.state.dropzoneActive) && (
            <div className="dropzone-overlay">
              <h4 className="text-primary">
                Sleep bestanden hierheen om te uploaden
              </h4>
            </div>
          )}
          {this.props.children}
        </Dropzone>
        <div className="d-none d-print-block">{this.props.children}</div>
      </>
    )
  }
}
