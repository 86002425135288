// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import Print from '../Print'
import Input from '../Input/TextInput'
import SelectInput from '../Input/SelectInput'
import CurrencyInput from '../Input/CurrencyInput'
import { workOrderTaskCategories } from '../WorkOrderTask/WorkOrderTask'

import { isAdmin } from '../../utils'
import { patchWorkOrderTask } from '../../actions'

import TT from '../Tooltip'

import type {
  Dispatch,
  WorkOrderTask,
  State as ReduxState,
  WithRouterProps as R
} from '../../types'

type OwnProps = {
  taskId: string,
  onDelete: Function
}
type S = {
  admin: boolean,
  task: WorkOrderTask,
  canBeDeleted: boolean
}
type D = {
  changeStringValue: (field: string, value: string) => Promise<void>,
  changeIndicatedPrice: (price: number | null) => Promise<void>,
  toggleState: (state: 'open' | 'completed') => Promise<void>
}
type Props = OwnProps & S & D & R
type State = {
  stateJustSet: boolean,
  moreMenuOpen: boolean
}

class TaskTitleBarComponent extends React.Component<Props, State> {
  state = {
    stateJustSet: false,
    moreMenuOpen: false
  }

  toggle() {
    this.setState({
      moreMenuOpen: !this.state.moreMenuOpen
    })
  }

  render() {
    const {
      task,
      changeStringValue,
      changeIndicatedPrice,
      onDelete,
      toggleState
    } = this.props
    const disabled = !this.props.admin
    return (
      <div className={['work-order-task-title', task.state].join(' ')}>
        <div className="icon-container">
          {this.props.admin ? (
            <TT
              tip={`Taak ${task.state === 'open' ? 'sluiten' : 'heropenen'}`}
              render={id => (
                <div
                  id={id}
                  className={_.compact([
                    'work-order-task-icon',
                    'icon',
                    task.state,
                    this.state.stateJustSet ? 'just-set' : undefined
                  ]).join(' ')}
                  onClick={() => {
                    this.setState({ stateJustSet: true }, () =>
                      toggleState(task.state)
                    )
                  }}
                  onMouseLeave={() => this.setState({ stateJustSet: false })}
                >
                  <i className="material-icons open d-print-none">build</i>
                  <i className="material-icons open d-none d-print-inline">
                    check_box_outline_blank
                  </i>
                  <i className="material-icons done">done</i>
                </div>
              )}
            />
          ) : (
            <div className="icon">
              {task.state === 'open' ? (
                <React.Fragment>
                  <i className="material-icons open d-print-none">build</i>
                  <i className="material-icons open d-none d-print-inline">
                    check_box_outline_blank
                  </i>
                </React.Fragment>
              ) : (
                <i className="material-icons done">done</i>
              )}
            </div>
          )}
        </div>

        <div className="title">
          <div className="row align-items-center">
            <div className="col">
              <Input
                className="form-control h4"
                value={task.name}
                disabled={disabled}
                onBlur={name => changeStringValue('name', name)}
              />
            </div>
            <div className="col-auto d-print-none">
              <SelectInput
                options={_.map(workOrderTaskCategories, (value, key) => ({
                  label: value,
                  value: key
                }))}
                value={task.category}
                disabled={disabled}
                onChange={category => changeStringValue('category', category)}
                className="form-control font-weight-bold"
                placeholder="Categorie"
              />
            </div>
            <TT
              tip="Prijs die is afgesproken met de klant"
              render={id => (
                <Print
                  id={id}
                  className="col-2 d-flex align-items-center"
                  print={false}
                >
                  <i className="material-icons">euro_symbol</i>
                  <CurrencyInput
                    className="form-control font-weight-bold text-right dark-placeholder"
                    value={task.indicatedPrice}
                    placeholder="—"
                    disabled={disabled}
                    onBlur={changeIndicatedPrice}
                    allowNull
                  />
                </Print>
              )}
            />
            <Dropdown
              id="more-menu"
              isOpen={this.state.moreMenuOpen}
              toggle={this.toggle.bind(this)}
            >
              <DropdownToggle
                tag="div"
                className="col-auto px-2 clickable d-flex align-items-center d-print-none"
              >
                <i className="material-icons">more_vert</i>
              </DropdownToggle>
              <DropdownMenu right className="mt-2">
                <DropdownItem
                  onClick={onDelete}
                  className="clickable"
                  disabled={disabled}
                >
                  Deze taak verwijderen
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps & R): S => {
  const id = ownProps.taskId
  const { workOrderTasks, billLineItems } = state.resources
  const task = workOrderTasks[id]
  const canBeDeleted = _(billLineItems)
    .filter(v => v.workOrderTaskId === id)
    .every(v => v.state === 'invoiced')
  return {
    admin: isAdmin(state),
    task,
    canBeDeleted
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps & R): D => {
  const id = ownProps.taskId
  return {
    toggleState: (state: 'open' | 'completed') => {
      if (state == null) return Promise.resolve()
      if (state !== 'open' && state !== 'completed')
        throw new Error('Invalid task state')
      const value = state === 'open' ? 'completed' : 'open'
      return dispatch(patchWorkOrderTask({ id, state: value }))
    },
    changeIndicatedPrice: (indicatedPrice: number | null) =>
      dispatch(patchWorkOrderTask({ id, indicatedPrice })),
    changeStringValue: (field: string, value: string) => {
      if (field === 'name' || field === 'category')
        return dispatch(patchWorkOrderTask({ id, [field]: value }))
      return Promise.resolve()
    }
  }
}

export const TaskTitleBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskTitleBarComponent)

export default TaskTitleBar
