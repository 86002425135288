// @flow
import * as React from 'react'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { ListStateIcon } from '../ListStateIcon'
import { Currency, DateString } from '../TextUtils'
import { customerName, grossTotal, makeGetBLIsForBillId } from '../../utils'

import type {
  Boat,
  Customer,
  Bill as BillType,
  BillProgress,
  BillLineItem,
  State as ReduxState
} from '../../types'

type OwnProps = {
  billId: string,
  bill: BillType
}
type S = {
  boat: ?Boat,
  customer: ?Customer,
  amountOutstanding: number,
  progress: BillProgress
}
type Props = OwnProps & S

const progressState = (progress: BillProgress) => {
  if (progress === 'open')
    return {
      color: 'primary',
      icon: 'euro_symbol',
      tip: 'Klaar om te factureren'
    }
  if (progress === 'invoiced')
    return {
      color: 'secondary',
      icon: 'check',
      tip: 'Gefactureerd'
    }
  if (progress === 'empty')
    return {
      color: 'dark',
      icon: 'more_horiz',
      tip: 'Nog geen items op de rekening'
    }
  return {
    color: 'coral',
    icon: 'warning',
    tip: 'Hier klopt iets niet :('
  }
}

const BillListItem = (props: Props) => {
  const {
    boat,
    customer,
    amountOutstanding,
    bill: { id, name, state, openedAt },
    progress
  } = props
  return (
    <Link className="list-item bill-list-item" to={`/bills/${id}`}>
      <div className="col">
        <h4>{name}</h4>
      </div>
      <div className="col-3">
        <div className="d-flex align-items-center">
          <i className="material-icons inline">person</i>
          {customerName(customer)}
        </div>
        <div className="d-flex align-items-center">
          <i className="material-icons inline">directions_boat</i>
          <strong>{boat != null ? boat.name : '...'}</strong>
        </div>
      </div>
      <div className="col-2 lead text-right">
        {amountOutstanding ? (
          <Currency>{amountOutstanding}</Currency>
        ) : (
          <i className="material-icons">check</i>
        )}
      </div>
      <div className="col-2 text-right">
        <DateString>{openedAt}</DateString>
      </div>
      <ListStateIcon state={state} {...progressState(progress)} />
    </Link>
  )
}

// Direct copy (atm) of BillSideListItem
const makeMapState = () => {
  const getProgress = memoizeOne(
    (blis: BillLineItem[]): BillProgress => {
      if (blis == null || _.size(blis) === 0) return 'empty'
      if (blis != null && _.every(blis, { state: 'invoiced' }))
        return 'invoiced'
      return 'open'
    }
  )
  const getBLIs = makeGetBLIsForBillId()
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const { boatId, customerId } = ownProps.bill
    const { boats, customers } = state.resources
    const billLineItems = getBLIs(state, ownProps)
    const amountOutstanding = _.sumBy(
      billLineItems.filter(d => d.state === 'open'),
      grossTotal
    )
    const progress = getProgress(billLineItems)
    return {
      boat: boatId != null ? boats[boatId] : null,
      customer: customerId != null ? customers[customerId] : null,
      amountOutstanding,
      progress
    }
  }
  return mapState
}

export default connect(makeMapState)(BillListItem)
