// @flow
import * as React from 'react'
import _ from 'lodash'
import NumberInput from './NumberInput'

type Props = {
  onBlur: (value: number | null) => Promise<void>,
  value: number | null,
  className?: string,
  placeholder?: number,
  disabled?: boolean,
  allowNull?: boolean
}

const PercentageInput = (props: Props) => (
  <React.Fragment>
    <NumberInput
      {...props}
      allowNull={true}
      className={['percentage', props.className].join(' ')}
      onBlur={(pct: number | null) => {
        if (pct == null) {
          if (props.allowNull) {
            return props.onBlur(null)
          } else {
            return props.onBlur(0)
          }
        } else {
          if (!_.isNumber(pct) || pct < 0 || pct > 100) {
            return props.onBlur(props.value)
          }
          return props.onBlur(pct / 100)
        }
      }}
      value={props.value ? props.value * 100 : null}
    />
    <span className="percentage-sign">%</span>
  </React.Fragment>
)

export default PercentageInput
