// @flow
type Auth = { email: string, password: string }
type ConnectionOptions = {
  storage: Storage,
  storageKey: string
}
declare var window: {
  authenticationHost: string
}

export class Authentication {
  host: string
  storageKey: string
  storage: Storage

  connect(host: string, { storage, storageKey }: ConnectionOptions): ?string {
    this.host = host
    this.storageKey = storageKey
    this.storage = storage

    const accessToken: ?string = this.storage.getItem(this.storageKey)
    if (accessToken != null) {
      this.setToken(accessToken)
      return accessToken
    }
  }

  getToken(): ?string {
    return this.storage.getItem(this.storageKey)
  }

  setToken(accessToken: string) {
    this.storage.setItem(this.storageKey, accessToken)
  }

  async login(credentials: Auth): Promise<boolean | Error> {
    const res = await fetch(`${this.host}/authentication`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ ...credentials, strategy: 'local' })
    })
    type Success = { accessToken: string }
    type Failed = { message: string }
    const data = await res.json()
    if (!res.ok) {
      throw new Error((data: Failed).message)
    }

    this.setToken((data: Success).accessToken)

    return true
  }

  logout() {
    this.storage.removeItem(this.storageKey)
  }
}

const authentication: Authentication = new Authentication()
export default authentication
