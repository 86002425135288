// @flow
import * as React from 'react'
import { Modal, ModalHeader } from 'reactstrap'

import { Button } from './Button'
import { Newlines } from './TextUtils'

type Props = {
  className?: ?string,
  title?: ?string,
  text: string,
  render: Function,
  onConfirm: Function
}

type State = {
  modal: boolean
}

export class Confirmation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      modal: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle: Function
  toggle() {
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {this.props.title || 'Weet u het zeker?'}
          </ModalHeader>
          <div className="modal-body">
            <Newlines>{this.props.text}</Newlines>
          </div>
          <div className="modal-footer">
            <Button className="btn btn-secondary" onClick={this.toggle}>
              Nee
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.toggle()
                return this.props.onConfirm()
              }}
            >
              Ja
            </Button>
          </div>
        </Modal>
        {this.props.render(this.toggle)}
      </React.Fragment>
    )
  }
}

export default Confirmation
