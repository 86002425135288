// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import Fuse from 'fuse.js'
import { Helmet } from 'react-helmet'

import SearchBar from '../Search/SearchBar'
import WorkOrderListItem from './WorkOrderListItem'
import WorkOrderListHeaders from './WorkOrderListHeaders'
import NewWorkOrderButton from './NewWorkOrderButton'
import { orderByFromQuery, isAdmin } from '../../utils'

import type {
  BillLineItem,
  Boat,
  Customer,
  Dispatch,
  ListFilters,
  State as ReduxState,
  StoreSlice,
  WorkOrder,
  WorkOrderProgress,
  WorkOrderTask
} from '../../types'

type OwnProps = {||}
type S = {
  admin: boolean,
  filters: ListFilters,
  workOrders: StoreSlice<WorkOrder>,
  tasksByWorkOrder: StoreSlice<WorkOrderTask[]>,
  blisByWorkOrder: StoreSlice<BillLineItem[]>,
  boats: StoreSlice<Boat>,
  customers: StoreSlice<Customer>
}
type D = {
  setFilters: ListFilters => void
}
type Props = OwnProps & S & D

const progress = memoizeOne(
  (tasks: ?(WorkOrderTask[]), blis: ?(BillLineItem[])): WorkOrderProgress => {
    if (tasks == null || _.size(tasks) === null) return 'empty'
    if (_.some(tasks, { state: 'open' })) return 'toDo'
    if (blis != null && _.every(blis, { state: 'invoiced' })) return 'invoiced'
    return 'readyToInvoice'
  }
)

class WorkOrders extends React.Component<Props, {}> {
  handleFilterChoice(choice: ?WorkOrderProgress) {
    const current = this.props.filters.workOrderProgress
    if (choice !== current) {
      const filters = { ...this.props.filters, workOrderProgress: choice }
      return this.props.setFilters(filters)
    }
  }

  renderFilterTabs() {
    const tabState = this.props.filters.workOrderProgress
    const tabs = [
      { name: 'Nog geen taken', progress: 'empty' },
      { name: 'Openstaande taken', progress: 'toDo' },
      { name: 'Te factureren', progress: 'readyToInvoice' },
      { name: 'Gefactureerd', progress: 'invoiced' },
      { name: 'Alles', progress: undefined }
    ]
    return (
      <ul className="nav nav-tabs mb-2">
        {_.map(tabs, ({ name, progress }) => (
          <li className="nav-item" key={progress || 'all'}>
            <div
              onClick={() => this.handleFilterChoice(progress)}
              className={_.compact([
                'nav-link clickable',
                tabState === progress ? 'active' : undefined
              ]).join(' ')}
            >
              {name}
            </div>
          </li>
        ))}
      </ul>
    )
  }

  render() {
    const {
      workOrders,
      tasksByWorkOrder,
      blisByWorkOrder,
      boats,
      customers,
      filters,
      admin
    } = this.props
    return (
      <div className="container">
        <Helmet title="Werkopdrachten" />
        <div className="d-flex align-items-center my-2 pt-2">
          <h1 className="m-0">Werkopdrachten</h1>
          {admin ? <NewWorkOrderButton /> : null}
        </div>
        <div className="sticky-top bg-white pt-2">
          {this.renderFilterTabs()}
          <SearchBar
            filters={filters}
            setFilters={this.props.setFilters}
            placeholder="Filter op klant, boot, opdracht..."
          />
          <WorkOrderListHeaders />
        </div>
        <div className="list work-order-list">
          {_.size(workOrders) > 0 ? (
            _.map(workOrders, workOrder => (
              <WorkOrderListItem
                key={workOrder.id}
                workOrder={workOrder}
                workOrderTasks={tasksByWorkOrder[workOrder.id]}
                billLineItems={blisByWorkOrder[workOrder.id]}
                boat={boats[workOrder.boatId]}
                customer={customers[workOrder.customerId]}
                progress={progress(
                  tasksByWorkOrder[workOrder.id],
                  blisByWorkOrder[workOrder.id]
                )}
              />
            ))
          ) : (
            <h5 className="my-4 text-center text-muted">
              Geen werkopdrachten gevonden
            </h5>
          )}
        </div>
      </div>
    )
  }
}

const getFilterOptions = (filters: ListFilters) => {
  const filterableFields = ['customerId', 'boatId', 'state']
  return _.pick(filters, filterableFields)
}
const getSortOptions = orderByFromQuery({ defaultSort: 'plannedCompletionAt' })
const getWorkOrders = memoizeOne((workOrders, filters, orderBy) =>
  _(workOrders)
    .filter(filters)
    .orderBy(...orderBy)
    .value()
)
const groupedWorkOrderTasks = memoizeOne((workOrders, tasks) =>
  _.groupBy(tasks, 'workOrderId')
)
const groupedBillLineItems = memoizeOne((workOrders, blis) =>
  _.mapValues(blis.idsByWorkOrder, ids => ids.map(id => blis.byId[id]))
)
const getFuseFilter = memoizeOne(
  (
    workOrders: StoreSlice<WorkOrder>,
    boats: StoreSlice<Boat>,
    customers: StoreSlice<Customer>
  ) => {
    const list = _.map(workOrders, workOrder => ({
      ...workOrder,
      boat: boats[workOrder.boatId],
      customer: customers[workOrder.customerId]
    }))
    const options = {
      threshold: 0.25,
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      minMatchCharLength: 2,
      keys: [
        'name',
        'boat.name',
        'boat.boatType',
        'customer.firstName',
        'customer.lastNamePrefix',
        'customer.lastName',
        'customer.city',
        'customer.email',
        'customer.companyName'
      ]
    }
    return new Fuse<WorkOrder>(list, options)
  }
)

const mapStateToProps = (state: ReduxState): S => {
  const filters = state.listFilters.workOrders
  const tasksByWorkOrder = groupedWorkOrderTasks(
    state.resources.workOrders,
    state.resources.workOrderTasks
  )
  const blisByWorkOrder = groupedBillLineItems(
    state.resources.workOrders,
    state.resources.billLineItems
  )
  const fuse = getFuseFilter(
    state.resources.workOrders,
    state.resources.boats,
    state.resources.customers
  )
  const { q, workOrderProgress } = filters
  const queriedWorkOrders =
    q == null ? state.resources.workOrders : fuse.search(q)
  let workOrders: StoreSlice<WorkOrder> = getWorkOrders(
    queriedWorkOrders,
    getFilterOptions(filters),
    getSortOptions(filters)
  )
  if (workOrderProgress != null) {
    workOrders = _.pickBy(
      workOrders,
      ({ id }) =>
        progress(tasksByWorkOrder[id], blisByWorkOrder[id]) ===
        workOrderProgress
    )
  }
  const admin = isAdmin(state)

  return {
    admin,
    workOrders,
    boats: state.resources.boats,
    customers: state.resources.customers,
    tasksByWorkOrder,
    blisByWorkOrder,
    filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch): D => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'workOrders', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrders)
