// @flow
import * as React from 'react'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { ListStateIcon } from '../ListStateIcon'
import { Currency } from '../TextUtils'
import { customerName, grossTotal, makeGetBLIsForBillId } from '../../utils'

import type {
  Boat,
  Customer,
  Bill as BillType,
  BillProgress,
  BillLineItem,
  State as ReduxState
} from '../../types'

type OwnProps = {
  billId: string,
  bill: BillType,
  active: ?boolean
}
type S = {
  boat: ?Boat,
  customer: ?Customer,
  amountOutstanding: number,
  progress: BillProgress
}
type Props = OwnProps & S

const progressState = (progress: BillProgress) => {
  if (progress === 'open')
    return {
      color: 'primary',
      icon: 'euro_symbol',
      tip: 'Klaar om te factureren'
    }
  if (progress === 'invoiced')
    return {
      color: 'secondary',
      icon: 'check',
      tip: 'Gefactureerd'
    }
  if (progress === 'empty')
    return {
      color: 'dark',
      icon: 'more_horiz',
      tip: 'Nog geen items op de rekening'
    }
  return {
    color: 'coral',
    icon: 'warning',
    tip: 'Hier klopt iets niet :('
  }
}

const BillSideListItem = (props: Props) => {
  const {
    boat,
    customer,
    amountOutstanding,
    bill: { id, name, state },
    progress,
    active
  } = props
  return (
    <Link
      className={`list-item bill-list-item short ${active ? ' active' : ''}`}
      to={`/bills/${id}`}
    >
      <div className="col">
        <h6 className="mb-1">{name}</h6>
        <div className="d-flex">
          <div className="d-flex align-items-center small">
            <i className="material-icons inline">person</i>
            {customerName(customer)}
            <i className="material-icons inline ml-2">directions_boat</i>
            <strong>{boat != null ? boat.name : '...'}</strong>
          </div>
          <div className="ml-auto">
            {amountOutstanding ? (
              <Currency>{amountOutstanding}</Currency>
            ) : (
              <i className="material-icons small">check</i>
            )}
          </div>
        </div>
      </div>
      <ListStateIcon state={state} {...progressState(progress)} small />
    </Link>
  )
}


// Direct copy (atm) of BillListItem
const makeMapState = () => {
  const getProgress = memoizeOne(
    (blis: BillLineItem[]): BillProgress => {
      if (blis == null || _.size(blis) === 0) return 'empty'
      if (blis != null && _.every(blis, { state: 'invoiced' }))
        return 'invoiced'
      return 'open'
    }
  )
  const getBLIs = makeGetBLIsForBillId()
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const { boatId, customerId } = ownProps.bill
    const { boats, customers } = state.resources
    const billLineItems = getBLIs(state, ownProps)
    const amountOutstanding = _.sumBy(
      billLineItems.filter(d => d.state === 'open'),
      grossTotal
    )
    const progress = getProgress(billLineItems)
    return {
      boat: boatId != null ? boats[boatId] : null,
      customer: customerId != null ? customers[customerId] : null,
      amountOutstanding,
      progress
    }
  }
  return mapState
}

export default connect(makeMapState)(BillSideListItem)
