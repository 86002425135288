// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import _ from 'lodash'
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

import Input from '../Input/TextInput'
import BillLineItems from '../BillLineItem/BillLineItems'
import { BoatAndCustomerInfo } from '../InfoBlocks'
import { Currency } from '../TextUtils'
import TT from '../Tooltip'
import { getBill, patchBill, createInvoice } from '../../actions'
import { grossTotal, makeGetBLIsForBillId, isAdmin } from '../../utils'

// Types
import type {
  Bill,
  BillLineItem,
  Boat,
  Customer,
  Dispatch,
  State as ReduxState
} from '../../types'
type OwnProps = {
  billId: string
}
type S = {
  admin: boolean,
  amountOutstanding: number,
  total: number,
  canClose: boolean,
  bill: Bill,
  boat: ?Boat,
  customer: ?Customer,
  billLineItems: BillLineItem[]
}
type D = {
  loadBill: () => Promise<Bill>,
  closeBill: () => Promise<void>,
  reopenBill: () => Promise<void>,
  changeName: (name: string) => Promise<void>,
  createInvoice: (Set<string>) => string => Promise<void>
}

type Props = OwnProps & S & D

type State = {
  selected: Set<string>
}

type StatusProps = {
  bill: Bill,
  canClose: boolean,
  closeBill: Function,
  reopenBill: Function,
  disabled: boolean
}

// Components

const Status = ({
  bill,
  closeBill,
  canClose,
  reopenBill,
  disabled
}: StatusProps) => {
  let status = { color: 'light', label: 'Onbekend' }
  if (bill.state != null && bill.state === 'open')
    status = { color: 'success', label: 'Open' }
  else if (bill.state != null && bill.state === 'closed')
    status = { color: 'info', label: 'Gesloten' }
  if (disabled) {
    return (
      <h2>
        <span
          className={`small badge badge-pill badge-${status.color} text-white`}
        >
          {status.label}
        </span>
      </h2>
    )
  }
  return (
    <h2>
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          tag="span"
          className={`small badge badge-pill badge-${status.color} text-white`}
        >
          {status.label}
        </DropdownToggle>
        <DropdownMenu right>
          {bill.state !== 'closed' ? (
            <TT
              tip="Rekening kan pas gesloten als alle regels gefactureerd en alle werkopdrachten gesloten zijn"
              render={id => (
                <DropdownItem
                  id={id}
                  className="btn btn-primary"
                  onClick={() =>
                    canClose
                      ? closeBill()
                      : toast.warning(
                          'Rekening kan pas gesloten als alle regels gefactureerd en alle werkopdrachten gesloten zijn'
                        )
                  }
                >
                  Sluit rekening
                </DropdownItem>
              )}
            />
          ) : null}
          {bill.state !== 'open' && (
            <DropdownItem onClick={reopenBill} className="btn btn-primary">
              Heropen rekening
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </h2>
  )
}

class BillDetail extends React.Component<Props, State> {
  state = {
    selected: new Set()
  }

  componentDidMount() {
    this.props.loadBill()
  }

  render() {
    const { bill, boat, customer } = this.props
    if (bill == null || boat == null || customer == null) {
      return (
        <div className="container">
          <h2>Loading...</h2>
        </div>
      )
    }
    const disabled = !this.props.admin
    return (
      <div className="container">
        <Helmet title={`${bill.name} (Rekening)`} />
        <h5 className="text-muted mb-1 d-print-none">REKENING</h5>
        <div className="row align-items-center">
          <div className="col">
            <Input
              className="h1"
              onBlur={this.props.changeName}
              value={bill.name}
              disabled={disabled}
            />
          </div>
          <div className="col-auto">
            <Status
              bill={bill}
              closeBill={this.props.closeBill}
              canClose={this.props.canClose}
              reopenBill={this.props.reopenBill}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="d-flex justify-content-between">
              <h5 className="text-muted">Nog te factureren</h5>
              <h4>
                <Currency>{this.props.amountOutstanding}</Currency>
              </h4>
            </div>
            <div className="d-flex justify-content-between">
              <h5 className="text-muted">Totaal</h5>
              <h4>
                <Currency>{this.props.total}</Currency>
              </h4>
            </div>
          </div>
          <div className="col text-right" />
        </div>
        <BoatAndCustomerInfo boat={boat} customer={customer} />
        <BillLineItems
          billId={this.props.billId}
          billLineItems={this.props.billLineItems}
          selected={this.state.selected}
          createInvoice={this.props.createInvoice(this.state.selected)}
          selectBLIs={selected => this.setState({ selected })}
        />
      </div>
    )
  }
}

// Redux connect

const filterWorkOrders = memoizeOne((billId, workOrders) =>
  _.filter(workOrders, { billId })
)

const makeMapState = () => {
  const getBLIs = makeGetBLIsForBillId()
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const { billId } = ownProps
    const bill = state.resources.bills[billId]
    const boat =
      bill != null && bill.boatId != null
        ? state.resources.boats[bill.boatId]
        : undefined
    const customer =
      bill != null && bill.customerId != null
        ? state.resources.customers[bill.customerId]
        : undefined
    const billLineItems = _.sortBy(getBLIs(state, ownProps), 'workOrderTaskId')
    const openItems = _.filter(billLineItems, {
      state: 'open',
      parentId: null
    })
    const amountOutstanding = _.sumBy(openItems, grossTotal)
    const total = _.round(_.sumBy(billLineItems, grossTotal), 2)
    const workOrders = filterWorkOrders(billId, state.resources.workOrders)
    const admin = isAdmin(state)
    const canClose =
      admin &&
      openItems.length === 0 &&
      _.every(workOrders, { state: 'closed' })
    return {
      admin,
      amountOutstanding,
      bill,
      total,
      boat,
      customer,
      billLineItems,
      canClose
    }
  }
  return mapState
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  const { billId: id } = ownProps
  return {
    loadBill: () => dispatch(getBill(id)),
    createInvoice: bliIds => orderStatus =>
      dispatch(createInvoice([...bliIds], orderStatus)),
    closeBill: () => dispatch(patchBill(id, { state: 'closed' })),
    reopenBill: () => dispatch(patchBill(id, { state: 'open' })),
    changeName: (name: string) => dispatch(patchBill(id, { name }))
  }
}

export default connect(
  makeMapState,
  mapDispatch
)(BillDetail)
