// @flow
import * as React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ListStateIcon } from '../ListStateIcon'
import { customerName } from '../../utils'

import type {
  Boat,
  Customer,
  WorkOrderTask,
  WorkOrder as WorkOrderType,
  WorkOrderProgress
} from '../../types'

type Props = {
  workOrder: WorkOrderType,
  workOrderTasks: WorkOrderTask[],
  boat: Boat,
  customer: Customer,
  progress: WorkOrderProgress,
  active: boolean
}

const progressState = (progress: WorkOrderProgress) => {
  if (progress === 'toDo')
    return {
      color: 'primary',
      icon: 'build',
      tip: 'Openstaande taken'
    }
  if (progress === 'readyToInvoice')
    return {
      color: 'sunset',
      icon: 'euro_symbol',
      tip: 'Klaar om te factureren'
    }
  if (progress === 'invoiced')
    return {
      color: 'secondary',
      icon: 'check',
      tip: 'Gefactureerd'
    }
  if (progress === 'empty')
    return {
      color: 'dark',
      icon: 'more_horiz',
      tip: 'Nog geen taken op de werkopdracht'
    }
  return {
    color: 'coral',
    icon: 'warning',
    tip: 'Hier klopt iets niet :('
  }
}

const WorkOrderListItem = (props: Props) => {
  const {
    boat,
    customer,
    workOrderTasks,
    workOrder: { name, id },
    progress,
    active
  } = props
  const allTasks = workOrderTasks.length
  const openTasks = _.filter(workOrderTasks, { state: 'open' }).length
  return (
    <Link
      className={`list-item work-order-list-item short ${
        active ? ' active' : ''
      }`}
      to={`/work-orders/${id}`}
    >
      <div className="col">
        <h6 className="mb-1">{name}</h6>
        <div className="d-flex">
          <div className="d-flex align-items-center small">
            <i className="material-icons inline">person</i>
            {customerName(customer)}
            <i className="material-icons inline ml-2">directions_boat</i>
            <strong>{boat != null ? boat.name : '...'}</strong>
          </div>
          <div className="ml-auto">
            {allTasks > 0 ? (
              openTasks ? (
                <span>
                  {openTasks}{' '}
                  <span className="text-muted small">/ {allTasks}</span>
                </span>
              ) : (
                <i className="material-icons inline">check</i>
              )
            ) : (
              <i className="material-icons inline">more_horiz</i>
            )}
          </div>
        </div>
      </div>
      <ListStateIcon state={progress} {...progressState(progress)} small />
    </Link>
  )
}

export default WorkOrderListItem
