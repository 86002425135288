// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import type { FormikProps } from 'formik'
import logo from '../assets/Logo.svg'
import type { Dispatch } from '../types'
import { login } from '../actions'

type Props = { login: ({ email: string, password: string }) => boolean }
type Values = { email: string, password: string, submit: string }

const LoginPage = ({ login }: Props) => (
  <div className="login-page">
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: ''
      }}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .required('Vul dit nog even in a.u.b.')
          .email('Dit is geen geldig e-mailadres'),
        password: yup.string().required('Vul dit nog even in a.u.b.')
      })}
      onSubmit={async (values, actions) => {
        try {
          const success = await login(values)
          if (!success) throw new Error('Failed to login')
        } catch (e) {
          actions.setSubmitting(false)
          console.error(e.message)
          actions.setErrors({
            submit: 'Het is niet gelukt om in te loggen'
          })
        }
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }: FormikProps<Values>) => (
        <form className="login-form" onSubmit={handleSubmit}>
          <img src={logo} className="img-fluid" alt="BotBoys" />
          <input
            className="form-control form-control-lg"
            type="email"
            name="email"
            placeholder="e-mail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <div className="form-feedback">
            {touched.email && errors.email ? errors.email : <span>&nbsp;</span>}
          </div>
          <input
            type="password"
            name="password"
            className="form-control form-control-lg"
            placeholder="wachtwoord"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          <div className="form-feedback">
            {touched.password && errors.password ? (
              errors.password
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          <button
            className="btn btn-lg btn-block btn-primary"
            type="submit"
            disabled={isSubmitting || errors.password || errors.email}
          >
            Inloggen
          </button>
          <div className="form-feedback">
            {// $FlowFixMe
            errors.submit != null ? errors.submit : <span>&nbsp;</span>}
          </div>
        </form>
      )}
    />
  </div>
)

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    login: async ({ email, password }) => dispatch(login(email, password))
  }
}

export default connect(
  null,
  mapDispatchToProps
)(LoginPage)
