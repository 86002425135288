// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { CurrencyDisabledInput } from './BillLineItem'
import TT from '../Tooltip'
import UserSelect from '../Select/UserSelect'
import { DateString } from '../TextUtils'
import { grossTotal } from '../../utils'

import type {
  State as ReduxState,
  BillLineItem as BLIType,
  WorkOrderTask
} from '../../types'

type OwnProps = {
  state: string,
  child: BLIType,
  selected: boolean,
  onClick: () => void
}
type S = {
  task: ?WorkOrderTask
}
type Props = OwnProps & S

export const ChildRow = ({ state, child, selected, onClick, task }: Props) => (
  <tr
    className={_([
      'bill-line-item child',
      `state-${state}`,
      selected ? 'selected' : undefined
    ]).join(' ')}
    onClick={onClick}
  >
    <td className="bli-date text-muted">
      <DateString>{child.bookedAt}</DateString>
    </td>
    <td>
      {child.userId != null ? (
        <UserSelect disabled onSelect={() => undefined} value={child.userId} />
      ) : null}
    </td>
    <td>{child.productCode}</td>
    <td>{child.description}</td>
    <td className="bli-price">
      <CurrencyDisabledInput value={child.price * (1 + child.taxPercentage)} />
    </td>
    <td className="bli-priceUnit">
      {child.priceUnit !== 1 && child.priceUnit !== 100
        ? '/' + child.priceUnit
        : null}
    </td>
    <td className="bli-quantity">{Number(child.quantity)}</td>
    <td className="bli-grossTotal">
      <CurrencyDisabledInput value={grossTotal(child)} />
    </td>
    <td colSpan="3" />
    <td className="bli-origin-link">
      {task != null && (
        <Link to={`/work-orders/${task.workOrderId}`}>
          <TT
            tip={`${task.name} (Bekijk deze taak)`}
            render={id => (
              <div
                id={id}
                className="small"
                style={{
                  maxWidth: '2.5rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
              >
                {task != null && task.name}
              </div>
            )}
          />
        </Link>
      )}
    </td>
  </tr>
)

const mapState = (state: ReduxState, ownProps: OwnProps): S => {
  const { workOrderTaskId } = ownProps.child
  const task =
    workOrderTaskId != null
      ? state.resources.workOrderTasks[workOrderTaskId]
      : null
  return { task }
}

export default connect(mapState)(ChildRow)
