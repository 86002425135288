// @flow
import * as React from 'react'
import _ from 'lodash'
import {
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

type Props = {
  choices: Array<{ label: string, value: ?string }>,
  current: ?string,
  handleChoice: (?string) => void,
  title?: string
}
type State = {
  isOpen: boolean
}

export default class FilterDropdown extends React.Component<Props, State> {
  state = {
    isOpen: false
  }

  toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { current, choices, handleChoice, title } = this.props
    const currentChoice = choices.find(d => d.value === current)
    return (
      <InputGroupButtonDropdown
        addonType="prepend"
        isOpen={this.state.isOpen}
        toggle={this.toggleDropDown.bind(this)}
      >
        <DropdownToggle outline color="grey" className="rounded-0">
          {currentChoice != null ? currentChoice.label : 'Kies...'}
        </DropdownToggle>
        <DropdownMenu>
          {title != null ? <DropdownItem header>{title}</DropdownItem> : null}
          {_.map(choices, ({ label, value }) => (
            <DropdownItem
              key={value || 'all'}
              onClick={() => handleChoice(value)}
            >
              {label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </InputGroupButtonDropdown>
    )
  }
}
