// @flow
import * as React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'

type Props = {
  As?: any,
  children: any,
  className?: ?string,
  innerClassName?: ?string,
  containerClassName?: ?string,
  href?: ?string,
  to?: ?string,
  target?: ?string,
  rel?: ?string
}

export class Button extends React.Component<Props> {
  render() {
    const {
      As,
      children,
      className,
      innerClassName,
      containerClassName,
      href,
      to,
      ...props
    } = this.props
    let Component = 'button'
    let destination = {}
    if (href != null) {
      if (href.slice(0, 4) === 'http') {
        Component = 'a'
        destination.href = href
      } else {
        Component = Link
        destination.to = href
      }
    }
    if (to != null) {
      Component = Link
      destination.to = to
    }
    if (As != null) {
      Component = As
    }
    return (
      <div
        className={_([containerClassName, 'button-container', 'd-print-none'])
          .compact()
          .join(' ')}
      >
        <Component
          role="button"
          {...props}
          {...destination}
          rel={
            props.rel == null && props.target === '_blank'
              ? 'noopener'
              : props.rel
          }
          className={_([className, 'btn', 'rounded-0', 'border-0'])
            .compact()
            .join(' ')}
        >
          <div className={innerClassName || undefined}>{children}</div>
        </Component>
      </div>
    )
  }
}

export default Button
