// @flow
import _ from 'lodash'
import apiService from '../services/feathers'
import { grossTotal } from '../utils'

import type { BillLineItem, Dispatch, GetState, Invoice } from '../types'

export function createInvoice(ids: string[], orderStatus: string) {
  return async (dispatch: Dispatch, getState: GetState): Promise<Invoice> => {
    try {
      await apiService.ready
      const { billLineItems } = getState().resources
      const blis: BillLineItem[] = ids.map(id => billLineItems.byId[id])
      const customerIds = _.uniq(blis.map(d => d.customerId))
      const billIds = _.uniq(blis.map(d => d.billId))
      if (customerIds.length !== 1)
        throw new Error('BLIs must be for one customer')
      if (billIds.length !== 1) throw new Error('BLIs must be for one bill')
      const totalPrice = _.sumBy(_.values(blis), grossTotal)
      const invoice = await apiService.invoices.create(
        {
          customerId: customerIds[0],
          billId: billIds[0],
          totalPrice,
          billLineItems: ids
        },
        { query: { orderStatus } }
      )
      return invoice
    } catch (err) {
      console.error(err)
      dispatch({
        type: 'CREATE_ERROR',
        error: err
      })
      return err
    }
  }
}
