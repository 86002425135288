// @flow
import * as React from 'react'
import _ from 'lodash'
import VirtualizedSelect from 'react-virtualized-select'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import { PrefixIndexStrategy } from 'js-search'
import createFilterOptions from 'react-select-fast-filter-options'
import { customerName } from '../../utils'

import type {
  State as ReduxState,
  Boat,
  Customer,
  StoreSlice
} from '../../types'

type BoatOptionProps = {
  style: Object,
  option: Object,
  selectValue: Function,
  valueKey: String,
  focusedOption: Object,
  focusOption: Function
}

const BoatOption = ({
  style,
  option,
  selectValue,
  valueKey,
  focusedOption,
  focusOption
}: BoatOptionProps) => (
  <div
    style={{ ...style, display: 'flex' }}
    className={`Select-option ${focusedOption === option ? 'is-focused' : ''}`}
    key={option[valueKey]}
    onClick={() => selectValue(option)}
    onMouseOver={() => focusOption(option)}
  >
    <strong className="mr-1">{option.name}</strong>{' '}
    <span>{option.boatType}</span>
    <div className="ml-auto text-muted">
      {option.owner != null
        ? customerName(option.owner, { withCity: true })
        : ''}
    </div>
  </div>
)

type OwnProps = {
  onSelect: (?Boat) => void
}
type S = {
  boats: StoreSlice<Boat>,
  customers: StoreSlice<Customer>,
  filterOptions: any
}
type Props = OwnProps & S

type State = {
  selectedBoat: ?Boat
}

class BoatSelect extends React.Component<Props, State> {
  state = {
    selectedBoat: null
  }
  selectBoat(boat) {
    this.setState({ selectedBoat: boat })
    this.props.onSelect(boat)
  }

  render() {
    const { selectedBoat } = this.state
    const filterOptions = this.props.filterOptions
    return (
      <VirtualizedSelect
        name="boat-select"
        value={selectedBoat}
        valueKey="id"
        labelKey="name"
        placeholder={`Zoek boot (of type, klant, woonplaats)...`}
        noResultsText={false}
        onChange={this.selectBoat.bind(this)}
        options={[]}
        optionRenderer={BoatOption}
        filterOptions={filterOptions}
      />
    )
  }
}

const buildFilter = memoizeOne((boats, customers) => {
  const joined = _.map(boats, (boat: Boat) => ({
    ...boat,
    owner: _.get(customers, boat.ownerId)
  }))

  return createFilterOptions({
    options: joined,
    indexes: [
      'name',
      'boatType',
      ['owner', 'firstName'],
      ['owner', 'lastNamePrefix'],
      ['owner', 'lastName'],
      ['owner', 'city'],
      ['owner', 'customerReference']
    ],
    valueKey: 'id',
    labelKey: 'name',
    indexStrategy: new PrefixIndexStrategy()
  })
})

const mapStateToProps = (state: ReduxState): S => {
  return {
    boats: state.resources.boats,
    customers: state.resources.customers,
    filterOptions: buildFilter(state.resources.boats, state.resources.customers)
  }
}

export default connect(mapStateToProps)(BoatSelect)
