// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import Fuse from 'fuse.js'
import { InputGroup } from 'reactstrap'

import SearchBar from '../Search/SearchBar'
import FilterDropdown from '../FilterDropdown'
import BillSideListItem from './BillSideListItem'
import { orderByFromQuery } from '../../utils'

import type {
  Bill,
  BillLineItem,
  BillProgress,
  Boat,
  Customer,
  Dispatch,
  ListFilters,
  State as ReduxState,
  StoreSlice
} from '../../types'

type OwnProps = {|
  activeBillId: string
|}
type S = {
  +bills: Bill[],
  +filters: ListFilters
}
type D = {
  setFilters: ListFilters => void
}
type Props = OwnProps & S & D

const progress = memoizeOne(
  (blis: ?(BillLineItem[])): BillProgress => {
    if (blis == null || _.size(blis) === 0) return 'empty'
    if (blis != null && _.every(blis, { state: 'invoiced' })) return 'invoiced'
    return 'open'
  }
)

const billProgressTabs = [
  { label: 'Nog geen items', value: 'empty' },
  { label: 'Te factureren', value: 'open' },
  { label: 'Gefactureerd', value: 'invoiced' },
  { label: 'Alles', value: undefined }
]

class Bills extends React.Component<Props, {}> {
  handleFilterChoice(choice: ?BillProgress) {
    const current = this.props.filters.billProgress
    if (choice !== current) {
      const filters = { ...this.props.filters, billProgress: choice }
      return this.props.setFilters(filters)
    }
  }

  render() {
    const { bills, filters, activeBillId } = this.props
    const tabState = filters.billProgress
    return (
      <div className="container p-0">
        <div className="sticky-top bg-white border-bottom-grey">
          <InputGroup className="rounded-0">
            <FilterDropdown
              choices={billProgressTabs}
              handleChoice={this.handleFilterChoice.bind(this)}
              current={tabState}
              title="Filter op status"
            />
            <SearchBar filters={filters} setFilters={this.props.setFilters} />
          </InputGroup>
          <div className="list-header small">
            <div className="col">naam / eigenaar / boot</div>
            <div className="col-auto ml-auto">openstaand</div>
            <div className="col-auto pr-2">status</div>
          </div>
        </div>
        {_.map(bills, bill => (
          <BillSideListItem
            key={bill.id}
            billId={bill.id}
            bill={bill}
            active={bill.id === activeBillId}
          />
        ))}
      </div>
    )
  }
}

// Direct copy (atm) of BillList
const getFilterOptions = (filters: ListFilters) => {
  const filterableFields = ['customerId', 'boatId', 'state']
  return _.pick(filters, filterableFields)
}
const getSortOptions = orderByFromQuery({ defaultSort: 'openedOn' })
const getBills = memoizeOne((bills, filters, orderBy) =>
  _(bills)
    .filter(filters)
    .orderBy(...orderBy)
    .value()
)
const getFuseFilter = memoizeOne(
  (
    bills: StoreSlice<Bill>,
    boats: StoreSlice<Boat>,
    customers: StoreSlice<Customer>
  ) => {
    const list = _.map(bills, bill => ({
      ...bill,
      boat: boats[bill.boatId],
      customer: customers[bill.customerId]
    }))
    const options = {
      threshold: 0.25,
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      minMatchCharLength: 2,
      keys: [
        'name',
        'boat.name',
        'boat.boatType',
        'customer.firstName',
        'customer.lastNamePrefix',
        'customer.lastName',
        'customer.city',
        'customer.email',
        'customer.companyName'
      ]
    }
    return new Fuse<Bill>(list, options)
  }
)
const groupedBillLineItems = memoizeOne((workOrders, blis) =>
  _.mapValues(blis.idsByBill, ids => ids.map(id => blis.byId[id]))
)

const mapStateToProps = (state: ReduxState): S => {
  const filters = state.listFilters.bills
  const blisByBill = groupedBillLineItems(
    state.resources.bills,
    state.resources.billLineItems
  )
  const fuse = getFuseFilter(
    state.resources.bills,
    state.resources.boats,
    state.resources.customers
  )
  const { q, billProgress } = filters
  const queriedBills = q == null ? state.resources.bills : fuse.search(q)
  let bills = getBills(
    queriedBills,
    getFilterOptions(filters),
    getSortOptions(filters)
  )
  if (billProgress != null) {
    bills = _.pickBy(
      bills,
      ({ id }) => progress(blisByBill[id]) === billProgress
    )
  }
  return {
    bills,
    filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch): D => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'bills', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bills)
