// @flow
import _ from 'lodash'
import type {
  Action,
  BillLineItem,
  BillLineItemState as State,
  Index,
  StoreSlice
} from '../types'

export const defaultState = {
  byId: {},
  idsByBill: {},
  idsByTask: {},
  idsByParent: {},
  idsByWorkOrder: {}
}

// Return a new updated index if key is defined, otherwise return the original
// Update it by adding the new bliId to the previous values
const add = (index: Index<>, key: ?string, id: string): Index<> => {
  if (key == null) return index
  const added = [...(index[key] || []), id]
  return { ...index, [key]: added }
}

// Return a new updated index if key is defined, otherwise return the original
// Update it by removing the bliId from the previous array
const remove = (index: Index<>, key: ?string, id: string): Index<> => {
  if (key == null) return index
  const removed = index[key].filter(d => d !== id)
  return { ...index, [key]: removed }
}

// Update an index if the relevant key of the resource changes
// Remember: an Index is a map of ids that are keyed to some other id
// (For instance, billLineItem ids that all belong to a billId)
// 1. Check if there was a previous resource at all
// 2. If there was, check if the relevant value was updated:
//    - remove it from a previous index-key by checking the previous value
//    - add it to the a new index-key by checking the new value
// 3. If there was no previous resource, then this bli is new and we should add
//    its id to the relevant index
// 3. Return the index, which is the same object if nothing changed
const updateIndex = (
  index: Index<>,
  keyName: string,
  current: BillLineItem,
  previous: BillLineItem
): Index<> => {
  let newIndex = index
  if (previous != null) {
    if (previous[keyName] !== current[keyName]) {
      if (previous[keyName] != null) {
        newIndex = remove(newIndex, previous[keyName], current.id)
      }
      if (current[keyName] != null) {
        newIndex = add(newIndex, current[keyName], current.id)
      }
    }
  } else {
    newIndex = add(newIndex, current[keyName], current.id)
  }
  return newIndex
}

export default function(state: State = defaultState, action: Action) {
  if (action.resource === 'BILL_LINE_ITEM') {
    switch (action.type) {
      case 'PATCH_RESOURCES_SUCCESS':
      case 'FIND_RESOURCES_SUCCESS':
      case 'CREATE_RESOURCES_SUCCESS': {
        // 1. Parse BLIs and map by id
        let dataSlice: StoreSlice<BillLineItem>
        if (Array.isArray(action.data)) {
          const data: BillLineItem[] = action.data
          dataSlice = _(data)
            .filter(d => d != null)
            .map(parseBLI)
            .keyBy('id')
            .value()
        } else {
          const data: BillLineItem = action.data
          dataSlice = { [data.id]: parseBLI(data) }
        }
        const byId: StoreSlice<BillLineItem> = { ...state.byId, ...dataSlice }

        // 2. Update each index as required
        let idsByBill = state.idsByBill
        let idsByTask = state.idsByTask
        let idsByParent = state.idsByParent
        let idsByWorkOrder = state.idsByWorkOrder
        _.forEach(dataSlice, (bli, id) => {
          const old = state.byId[id]
          idsByBill = updateIndex(idsByBill, 'billId', bli, old)
          idsByTask = updateIndex(idsByTask, 'workOrderTaskId', bli, old)
          idsByParent = updateIndex(idsByParent, 'parentId', bli, old)
          idsByWorkOrder = updateIndex(idsByWorkOrder, 'workOrderId', bli, old)
        })

        const result: State = {
          byId,
          idsByBill,
          idsByTask,
          idsByParent,
          idsByWorkOrder
        }
        return result
      }
      case 'DELETE_RESOURCES_SUCCESS': {
        const ids = Array.isArray(action.ids) ? action.ids : [action.ids]
        let idsByBill = state.idsByBill
        let idsByTask = state.idsByTask
        let idsByParent = state.idsByParent
        let idsByWorkOrder = state.idsByWorkOrder

        // Update each index by removing the ids
        _.forEach(ids, id => {
          const bli = state.byId[id]
          if (bli == null) return
          idsByBill = remove(idsByBill, bli.billId, id)
          idsByTask = remove(idsByTask, bli.workOrderTaskId, id)
          idsByParent = remove(idsByParent, bli.parentId, id)
          idsByWorkOrder = remove(idsByWorkOrder, bli.workOrderId, id)
        })
        const result: State = {
          byId: _.omit(state.byId, ids),
          idsByBill,
          idsByTask,
          idsByParent,
          idsByWorkOrder
        }
        return result
      }
      case 'LOGOUT': {
        return defaultState
      }
      default:
        return state
    }
  } else {
    return state
  }
}

export function parseBLI(bli: BillLineItem): BillLineItem {
  return {
    ...bli,
    ..._.mapValues(
      _.pick(bli, [
        'standardPrice',
        'price',
        'costPrice',
        'taxPercentage',
        'discountPercentage',
        'quantity'
      ]),
      _.toNumber
    )
  }
}
