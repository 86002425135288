// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import BillLineItem, { LineItemsHeader } from '../BillLineItem/BillLineItem'
import { TotalRow } from '../BillLineItem/BillLineItemRows'
import NewBillLineItem from '../BillLineItem/NewBillLineItem'

import { makeGetBLIsForTaskId, isAdmin } from '../../utils'
import { addBillLineItemToTask } from '../../actions'

import type {
  Dispatch,
  State as ReduxState,
  BillLineItem as BLIType,
  WorkOrder,
  WorkOrderTask,
  WithRouterProps as R
} from '../../types'

// Types
type OwnProps = {
  taskId: string
}
type S = {
  workOrder: ?WorkOrder,
  task: ?WorkOrderTask,
  canAdd: boolean,
  billLineItems: BLIType[]
}
type D = {
  addBillLineItem: BLIType => void
}
type Props = OwnProps & S & D & R

const BillLineItems = ({
  billLineItems,
  task,
  addBillLineItem,
  canAdd
}: Props) => {
  if (task == null) return <div>Loading...</div>
  return (
    <div className="container">
      <div className="d-flex align-items-end">
        <table className="table">
          <LineItemsHeader taskLink={false} />
          <tbody>
            {_.map(billLineItems, bli => (
              <BillLineItem
                key={bli.id}
                billLineItem={bli}
                onClick={() => {}}
                selected={undefined}
              />
            ))}
            <TotalRow columns={10} billLineItems={billLineItems} />
            {canAdd ? (
              <NewBillLineItem
                loadProducts={false}
                disabled={!canAdd}
                onCreate={addBillLineItem}
                columns={10}
              />
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

// Redux connect

const makeMapState = () => {
  const getBLIs = makeGetBLIsForTaskId()
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const workOrderTaskId = ownProps.taskId
    const task = state.resources.workOrderTasks[workOrderTaskId]
    const workOrderId = task != null ? task.workOrderId : null
    const workOrder =
      workOrderId != null ? state.resources.workOrders[workOrderId] : null
    const billLineItems = getBLIs(state, { workOrderTaskId })
    const canAdd =
      task != null &&
      workOrder != null &&
      workOrder.state === 'open' &&
      isAdmin(state)
    return {
      workOrder,
      task,
      canAdd,
      billLineItems
    }
  }
  return mapState
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  return {
    addBillLineItem: bli =>
      dispatch(addBillLineItemToTask(bli, ownProps.taskId))
  }
}

export default connect(
  makeMapState,
  mapDispatch
)(BillLineItems)
