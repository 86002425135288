// @flow
import * as React from 'react'
import _ from 'lodash'

import { CurrencyDisabledInput } from './BillLineItem'
import { grossTotal } from '../../utils'

import type { BillLineItem as BLIType } from '../../types'
import type { BLIProps } from './BillLineItem'

export const ProductRow = ({
  billLineItem: bli,
  product,
  onClick,
  selected
}: BLIProps) => {
  if (product != null && product.name !== bli.description)
    return (
      <tr
        className={_([
          'bill-line-item product',
          `state-${bli.state}`,
          selected ? 'selected' : undefined,
          bli.parentId != null ? 'disabled' : undefined
        ])
          .compact()
          .join(' ')}
        onClick={onClick}
      >
        <td colSpan="3" />
        <td>
          <div className="article">Artikel: {product.name || 'onbekend'}</div>
        </td>
        <td colSpan="100" />
      </tr>
    )
  return null
}

type TotalRowProps = {
  billLineItems: BLIType[],
  columns: number
}

export const TotalRow = ({ billLineItems, columns }: TotalRowProps) => {
  if (billLineItems != null && !_.isEmpty(billLineItems)) {
    const total = _.sumBy(billLineItems, grossTotal)
    return (
      <tr className="bill-line-item">
        <td />
        <td />
        <td />
        <td className="font-weight-bold small">Totaal</td>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td className="bli-grossTotal font-weight-bold">
          <CurrencyDisabledInput value={total} />
        </td>
        <td colSpan={columns - 9} />
      </tr>
    )
  }
  return null
}
