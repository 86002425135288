// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Input from '../Input/TextInput'
import { toast } from 'react-toastify'
import { Collapse } from 'reactstrap'
import FilesDropzone from '../FilesDropzone'
import Files from '../Files'
import TaskSummary from './TaskSummary'
import TaskTitleBar from './TaskTitleBar'
import Dropzone from 'react-dropzone'

import TaskBillLineItems from '../WorkOrderTask/TaskBillLineItems'
import Print from '../Print'

import { makeGetBLIsForTaskId, isAdmin } from '../../utils'
import {
  patchWorkOrderTask,
  deleteWorkOrderTask,
  createFiles,
  deleteFiles
} from '../../actions'

import type {
  Attachment,
  BillLineItem,
  Dispatch,
  WorkOrderTask as Task,
  State as ReduxState,
  Relations
} from '../../types'
import type { DropzoneFile } from 'react-dropzone'

type OwnProps = {
  task: Task
}
type S = {
  admin: boolean,
  canBeDeleted: boolean,
  files: Attachment[],
  billLineItems: BillLineItem[]
}
type D = {
  changeStringValue: ($Shape<Task>) => Promise<void>,
  removeTask: () => void,
  addFiles: (files: DropzoneFile[], fileMetadata: Relations) => void,
  removeFile: string => void
}
type Props = OwnProps & S & D

type State = {
  isOpen: boolean
}

export const workOrderTaskCategories = {
  hull: 'Onderwaterschip / Poetsen',
  motor: 'Motorwerkzaamheden',
  paint: 'Schilderwerk',
  carpenter: 'Timmerwerk',
  electricity: 'Elektra',
  welding: 'Lassen',
  bbm: 'Buitenboordmotor',
  dekzeil: 'Dekzeil',
  other: 'Diversen'
}

export const shortWOTCategories = {
  hull: 'o.w.s.',
  motor: 'motor',
  paint: 'schilder',
  carpenter: 'timmer',
  electricity: 'elektra',
  welding: 'las',
  bbm: 'BBM',
  dekzeil: 'dekzeil',
  other: 'div'
}

class WorkOrderTaskComponent extends React.Component<Props, State> {
  state = {
    stateJustSet: false,
    isOpen: false
  }
  dropzone: ?Dropzone

  render() {
    const {
      task,
      canBeDeleted,
      files,
      changeStringValue,
      removeTask
    } = this.props
    const { isOpen } = this.state
    const metadata = {
      workOrderId: task.workOrderId,
      workOrderTaskId: task.id
    }
    const disabled = !this.props.admin

    return (
      <div className="work-order-task">
        <TaskTitleBar
          task={task}
          taskId={task.id}
          onDelete={() => {
            if (!canBeDeleted) {
              toast.error('Niet alle orderregels zijn afgesloten')
            } else {
              // TODO: warning before deletion
              removeTask()
            }
          }}
        />
        <FilesDropzone
          addFiles={files => this.props.addFiles(files, metadata)}
          setRef={ref => {
            this.dropzone = ref
          }}
          disabled={disabled}
        >
          <TaskSummary
            files={this.props.files}
            billLineItems={this.props.billLineItems}
            openFileUpload={() => this.dropzone != null && this.dropzone.open()}
          />

          <div className="list-group list-group-flush work-order-task-body">
            <Print
              component={Collapse}
              isOpen={isOpen || task.state === 'open'}
              print={task.state === 'open'}
            >
              <Print
                className="list-group-item p-0 border-0"
                print={!_.isEmpty(task.description)}
              >
                <div className="form-field-title px-3 d-print-none">
                  Instructies
                </div>
                <Input
                  className="m-0 py-2 px-3 with-title"
                  textarea
                  placeholder="Wat moet er gebeuren? (Klik om te wijzigen)"
                  disabled={disabled}
                  value={task.description}
                  onBlur={description => {
                    if (description === '') {
                      return changeStringValue({ description: null })
                    }
                    return changeStringValue({ description })
                  }}
                />
              </Print>
            </Print>
            <Print
              component={Collapse}
              isOpen={isOpen}
              print={task.state === 'open'}
            >
              {files.length > 0 ? (
                <div className="list-group-item border-bottom-0 px-3 py-2 m-0">
                  <div className="form-field-title px-3">Bestanden</div>
                  <Files
                    className="pt-3"
                    files={files}
                    removeFile={disabled ? null : this.props.removeFile}
                  />
                </div>
              ) : null}
              <Print
                className="list-group-item p-0 border-bottom-0"
                print={false}
              >
                <div className="form-field-title px-3">Notities</div>
                <Input
                  className="m-0 py-2 px-3 with-title"
                  textarea
                  value={task.notes}
                  disabled={disabled}
                  placeholder="Wat is er gedaan? (Klik om te wijzigen)"
                  onBlur={notes => changeStringValue({ notes })}
                />
              </Print>
              <div className="list-group-item p-0 border-0 d-print-none">
                <div className="row">
                  <TaskBillLineItems taskId={task.id} />
                </div>
              </div>
            </Print>
          </div>

          <div
            className="container text-center py-2 border-top clickable d-print-none"
            onClick={() => this.setState({ isOpen: !isOpen })}
          >
            {isOpen ? 'Toon minder' : 'Details'}
          </div>
        </FilesDropzone>
      </div>
    )
  }
}

const makeMapState = () => {
  const getBLIs = makeGetBLIsForTaskId()
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const { id: workOrderTaskId } = ownProps.task
    const match = _.matches({ workOrderTaskId })
    const billLineItems = getBLIs(state, { workOrderTaskId })
    const canBeDeleted = _.every(billLineItems, { state: 'invoiced' })
    const files = _.filter(state.resources.files, match)
    return {
      admin: isAdmin(state),
      canBeDeleted,
      files,
      billLineItems
    }
  }
  return mapState
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  return {
    addFiles: (files, metadata) => dispatch(createFiles(files, metadata)),
    removeFile: id => dispatch(deleteFiles([id])),
    changeStringValue: (update: $Shape<Task>) =>
      dispatch(patchWorkOrderTask({ id: ownProps.task.id, ...update })),
    removeTask: () => dispatch(deleteWorkOrderTask(ownProps.task))
  }
}

export const WorkOrderTask = connect(
  makeMapState,
  mapDispatch
)(WorkOrderTaskComponent)

export default WorkOrderTask
