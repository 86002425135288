// @flow
import * as React from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import FileIcon, { defaultStyles } from 'react-file-icon'
import 'react-pdf/dist/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`

type Props = {
  filename: string,
  height?: number,
  displayPages?: boolean
}
type State = {
  numPages: ?number,
  pageNumber: number
}

const Loading = ({ size }: { size: ?number }) => (
  <FileIcon
    extension={'pdf'}
    {...defaultStyles.pdf}
    className="file-thumbnail"
    size={size == null ? 100 : size}
  />
)

type PaginatorProps = {
  pageUp: () => void,
  pageDown: () => void,
  pageNumber: number,
  numPages: number
}
const Paginator = ({
  pageUp,
  pageDown,
  pageNumber,
  numPages
}: PaginatorProps) => (
  <nav aria-label="navigation pdf-pagination">
    <ul className="mt-3 pagination justify-content-center align-items-center">
      <li className="page-item">
        <div className="page-link" aria-label="Previous" onClick={pageDown}>
          <span aria-hidden="true">&laquo;</span>
        </div>
      </li>
      <li className="page-item px-3">
        pagina {pageNumber} van {numPages}
      </li>
      <li className="page-item">
        <div className="page-link" aria-label="Next" onClick={pageUp}>
          <span aria-hidden="true">&raquo;</span>
        </div>
      </li>
    </ul>
  </nav>
)

export default class PdfPreview extends React.Component<Props, State> {
  state = {
    numPages: null,
    pageNumber: 1
  }

  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    this.setState({ numPages })
  }

  setPageNumber(n: number) {
    const { numPages } = this.state
    if (numPages == null) return
    let pageNumber = n % numPages
    if (pageNumber === 0) pageNumber = numPages
    this.setState({ pageNumber })
  }

  render() {
    const { pageNumber, numPages } = this.state
    const { filename, height, displayPages, ...rest } = this.props

    return (
      <Document
        file={filename}
        onLoadSuccess={this.onDocumentLoadSuccess}
        loading={<Loading size={height} />}
        {...rest}
      >
        <Page
          pageNumber={pageNumber}
          loading={<Loading size={height} />}
          height={height}
          renderAnnotationLayer={false}
          renderMode="svg"
        />
        {displayPages === true && numPages != null ? (
          <Paginator
            pageNumber={pageNumber}
            numPages={numPages}
            pageUp={() => this.setPageNumber(pageNumber + 1)}
            pageDown={() => this.setPageNumber(pageNumber - 1)}
          />
        ) : null}
      </Document>
    )
  }
}
