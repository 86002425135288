// @flow
import * as React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import { Newlines, DateString } from './TextUtils'
import {
  customerName,
  boatDimensions,
  boatSurfaceArea,
  boatDisplacement,
  boatInsurance,
  boatCategory,
  boatMaterial
} from '../utils'

import type { Boat, Customer } from '../types'
type GenericProps = {
  className?: ?string,
  title?: ?string | React.Element<any>,
  icon?: ?string,
  link?: string,
  info?: ?Array<Object>
}
type BoatProps = {
  boat: Boat
}
type CustomerProps = {
  customer: Customer
}

export const InfoBlock = ({
  className: outerClassName,
  title,
  icon,
  info,
  link
}: GenericProps) => {
  let Title = 'div'
  let titleProps = {}
  if (link != null) {
    Title = Link
    titleProps = { to: link }
  }
  return (
    <div
      className={_([outerClassName, 'info-block'])
        .compact()
        .join(' ')}
    >
      <Title
        {...titleProps}
        className={`info-block-title ${link != null ? 'link' : ''}`}
      >
        <div className="icon-container">
          <div className="icon">
            <i className="material-icons">{icon}</i>
          </div>
        </div>
        <div className="title">
          <h4>{title}</h4>
        </div>
      </Title>
      <div className="info-block-body">
        <dl className="row">
          {_(info)
            .omitBy(({ value }) => !value || _.isNil(value))
            .map(({ value, key, className = '' }) => (
              <React.Fragment
                key={`${String(outerClassName)}-${String(title)}-${key}`}
              >
                <dt className={['info-block-key', className].join(' ')}>
                  {key}
                </dt>
                <dd className={['info-block-value', className].join(' ')}>
                  {value}
                </dd>
              </React.Fragment>
            ))
            .value()}
        </dl>
      </div>
    </div>
  )
}

export const BoatInfo = ({ boat }: BoatProps) => {
  const info = [
    {
      key: 'Type',
      value: [
        boat.boatType,
        boat.boatCategory != null ? `(${boatCategory(boat)})` : undefined
      ].join(' ')
    },
    {
      key: 'Afmetingen',
      value: `${boatDimensions(boat)} (${boatSurfaceArea(boat)})`
    },
    {
      key: 'Gewicht',
      value: boatDisplacement(boat)
    },
    {
      key: 'Bouwjaar',
      value: boat.yearOfManufacture
    },
    {
      key: 'Opmerkingen',
      value: boat.notes && <Newlines>{boat.notes}</Newlines>,
      className: 'd-print-none'
    },
    {
      key: 'Materiaal',
      value: boatMaterial(boat)
    },
    {
      key: 'Verzekering',
      value: boatInsurance(boat),
      className: 'd-print-none'
    },
    {
      key: 'Hijsinstructies',
      value: boat.slipInstructions && (
        <Newlines>{boat.slipInstructions}</Newlines>
      )
    },
    {
      key: 'Verf-geschiedenis',
      value: boat.paintHistory && <Newlines>{boat.paintHistory}</Newlines>,
      className: 'd-print-none'
    },
    {
      key: 'Onderhouds-geschiedenis',
      value: boat.maintenanceHistory && (
        <Newlines>{boat.maintenanceHistory}</Newlines>
      ),
      className: 'd-print-none'
    }
  ]
  return (
    <InfoBlock
      className="boat-info-block"
      icon="directions_boat"
      link={
        process.env.REACT_APP_FEATURE_BOATS === 'active'
          ? `/boats/${boat.id}`
          : undefined
      }
      title={
        <span>
          {boat.name}
          <span className="text-muted small ml-2">{boat.boatType}</span>
        </span>
      }
      info={info}
    />
  )
}

export const CustomerInfo = ({ customer }: CustomerProps) => {
  const address = (
    <Newlines>
      {_.compact([customer.address, customer.zipCode, customer.city]).join(
        '\n'
      )}
    </Newlines>
  )
  const info = [
    { key: 'Bedrijf', value: customer.isCompany ? customer.companyName : null },
    {
      key: 'Adres',
      value: address,
      className: 'd-print-none'
    },
    {
      key: 'Woonplaats',
      value: customer.city,
      className: 'd-none d-print-block'
    },
    {
      key: 'Telefoon',
      value: _.compact([customer.telephone, customer.mobile]).join(' / '),
      className: 'd-print-none'
    },
    {
      key: 'E-mail',
      value: <a href={`mailto:${customer.email || '#'}`}>{customer.email}</a>,
      className: 'd-print-none'
    },
    {
      key: 'Klant sinds',
      value: customer.createdAt != null && (
        <DateString>{customer.createdAt}</DateString>
      )
    },
    {
      key: 'Notities',
      value: customer.notes && <Newlines>{customer.notes}</Newlines>,
      className: 'd-print-none'
    }
  ]
  const title =
    customer.customerReference != null ? (
      <span>
        {customerName(customer)}
        <span className="text-muted small ml-2">
          relatienr. {customer.customerReference}
        </span>
      </span>
    ) : (
      customerName(customer)
    )
  return (
    <InfoBlock
      className="customer-info-block"
      icon="person"
      title={title}
      info={info}
    />
  )
}

export const BoatAndCustomerInfo = ({
  boat,
  customer
}: BoatProps & CustomerProps) => (
  <div className="row mb-4">
    <div className="col d-flex">
      <BoatInfo boat={boat} />
    </div>
    <div className="col-5 d-flex">
      <CustomerInfo customer={customer} />
    </div>
  </div>
)
