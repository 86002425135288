// @flow
import _ from 'lodash'
import { cleanFilters } from '../utils'

import type { Action, ListName, ListFilters } from '../types'

type State = {
  [list: ListName]: ListFilters
}

const defaultBillFilters = {
  billProgress: 'open',
  orderBy: 'openedAt',
  order: 'desc'
}
const defaultWorkOrderFilters = {
  workOrderProgress: 'toDo',
  orderBy: 'plannedCompletionAt',
  order: 'asc'
}
const defaultBoatFilters = {}

const defaultState = {
  bills: defaultBillFilters,
  workOrders: defaultWorkOrderFilters,
  boats: defaultBoatFilters
}

export default function listFilters(
  state: State = defaultState,
  action: Action
) {
  switch (action.type) {
    case 'SET_FILTERS': {
      if (_.isEqual(state[action.list], action.filters)) return state
      return {
        ...state,
        [action.list]: cleanFilters(action.filters)
      }
    }
    case 'RESET_FILTERS': {
      if (action.list === 'bills') {
        if (!_.isEqual(state.bills, defaultBillFilters))
          return {
            ...state,
            bills: defaultBillFilters
          }
      }
      if (action.list === 'workOrders') {
        if (!_.isEqual(state.workOrders, defaultWorkOrderFilters))
          return {
            ...state,
            workOrders: defaultWorkOrderFilters
          }
      }
      if (action.list === 'boats') {
        if (!_.isEqual(state.boats, defaultBoatFilters))
          return {
            ...state,
            boats: defaultBoatFilters
          }
      }
      return state
    }
    default:
      return state
  }
}
