// @flow
import _ from 'lodash'
import apiService from '../services/feathers'
import type { Dispatch, GetState, QueryObject, User } from '../types'

export function findUsers(
  filters: ?QueryObject
): (Dispatch, GetState) => Promise<User[]> {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      await apiService.ready
      let users = getState().resources.users
      if (_.size(users) > 0) return _.map(users)
      const data = await apiService.users.find(filters)
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'USER',
        data
      })
      return data
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_ERROR' })
      return err
    }
  }
}
