// @flow
import * as React from 'react'
import VirtualizedSelect from 'react-virtualized-select'

import apiService from '../../services/api'

import type { Product } from '../../types'
import { Currency } from '../TextUtils'

type ProductOptionProps = {
  style: Object,
  option: Object,
  selectValue: Function,
  valueKey: String,
  focusedOption: Object,
  focusOption: Function
}

const ProductOption = ({
  style,
  option,
  selectValue,
  valueKey,
  focusedOption,
  focusOption
}: ProductOptionProps) => (
  <div
    style={{ ...style, display: 'flex' }}
    className={`Select-option ${focusedOption === option ? 'is-focused' : ''}`}
    key={option[valueKey]}
    onClick={() => selectValue(option)}
    onMouseOver={() => focusOption(option)}
  >
    {option.name}
    <code className="ml-auto small">{option.productCode}</code>
    <div className="ml-2 text-right" style={{ width: '5em' }}>
      <Currency>{option.price * (1 + Number(option.vatPercentage))}</Currency>
    </div>
  </div>
)

type Props = {
  product: ?Product,
  disabled?: boolean,
  onChange: (?Product) => void,
  onInputKeyDown: Function, // bubble up input keypresses
  placeholder?: ?string
}

class ProductSelect extends React.Component<Props> {
  async getOptions(search: string) {
    if (search.length < 3) {
      return { options: [] }
    }
    const results = await apiService.service('products').find({ search })
    return { options: results.data }
  }

  render() {
    return (
      <VirtualizedSelect
        async
        loadOptions={this.getOptions.bind(this)}
        disabled={this.props.disabled}
        name="product-select"
        value={this.props.product}
        valueKey="id"
        labelKey="name"
        onChange={this.props.onChange}
        filterOptions={options => {
          // Do no filtering, just return all options
          return options
        }}
        placeholder={this.props.placeholder || 'Zoek product...'}
        optionRenderer={ProductOption}
        onInputKeyDown={this.props.onInputKeyDown}
      />
    )
  }
}

export default ProductSelect
