// @flow
import apiService from '../services/feathers'
import _ from 'lodash'
import type {
  Dispatch,
  QueryObject,
  Customer,
  Boat,
  BoatLegacyMaintenance,
  Bill,
  WorkOrder,
  BillLineItem
} from '../types'

export function findBoats(filters: ?QueryObject): Dispatch => Promise<Boat[]> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const response = await apiService.boats.find(filters)
      const boats: Array<Boat> = _.map(response, b => _.omit(b, 'owner'))
      const customers: Array<Customer> = _.map(response, 'owner')
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'BOAT',
        data: boats
      })
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'CUSTOMER',
        data: customers
      })
      return response
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_ERROR' })
      return err
    }
  }
}

export function getBoat(boatId: number): Dispatch => Promise<Boat> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const boat: Boat = await apiService.boats.get(boatId)
      const customerId = boat.ownerId

      const customer =
        customerId != null ? await apiService.customers.get(customerId) : null
      const billLineItems: BillLineItem[] = await apiService.billLineItems.find(
        { query: { boatId } }
      )
      const bills: Bill[] = await apiService.bills.find({
        query: { boatId }
      })
      const workOrders: WorkOrder[] = await apiService.workOrders.find({
        query: { boatId }
      })
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'BOAT',
        data: (boat: Boat)
      })
      if (bills != null) {
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'BILL',
          data: (bills: Bill[])
        })
      }
      if (billLineItems != null) {
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'BILL_LINE_ITEM',
          data: billLineItems
        })
      }
      if (customer != null)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'CUSTOMER',
          data: (customer: Customer)
        })
      if (workOrders != null)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'WORK_ORDER',
          data: (workOrders: WorkOrder[])
        })
      return boat
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_ERROR' })
      return err
    }
  }
}

export function patchBoat(
  id: number,
  update: $Shape<Boat>
): Dispatch => Promise<void> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      await apiService.boats.patch(id, update)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'UPDATE_ERROR' })
      return err
    }
  }
}

export function getBoatMaintenance(
  boatId: number
): Dispatch => Promise<BoatLegacyMaintenance> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const boatLegacyMaintenance: BoatLegacyMaintenance = await apiService.boatLegacyMaintenance.get(
        boatId
      )
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'LEGACY_MAINTENANCE',
        data: (boatLegacyMaintenance: BoatLegacyMaintenance)
      })
      return boatLegacyMaintenance
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_ERROR' })
      return err
    }
  }
}

export function patchBoatMaintenance(
  id: number,
  update: $Shape<BoatLegacyMaintenance>
): Dispatch => Promise<void> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      await apiService.boatLegacyMaintenance.patch(id, update)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'UPDATE_ERROR' })
      return err
    }
  }
}
