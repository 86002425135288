// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import Fuse from 'fuse.js'
import { InputGroup } from 'reactstrap'

import SearchBar from '../Search/SearchBar'
import FilterDropdown from '../FilterDropdown'
import WorkOrderSideListItem from './WorkOrderSideListItem'
import { orderByFromQuery } from '../../utils'

import type {
  BillLineItem,
  Boat,
  Customer,
  Dispatch,
  ListFilters,
  State as ReduxState,
  StoreSlice,
  WorkOrder,
  WorkOrderProgress,
  WorkOrderTask
} from '../../types'

type OwnProps = {|
  activeWorkOrderId: string
|}
type S = {
  filters: ListFilters,
  workOrders: StoreSlice<WorkOrder>,
  tasksByWorkOrder: StoreSlice<WorkOrderTask[]>,
  blisByWorkOrder: StoreSlice<BillLineItem[]>,
  boats: StoreSlice<Boat>,
  customers: StoreSlice<Customer>
}
type D = {
  setFilters: ListFilters => void
}
type Props = OwnProps & S & D

const progress = memoizeOne(
  (tasks: ?(WorkOrderTask[]), blis: ?(BillLineItem[])): WorkOrderProgress => {
    if (tasks == null || _.size(tasks) === null) return 'empty'
    if (_.some(tasks, { state: 'open' })) return 'toDo'
    if (blis != null && _.every(blis, { state: 'invoiced' })) return 'invoiced'
    return 'readyToInvoice'
  }
)

const workOrderProgressFilters = [
  { label: 'Nog geen taken', value: 'empty' },
  { label: 'Openstaande taken', value: 'toDo' },
  { label: 'Te factureren', value: 'readyToInvoice' },
  { label: 'Gefactureerd', value: 'invoiced' },
  { label: 'Alles', value: undefined }
]

const workOrderSort = [
  { label: 'Geopened', value: 'openedAt' },
  { label: 'Gepland', value: 'plannedCompletionAt' }
]

class WorkOrders extends React.Component<Props, {}> {
  handleFilterChoice(choice: ?WorkOrderProgress) {
    const current = this.props.filters.workOrderProgress
    if (choice !== current) {
      const filters = { ...this.props.filters, workOrderProgress: choice }
      return this.props.setFilters(filters)
    }
  }

  handleSortChoice(choice: $Keys<WorkOrder>) {
    const current = _.get(this.props.filters, 'orderBy', 'plannedCompletionAt')
    const order = _.get(this.props.filters, 'order', 'asc')
    if (current !== choice) {
      const filters = { ...this.props.filters, orderBy: choice, order: 'asc' }
      return this.props.setFilters(filters)
    } else {
      const filters = {
        ...this.props.filters,
        order: order === 'asc' ? 'desc' : 'asc'
      }
      return this.props.setFilters(filters)
    }
  }

  render() {
    const {
      workOrders,
      tasksByWorkOrder,
      blisByWorkOrder,
      boats,
      customers,
      filters,
      activeWorkOrderId
    } = this.props
    return (
      <div className="container p-0">
        <div className="sticky-top bg-white border-bottom-grey">
          <InputGroup className="rounded-0">
            <FilterDropdown
              choices={workOrderProgressFilters}
              current={filters.workOrderProgress}
              handleChoice={this.handleFilterChoice.bind(this)}
              title="Filter op status"
            />
            <FilterDropdown
              choices={workOrderSort}
              current={filters.orderBy}
              handleChoice={this.handleSortChoice.bind(this)}
              title="Sorteer op ..."
            />
            <SearchBar filters={filters} setFilters={this.props.setFilters} />
          </InputGroup>
          <div className="list-header small">
            <div className="col">naam / eigenaar / boot</div>
            <div className="col-auto ml-auto">taken</div>
            <div className="col-auto pr-2">status</div>
          </div>
        </div>
        {_.map(workOrders, workOrder => (
          <WorkOrderSideListItem
            key={workOrder.id}
            workOrder={workOrder}
            workOrderTasks={tasksByWorkOrder[workOrder.id] || []}
            billLineItems={blisByWorkOrder[workOrder.id] || []}
            boat={boats[workOrder.boatId]}
            customer={customers[workOrder.customerId]}
            progress={progress(
              tasksByWorkOrder[workOrder.id],
              blisByWorkOrder[workOrder.id]
            )}
            active={workOrder.id === activeWorkOrderId}
          />
        ))}
      </div>
    )
  }
}

// Direct copy (atm) of WorkOrderList
const getFilterOptions = (filters: ListFilters) => {
  const filterableFields = ['customerId', 'boatId', 'state']
  return _.pick(filters, filterableFields)
}
const getSortOptions = orderByFromQuery({ defaultSort: 'plannedCompletionAt' })
const getWorkOrders = memoizeOne((workOrders, filters, orderBy) =>
  _(workOrders)
    .filter(filters)
    .orderBy(...orderBy)
    .value()
)
const groupedWorkOrderTasks = memoizeOne((workOrders, tasks) =>
  _.groupBy(tasks, 'workOrderId')
)
const groupedBillLineItems = memoizeOne((workOrders, blis) =>
  _.mapValues(blis.idsByWorkOrder, ids => ids.map(id => blis.byId[id]))
)
const getFuseFilter = memoizeOne(
  (
    workOrders: StoreSlice<WorkOrder>,
    boats: StoreSlice<Boat>,
    customers: StoreSlice<Customer>
  ) => {
    const list = _.map(workOrders, workOrder => ({
      ...workOrder,
      boat: boats[workOrder.boatId],
      customer: customers[workOrder.customerId]
    }))
    const options = {
      threshold: 0.25,
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      minMatchCharLength: 2,
      keys: [
        'name',
        'boat.name',
        'boat.boatType',
        'customer.firstName',
        'customer.lastNamePrefix',
        'customer.lastName',
        'customer.city',
        'customer.email',
        'customer.companyName'
      ]
    }
    return new Fuse<WorkOrder>(list, options)
  }
)

const mapStateToProps = (state: ReduxState): S => {
  const filters = state.listFilters.workOrders
  const tasksByWorkOrder = groupedWorkOrderTasks(
    state.resources.workOrders,
    state.resources.workOrderTasks
  )
  const blisByWorkOrder = groupedBillLineItems(
    state.resources.workOrders,
    state.resources.billLineItems
  )
  const fuse = getFuseFilter(
    state.resources.workOrders,
    state.resources.boats,
    state.resources.customers
  )
  const { q, workOrderProgress } = filters
  const queriedWorkOrders =
    q == null ? state.resources.workOrders : fuse.search(q)
  let workOrders: StoreSlice<WorkOrder> = getWorkOrders(
    queriedWorkOrders,
    getFilterOptions(filters),
    getSortOptions(filters)
  )
  if (workOrderProgress != null) {
    workOrders = _.pickBy(
      workOrders,
      ({ id }) =>
        progress(tasksByWorkOrder[id], blisByWorkOrder[id]) ===
        workOrderProgress
    )
  }

  return {
    workOrders,
    boats: state.resources.boats,
    customers: state.resources.customers,
    tasksByWorkOrder,
    blisByWorkOrder,
    filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch): D => {
  return {
    setFilters: filters =>
      dispatch({ type: 'SET_FILTERS', list: 'workOrders', filters })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrders)
