// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import WorkOrderStatus from './WorkOrderStatus'
import WorkOrderCategory from './WorkOrderCategory'
import { BoatAndCustomerInfo } from '../InfoBlocks'
import Input from '../Input/TextInput'
import DateInput from '../Input/DateInput'
import Button from '../Button'
import Print from '../Print'
import { workOrderTaskCategories } from '../WorkOrderTask/WorkOrderTask'

import { isAdmin } from '../../utils'
import { getWorkOrder, patchWorkOrder, addWorkOrderTask } from '../../actions'

import type {
  WorkOrder,
  Boat,
  Customer,
  Dispatch,
  State as ReduxState,
  TaskCategoryMap
} from '../../types'

type OwnProps = {
  workOrderId: string
}
type S = {
  admin: boolean,
  boat: ?Boat,
  customer: ?Customer,
  workOrder: ?WorkOrder,
  workOrderTasks: TaskCategoryMap
}
type D = {
  loadWorkOrder: () => Promise<void>,
  addTask: (category: string) => Promise<void>,
  changeName: (name: string) => Promise<void>,
  changeNotes: (notes: string | null) => Promise<void>,
  changeCompletion: (date: string | null) => Promise<void>,
  changeOpenDate: (date: ?string) => Promise<void>
}

type Props = OwnProps & S & D

class WorkOrderDetail extends React.Component<Props> {
  componentDidMount() {
    this.props.loadWorkOrder()
  }

  render() {
    const { boat, customer, workOrder, workOrderTasks } = this.props
    const disabled = !this.props.admin
    // TODO return a loading indicator
    if (
      workOrder == null ||
      _.isEmpty(workOrder) ||
      workOrderTasks == null ||
      boat == null ||
      customer == null
    )
      return (
        <div className="container">
          <h2>Loading...</h2>
        </div>
      )
    return (
      <div className="container">
        <Helmet title={`${workOrder.name} (Werkopdracht)`} />
        <h5 className="text-muted mb-1 d-print-none">WERKOPDRACHT</h5>
        <div className="row align-items-center">
          <div className="col">
            <Input
              className="h1"
              disabled={disabled}
              onBlur={this.props.changeName}
              value={workOrder.name}
            />
          </div>
          <div className="col-auto">
            <WorkOrderStatus workOrder={workOrder} disabled={disabled} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="row">
              <div className="col-auto">
                <div className="position-static form-field-title">
                  Opdrachtdatum
                </div>
                <DateInput
                  value={workOrder.openedAt}
                  onBlur={this.props.changeOpenDate}
                  disabled={disabled}
                />
              </div>
              <Print
                className="col-auto"
                print={!_.isEmpty(workOrder.plannedCompletionAt)}
              >
                <div className="position-static form-field-title">
                  Opleverdatum
                </div>
                <DateInput
                  value={workOrder.plannedCompletionAt}
                  onBlur={this.props.changeCompletion}
                  disabled={disabled}
                />
              </Print>
              <Print className="col-auto ml-auto d-none">
                <div className="position-static form-field-title">
                  Geprint op
                </div>
                <DateInput
                  value={moment()
                    .toDate()
                    .toISOString()}
                  onBlur={() => Promise.resolve()}
                />
              </Print>
            </div>
            <Print
              className="position-relative"
              print={!_.isEmpty(workOrder.notes)}
            >
              <div className="form-field-title">Notities</div>
              <Input
                className="workOrder-notes with-title"
                textarea
                placeholder="Klik om notities toe te voegen"
                value={workOrder.notes}
                disabled={disabled}
                onBlur={notes => {
                  if (notes === '') {
                    return this.props.changeNotes(null)
                  }
                  return this.props.changeNotes(notes)
                }}
              />
            </Print>
          </div>
          <Print
            print={false}
            className="col-auto d-flex flex-column align-items-end"
          >
            {workOrder.billId != null && (
              <Button
                to={`/bills/${workOrder.billId}`}
                className="btn btn-primary"
              >
                Bekijk rekening
              </Button>
            )}
          </Print>
        </div>
        <BoatAndCustomerInfo boat={boat} customer={customer} />
        {_.map(workOrderTaskCategories, (value, cat) => (
          <WorkOrderCategory
            workOrder={workOrder}
            workOrderTasks={this.props.workOrderTasks[cat]}
            key={cat}
            category={cat}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): S => {
  const { workOrderId } = ownProps
  const workOrder: ?WorkOrder = state.resources.workOrders[workOrderId]
  const boat =
    workOrder != null ? state.resources.boats[workOrder.boatId] : undefined
  const customer =
    workOrder != null
      ? state.resources.customers[workOrder.customerId]
      : undefined
  const workOrderTasks =
    workOrder != null
      ? _(state.resources.workOrderTasks)
          .values()
          .filter({ workOrderId: workOrder.id })
          .sortBy('createdAt')
          .groupBy('category')
          .value()
      : {}
  return {
    admin: isAdmin(state),
    workOrder,
    workOrderTasks,
    customer,
    boat
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): D => {
  const { workOrderId: id } = ownProps
  return {
    loadWorkOrder: () => dispatch(getWorkOrder(id)),
    addTask: (category: string) => dispatch(addWorkOrderTask(category, id)),
    changeNotes: (notes: string | null) =>
      dispatch(patchWorkOrder(id, { notes })),
    changeCompletion: (plannedCompletionAt: string | null) =>
      dispatch(patchWorkOrder(id, { plannedCompletionAt })),
    changeOpenDate: (openedAt: ?string) => {
      if (openedAt == null) return Promise.resolve()
      return dispatch(patchWorkOrder(id, { openedAt }))
    },
    changeName: (name: string) => dispatch(patchWorkOrder(id, { name }))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderDetail)
