// @flow
import _ from 'lodash'
import apiService from '../services/feathers'
import type { Dispatch, GetState, QueryObject, Stand } from '../types'

export function findStands(
  filters: ?QueryObject
): (Dispatch, GetState) => Promise<Stand[]> {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      await apiService.ready
      let stands = getState().resources.stands
      if (_.size(stands) > 0) return _.map(stands)
      const data = await apiService.stands.find(filters)
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'STAND',
        data
      })
      return data
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_ERROR' })
      return err
    }
  }
}
