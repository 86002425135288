// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import TextInput from '../Input/TextInput'
import { getBoatMaintenance, patchBoatMaintenance } from '../../actions'

// Types
import type {
  BoatLegacyMaintenance as Maintenance,
  Dispatch,
  State as ReduxState
} from '../../types'

type OwnProps = {
  boatId: number,
  admin: boolean
}
type S = {
  maintenance: Maintenance
}
type D = {
  loadMaintenance: () => Promise<Maintenance>,
  patchMaintenance: ($Shape<Maintenance>) => Promise<void>
}
type Props = OwnProps & S & D

const EditField = ({
  label,
  children,
  className
}: {
  label: string,
  children: React.Node,
  className?: string
}) => (
  <React.Fragment>
    <dt className={['info-block-key text-right editable', className].join(' ')}>
      {label}
    </dt>
    <dd className={['info-block-value mb-0', className].join(' ')}>
      {children}
    </dd>
  </React.Fragment>
)

class PaintHistory extends React.Component<Props> {
  componentDidMount() {
    this.props.loadMaintenance()
  }
  render() {
    const { maintenance, patchMaintenance, admin } = this.props
    const disabled = !admin
    return (
      <div className={`paint-info-block info-block mb-5`}>
        <div className="info-block-title">
          <div className="icon-container">
            <div className="icon">
              <i className="material-icons">brush</i>
            </div>
          </div>
          <div className="title">
            <h4>Verfgeschiedenis</h4>
          </div>
        </div>
        <div className="info-block-body">
          <h3>Opbouwkajuit</h3>
          <dl className="row">
            <EditField label={'grond'}>
              <TextInput
                textarea
                onBlur={verf_opbouwkajuit_grond =>
                  patchMaintenance({ verf_opbouwkajuit_grond })
                }
                disabled={disabled}
                value={maintenance.verf_opbouwkajuit_grond}
              />
            </EditField>
            <EditField label={'aflak'}>
              <TextInput
                textarea
                onBlur={verf_opbouwkajuit_aflak =>
                  patchMaintenance({ verf_opbouwkajuit_aflak })
                }
                disabled={disabled}
                value={maintenance.verf_opbouwkajuit_aflak}
              />
            </EditField>
            <EditField label={'kleur'}>
              <TextInput
                textarea
                onBlur={verf_opbouwkajuit_kleur =>
                  patchMaintenance({ verf_opbouwkajuit_kleur })
                }
                disabled={disabled}
                value={maintenance.verf_opbouwkajuit_kleur}
              />
            </EditField>
          </dl>
          <h3 className="mt-3">Gangboorden</h3>
          <dl className="row">
            <EditField label={'grond'}>
              <TextInput
                textarea
                onBlur={verf_gangboorden_grond =>
                  patchMaintenance({ verf_gangboorden_grond })
                }
                disabled={disabled}
                value={maintenance.verf_gangboorden_grond}
              />
            </EditField>
            <EditField label={'aflak'}>
              <TextInput
                textarea
                onBlur={verf_gangboorden_aflak =>
                  patchMaintenance({ verf_gangboorden_aflak })
                }
                disabled={disabled}
                value={maintenance.verf_gangboorden_aflak}
              />
            </EditField>
            <EditField label={'kleur'}>
              <TextInput
                textarea
                onBlur={verf_gangboorden_kleur =>
                  patchMaintenance({ verf_gangboorden_kleur })
                }
                disabled={disabled}
                value={maintenance.verf_gangboorden_kleur}
              />
            </EditField>
          </dl>
          <h3 className="mt-3">Boeischot</h3>
          <dl className="row">
            <EditField label={'grond'}>
              <TextInput
                textarea
                onBlur={verf_boeischot_grond =>
                  patchMaintenance({ verf_boeischot_grond })
                }
                disabled={disabled}
                value={maintenance.verf_boeischot_grond}
              />
            </EditField>
            <EditField label={'aflak'}>
              <TextInput
                textarea
                onBlur={verf_boeischot_aflak =>
                  patchMaintenance({ verf_boeischot_aflak })
                }
                disabled={disabled}
                value={maintenance.verf_boeischot_aflak}
              />
            </EditField>
            <EditField label={'kleur'}>
              <TextInput
                textarea
                onBlur={verf_boeischot_kleur =>
                  patchMaintenance({ verf_boeischot_kleur })
                }
                disabled={disabled}
                value={maintenance.verf_boeischot_kleur}
              />
            </EditField>
          </dl>

          <h3 className="mt-3">Romp</h3>

          <dl className="row">
            <EditField label={'grond'}>
              <TextInput
                textarea
                onBlur={verf_romp_grond =>
                  patchMaintenance({ verf_romp_grond })
                }
                disabled={disabled}
                value={maintenance.verf_romp_grond}
              />
            </EditField>
            <EditField label={'aflak'}>
              <TextInput
                textarea
                onBlur={verf_romp_aflak =>
                  patchMaintenance({ verf_romp_aflak })
                }
                disabled={disabled}
                value={maintenance.verf_romp_aflak}
              />
            </EditField>
            <EditField label={'kleur'}>
              <TextInput
                textarea
                onBlur={verf_romp_kleur =>
                  patchMaintenance({ verf_romp_kleur })
                }
                disabled={disabled}
                value={maintenance.verf_romp_kleur}
              />
            </EditField>
          </dl>

          <h3 className="mt-3">Waterlijn</h3>

          <dl className="row">
            <EditField label={'grond'}>
              <TextInput
                textarea
                onBlur={verf_waterlijn_grond =>
                  patchMaintenance({ verf_waterlijn_grond })
                }
                disabled={disabled}
                value={maintenance.verf_waterlijn_grond}
              />
            </EditField>
            <EditField label={'aflak'}>
              <TextInput
                textarea
                onBlur={verf_waterlijn_aflak =>
                  patchMaintenance({ verf_waterlijn_aflak })
                }
                disabled={disabled}
                value={maintenance.verf_waterlijn_aflak}
              />
            </EditField>
            <EditField label={'kleur'}>
              <TextInput
                textarea
                onBlur={verf_waterlijn_kleur =>
                  patchMaintenance({ verf_waterlijn_kleur })
                }
                disabled={disabled}
                value={maintenance.verf_waterlijn_kleur}
              />
            </EditField>
          </dl>

          <h3 className="mt-3">Onderwaterschip</h3>

          <dl className="row">
            <EditField label={'grond'}>
              <TextInput
                textarea
                onBlur={verf_onderwaterschip_grond =>
                  patchMaintenance({ verf_onderwaterschip_grond })
                }
                disabled={disabled}
                value={maintenance.verf_onderwaterschip_grond}
              />
            </EditField>
            <EditField label={'antifouling'}>
              <TextInput
                textarea
                onBlur={verf_onderwaterschip_antifouling =>
                  patchMaintenance({ verf_onderwaterschip_antifouling })
                }
                disabled={disabled}
                value={maintenance.verf_onderwaterschip_antifouling}
              />
            </EditField>
            <EditField label={'kleur'}>
              <TextInput
                textarea
                onBlur={verf_onderwaterschip_kleur =>
                  patchMaintenance({ verf_onderwaterschip_kleur })
                }
                disabled={disabled}
                value={maintenance.verf_onderwaterschip_kleur}
              />
            </EditField>
          </dl>

          <h3 className="mt-3">Interieur</h3>

          <dl className="row">
            <EditField label={'grond'}>
              <TextInput
                textarea
                onBlur={verf_interieur_grond =>
                  patchMaintenance({ verf_interieur_grond })
                }
                disabled={disabled}
                value={maintenance.verf_interieur_grond}
              />
            </EditField>
            <EditField label={'aflak'}>
              <TextInput
                textarea
                onBlur={verf_interieur_aflak =>
                  patchMaintenance({ verf_interieur_aflak })
                }
                disabled={disabled}
                value={maintenance.verf_interieur_aflak}
              />
            </EditField>
            <EditField label={'kleur'}>
              <TextInput
                textarea
                onBlur={verf_interieur_kleur =>
                  patchMaintenance({ verf_interieur_kleur })
                }
                disabled={disabled}
                value={maintenance.verf_interieur_kleur}
              />
            </EditField>
          </dl>

          <h3 className="mt-3">Overig</h3>

          <dl className="row">
            <EditField label={'extra'}>
              <TextInput
                textarea
                onBlur={verf_extra => patchMaintenance({ verf_extra })}
                disabled={disabled}
                value={maintenance.verf_extra}
              />
            </EditField>
            <EditField label={'verfhistorie'}>
              <TextInput
                textarea
                onBlur={verf_historie => patchMaintenance({ verf_historie })}
                disabled={disabled}
                value={maintenance.verf_historie}
              />
            </EditField>
          </dl>
        </div>
      </div>
    )
  }
}

// Redux connect

const makeMapState = () => {
  const mapState = (state: ReduxState, ownProps: OwnProps): S => {
    const { boatId } = ownProps
    const maintenance = state.resources.boatLegacyMaintenance[boatId]
    return {
      maintenance
    }
  }
  return mapState
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  const { boatId } = ownProps
  return {
    loadMaintenance: () => dispatch(getBoatMaintenance(boatId)),
    patchMaintenance: (updates: ?$Shape<Maintenance>) =>
      dispatch(
        patchBoatMaintenance(
          boatId,
          _.mapValues(updates, value => (_.isEmpty(value) ? null : value))
        )
      )
  }
}

export default connect(
  makeMapState,
  mapDispatch
)(PaintHistory)
