// @flow
import memoizeOne from 'memoize-one'
import type { BillLineItem as BLI, Product, State, StoreSlice } from '../types'

export const MAX_BLI_DESC_LENGTH = 40

// SELECTORS

export const sortByBookedAt = (a: BLI, b: BLI): number => {
  if (a.bookedAt === b.bookedAt) {
    return new Date(a.createdAt) - new Date(b.createdAt)
  } else {
    return new Date(a.bookedAt) - new Date(b.bookedAt)
  }
}

type BillIdProps = { billId: string }
type TaskIdProps = { workOrderTaskId: string }
type WorkOrderIdProps = { workOrderId: string }
type ParentIdProps = { parentId: string }

export const getBLIsById = (state: State) => state.resources.billLineItems.byId
export const getIdsByTask = (state: State, props: TaskIdProps) =>
  state.resources.billLineItems.idsByTask[props.workOrderTaskId]
export const getIdsByBill = (state: State, props: BillIdProps) =>
  state.resources.billLineItems.idsByBill[props.billId]
export const getIdsByWorkOrder = (state: State, props: WorkOrderIdProps) =>
  state.resources.billLineItems.idsByWorkOrder[props.workOrderId]
export const getIdsByParent = (state: State, props: ParentIdProps) =>
  state.resources.billLineItems.idsByParent[props.parentId]

export const makeGetBLIsForTaskId = (): ((State, TaskIdProps) => BLI[]) => {
  const sort = memoizeOne(blis => blis.sort(sortByBookedAt))
  const getBLIs = memoizeOne(
    (ids: string[], byId: StoreSlice<BLI>): BLI[] => {
      if (ids == null) return []
      return sort(ids.map(id => byId[id]))
    }
  )
  return memoizeOne((state: State, props: TaskIdProps) =>
    getBLIs(getIdsByTask(state, props), getBLIsById(state))
  )
}
export const makeGetBLIsForWorkOrderId = (): ((
  State,
  WorkOrderIdProps
) => BLI[]) => {
  const sort = memoizeOne(blis => blis.sort(sortByBookedAt))
  const getBLIs = memoizeOne(
    (ids: string[], byId: StoreSlice<BLI>): BLI[] => {
      if (ids == null) return []
      return sort(ids.map(id => byId[id]))
    }
  )
  return memoizeOne((state: State, props: WorkOrderIdProps) =>
    getBLIs(getIdsByWorkOrder(state, props), getBLIsById(state))
  )
}
export const makeGetBLIsForBillId = (): ((State, BillIdProps) => BLI[]) => {
  const filterAndSort = memoizeOne(blis =>
    blis.filter(d => d.parentId == null).sort(sortByBookedAt)
  )
  const getBLIs = memoizeOne(
    (ids: string[], byId: StoreSlice<BLI>): BLI[] => {
      if (ids == null) return []
      return filterAndSort(ids.map(id => byId[id]))
    }
  )
  return memoizeOne((state: State, props: BillIdProps) =>
    getBLIs(getIdsByBill(state, props), getBLIsById(state))
  )
}

export const makeGetBLIsForParentId = (): ((State, ParentIdProps) => BLI[]) => {
  const sort = memoizeOne(blis => blis.sort(sortByBookedAt))
  const getBLIs = memoizeOne(
    (ids: string[], byId: StoreSlice<BLI>): BLI[] => {
      if (ids == null) return []
      return sort(ids.map(id => byId[id]))
    }
  )
  return memoizeOne((state: State, props: { parentId: string }) =>
    getBLIs(getIdsByParent(state, props), getBLIsById(state))
  )
}

export const selectBLIsByTaskId = makeGetBLIsForTaskId()
export const selectBLIsByParentId = makeGetBLIsForParentId()
export const selectBLIsByWorkOrderId = makeGetBLIsForWorkOrderId()
export const selectBLIsByBillId = makeGetBLIsForBillId()

// DERIVED BLI VALUES

const roundToNearest = (nearest: number) => (value: number) =>
  Math.round(value / nearest) * nearest

export const netTotal = (bli: BLI): number => {
  const { price, quantity, discountPercentage, priceUnit } = bli
  if (discountPercentage == null) return (price * quantity) / priceUnit
  return (price * quantity * (1 - discountPercentage)) / priceUnit
}

export const netCalculatedLinePrice = (bli: BLI): number => {
  const { price, quantity, priceUnit } = bli
  return (price * quantity) / priceUnit
}

export const grossCalculatedLinePrice = (bli: BLI): number => {
  return roundToNearest(0.05)(
    netCalculatedLinePrice(bli) * (1 + bli.taxPercentage)
  )
}

export const taxTotal = (bli: BLI): number => {
  return grossTotal(bli) - netTotal(bli)
}

export const grossTotal = (bli: BLI): number => {
  const { taxPercentage } = bli
  return roundToNearest(0.05)(netTotal(bli) * (1 + taxPercentage))
}

export const breakEvenPrice = (bli: BLI): number => {
  const { costPrice, quantity, priceUnit } = bli
  if (costPrice == null) return 0
  return (costPrice * quantity) / priceUnit
}

export const margin = (bli: BLI): number => {
  if (breakEvenPrice(bli) === 0) return 0
  return (netTotal(bli) - breakEvenPrice(bli)) / breakEvenPrice(bli)
}

// For some products we will hide the fact that everything is measured
// in 100s
export const hiddenFactor100 = (bli: ?(BLI | Product)) => {
  return bli != null && bli.priceUnit === 100
}
