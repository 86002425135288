// @flow
import _ from 'lodash'
import localforage from 'localforage'
import { createMigrate } from 'redux-persist'

// This counters a bug in Safari where the IndexedDB completely blows up in
// size with every reload from the backend
// 1. Purge the redux-persistor
// 2. Actually fetch the item to clear some IndexedDB/localforage cache ??
// 3. It works~! We can reload now
// (I am very sorry, but I cannot make sense of *why* this works.)
// TODO: figure out why this fixes things
export const purge = async (persistor: any) => {
  await persistor.pause()
  await persistor.flush()
  await persistor.purge()
  await localforage.getItem('persist:root').catch()
  await persistor.persist()
}

const reset = () => {
  localforage.removeItem('persist:root')
  window.localStorage.removeItem('jachthaven-accessToken')
  window.location.href = `${window.location.protocol}//${window.location.host}`
}

const migrations = {
  '0': state => _.defaultsDeep(state, {}),
  '1': reset,
  '2': reset
}

export const persistConfig = (key: string, options?: Object = {}) => ({
  key,
  storage: localforage,
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  ...options
})
