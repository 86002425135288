// @flow

import type { SyncAction } from '../types'

type State = {
  [id: string]: { latestRevision: string }
}

const defaultState = {}

export default function syncLog(
  state: State = defaultState,
  action: SyncAction
) {
  switch (action.type) {
    case 'SET_LATEST_REVISION': {
      return {
        ...state,
        [action.resource]: {
          ...state[action.resource],
          latestRevision: action.latestRevision
        }
      }
    }
    default:
      return state
  }
}
