// @flow
import * as React from 'react'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'

import { DateString } from '../TextUtils'
import Confirmation from '../Confirmation'

import NumberInput from '../Input/NumberInput'
import PercentageInput from '../Input/PercentageInput'
import CurrencyInput from '../Input/CurrencyInput'
import Input from '../Input/TextInput'
import UserSelect from '../Select/UserSelect'
import { ProductRow } from '../BillLineItem/BillLineItemRows'
import ChildRows from './ChildRows'
import TT from '../Tooltip'

import {
  grossCalculatedLinePrice,
  grossTotal,
  hiddenFactor100,
  isAdmin
} from '../../utils'
import {
  deleteBillLineItems,
  changeBLIField,
  splitBillLineItem,
  getProduct
} from '../../actions'

import type {
  Dispatch,
  BillLineItem as BLIType,
  Product,
  State,
  WorkOrderTask,
  WithRouterProps as R
} from '../../types'

type OwnProps = {
  billLineItem: BLIType,
  selected?: boolean,
  onClick: () => void,
  showTaskLink?: boolean,
  startSaving?: () => void,
  stopSaving?: () => void
}
type S = {
  admin?: boolean,
  hasChildren: boolean,
  product: ?Product,
  canDelete: boolean,
  taskLink: ?string,
  task: ?WorkOrderTask
}
type D = {
  removeBLI: () => Promise<void>,
  splitBLI: () => Promise<void>,
  changeField: (
    field: $Keys<BLIType>
  ) => (value: string | number | null) => Promise<void>,
  getProduct: (id: number) => Promise<void>
}
export type BLIProps = OwnProps & S & D & R

export const LineItemsHeader = (
  { taskLink }: { taskLink: ?boolean } = { taskLink: false }
) => (
  <thead>
    <tr className="bill-line-item">
      <th className="bli-date">datum</th>
      <th className="bli-assignee">wie</th>
      <th className="bli-productCode">artikelcode</th>
      <th className="bli-description">omschrijving</th>
      <th className="bli-price">stuksprijs</th>
      <th className="bli-priceUnit" />
      <th className="bli-quantity">aantal</th>
      <th className="bli-calculatedLinePrice" />
      <th className="bli-discountPercentage">korting</th>
      <th className="bli-grossTotal">prijs</th>
      <th className="bli-action-buttons" />
      {taskLink && <th className="bli-origin-link" />}
    </tr>
  </thead>
)

const ActionButtons = ({
  billLineItem: bli,
  hasChildren,
  splitBLI,
  removeBLI,
  canDelete
}: BLIProps) => {
  if (bli.state === 'invoiced')
    return (
      <TT
        tip="Gefactureerd (en kan niet meer bewerkt)"
        render={id => (
          <i id={id} className="material-icons text-success">
            check
          </i>
        )}
      />
    )
  if (hasChildren)
    return (
      <TT
        tip="Regel splitsen"
        render={id => (
          <span id={id} className="bli-split clickable" onClick={splitBLI}>
            <i className="material-icons">call_split</i>
          </span>
        )}
      />
    )
  if (bli.parentId != null)
    return (
      <TT
        tip="Deze regel is gegroepeerd en kan niet bewerkt worden"
        render={id => (
          <span
            id={id}
            title="Deze regel is gegroepeerd en kan niet bewerkt worden"
          >
            <i className="material-icons">error_outline</i>
          </span>
        )}
      />
    )
  if (bli.state === 'open')
    return (
      <Confirmation
        title="Orderregel verwijderen"
        text={`Hiermee wordt de orderregel verwijderd. Dit kan alleen ongedaan worden gemaakt door alle niet-opgeslagen wijzigigen terug te draaien.`}
        onConfirm={removeBLI}
        render={onClick => (
          <TT
            tip={canDelete ? 'Regel verwijderen' : 'Kan niet verwijeren'}
            render={id => (
              <span
                id={id}
                className="bli-delete"
                onClick={() => {
                  if (canDelete) {
                    return onClick()
                  }
                  toast.error(
                    'Kan de regel niet verwijderen als de taak al is afgesloten.'
                  )
                }}
              >
                <i className="material-icons">delete</i>
              </span>
            )}
          />
        )}
      />
    )
  return null
}

export const CurrencyDisabledInput = ({ value }: { value: number }) => (
  <CurrencyInput
    disabled
    value={value}
    className="bli-form-control"
    onBlur={() => Promise.resolve()}
  />
)

class BillLineItem extends React.Component<BLIProps> {
  componentDidMount() {
    const { productId } = this.props.billLineItem
    this.props.getProduct(productId)
  }

  async splitBLI() {
    const { startSaving, stopSaving } = this.props
    startSaving != null && startSaving()
    await this.props.splitBLI()
    stopSaving != null && stopSaving()
  }

  render() {
    const { billLineItem: bli } = this.props
    const disabled =
      bli.state === 'invoiced' || bli.parentId != null || !this.props.admin
    return [
      <tr
        key={`bli-${bli.id}`}
        className={_([
          'bill-line-item',
          `state-${bli.state}`,
          this.props.selected ? 'selected' : undefined,
          bli.parentId != null ? 'disabled' : undefined
        ])
          .compact()
          .join(' ')}
        title={
          bli.parentId != null
            ? 'Deze regel is gegroepeerd en kan niet bewerkt worden'
            : undefined
        }
        onClick={!disabled ? this.props.onClick : undefined}
      >
        <td className="bli-date">
          <DateString>{bli.bookedAt}</DateString>
        </td>
        <td className="bli-assignee">
          <UserSelect
            value={bli.userId}
            onSelect={this.props.changeField('userId')}
            disabled={disabled}
          />
        </td>
        <td className="bli-productCode">{bli.productCode}</td>
        <td className="bli-description">
          <Input
            value={bli.description}
            className="bli-form-control"
            disabled={disabled}
            onBlur={this.props.changeField('description')}
          />
        </td>
        <td className="bli-price">
          <CurrencyInput
            disabled={disabled}
            value={bli.price * (1 + bli.taxPercentage)}
            className="bli-form-control"
            onBlur={this.props.changeField('price')}
          />
        </td>
        <td className="bli-priceUnit">
          {bli.priceUnit !== 1 && !hiddenFactor100(bli) ? (
            <span className="small text-muted">{'/' + bli.priceUnit}</span>
          ) : null}
        </td>
        <td className="bli-quantity">
          <NumberInput
            onBlur={this.props.changeField('quantity')}
            className="bli-form-control"
            value={bli.quantity}
            roundingPrecision={1 / bli.priceUnit}
            disabled={disabled}
            factor={hiddenFactor100(bli) ? 100 : 1}
          />
        </td>
        <td className="bli-calculatedLinePrice">
          <CurrencyDisabledInput value={grossCalculatedLinePrice(bli)} />
        </td>
        <td className="bli-discountPercentage">
          <PercentageInput
            onBlur={this.props.changeField('discountPercentage')}
            className="bli-form-control"
            value={bli.discountPercentage || null}
            disabled={disabled}
            allowNull={false}
          />
        </td>
        <td className="bli-grossTotal">
          <CurrencyDisabledInput value={grossTotal(bli)} />
        </td>
        <td className="bli-action-buttons">
          <ActionButtons {...this.props} splitBLI={this.splitBLI.bind(this)} />
        </td>
        <td className="bli-origin-link">
          {this.props.taskLink != null && this.props.task != null && (
            <Link to={`/work-orders/${this.props.taskLink}`}>
              <TT
                tip={`${this.props.task.name} (Bekijk deze taak)`}
                render={id => (
                  <div
                    id={id}
                    className="small"
                    style={{
                      maxWidth: '2.5rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                  >
                    {this.props.task != null && this.props.task.name}
                  </div>
                )}
              />
            </Link>
          )}
        </td>
      </tr>,
      <ProductRow {...this.props} key="product" />,
      <ChildRows {...this.props} key="children" />
    ]
  }
}

const mapState = (state: State, ownProps: OwnProps): S => {
  const { billLineItem } = ownProps
  const { productId, workOrderId, workOrderTaskId } = billLineItem
  const { products, workOrders, workOrderTasks } = state.resources
  const hasChildren =
    state.resources.billLineItems.idsByParent[billLineItem.id] != null &&
    state.resources.billLineItems.idsByParent[billLineItem.id].length > 0
  const product = productId != null ? products[productId] : null
  const workOrder = workOrderId != null ? workOrders[workOrderId] : null
  const task = workOrderTaskId != null ? workOrderTasks[workOrderTaskId] : null
  const taskLink = ownProps.showTaskLink ? workOrderId : null
  const admin = isAdmin(state)
  const canDelete =
    admin &&
    (workOrder == null ||
      task == null ||
      (workOrder.state === 'open' && task.state === 'open'))
  return {
    hasChildren,
    product,
    task,
    taskLink,
    canDelete,
    admin
  }
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps & R): D => {
  const id: string = ownProps.billLineItem.id
  return {
    changeField: (field: $Keys<BLIType>) => (value: string | number | null) =>
      dispatch(changeBLIField(id, field, value)),
    removeBLI: () => dispatch(deleteBillLineItems([id])),
    splitBLI: () => dispatch(splitBillLineItem(id)),
    getProduct: (id: number) => dispatch(getProduct(id))
  }
}

export default withRouter<OwnProps>(
  connect(
    mapState,
    mapDispatch
  )(BillLineItem)
)
