// @flow
import * as React from 'react'
import { Input } from 'reactstrap'

import type { ListFilters } from '../../types'
type OwnProps = {
  +filters: ListFilters,
  setFilters: ListFilters => void,
  placeholder?: string
}
type Props = OwnProps

class SearchBar extends React.Component<Props> {
  filterOnChange({
    currentTarget: { value }
  }: SyntheticEvent<HTMLInputElement>) {
    const filters = { ...this.props.filters, q: value }
    return this.props.setFilters(filters)
  }

  render() {
    return (
      <Input
        className="form-control filter-input"
        placeholder={
          this.props.placeholder != null ? this.props.placeholder : 'Filter...'
        }
        value={this.props.filters.q || ''}
        onChange={this.filterOnChange.bind(this)}
      />
    )
  }
}

export default SearchBar
