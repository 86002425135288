// @flow
import apiService from '../services/feathers'
import _ from 'lodash'
import uuid from 'uuid/v4'
import { toast } from 'react-toastify'
import { getProduct, findProducts } from './'
import {
  breakEvenPrice,
  netTotal,
  selectBLIsByParentId,
  MAX_BLI_DESC_LENGTH
} from '../utils'

import type {
  BillLineItem,
  Dispatch,
  GetState,
  StoreSlice,
  Product
} from '../types'

type MultiPatchType = $Shape<BillLineItem> & { id: string }

/* -----------------
    REST OPERATIONS
   ----------------- */

export function createBillLineItems(
  items: BillLineItem[] | StoreSlice<BillLineItem>
) {
  return async (dispatch: Dispatch): Promise<BillLineItem[]> => {
    try {
      await apiService.ready
      const billLineItems = await Promise.all(
        _.map(items, async item => apiService.billLineItems.create(item))
      )
      return billLineItems
    } catch (err) {
      console.error(err)
      dispatch({ type: 'CREATE_ERROR' })
      return err
    }
  }
}

export function patchBillLineItems(items: MultiPatchType[]) {
  return async (dispatch: Dispatch): Promise<BillLineItem[]> => {
    try {
      await apiService.ready
      const billLineItems = await Promise.all(
        _.map(items, async bli => {
          const result = await apiService.billLineItems
            .patch(bli.id, _.omit(bli, 'state'))
            .catch(error => {
              if (error.code === 404) {
                console.warn('Gracefully handled error:', error)
                // If not found we assume it's already deleted
                dispatch({
                  type: 'DELETE_RESOURCES_SUCCESS',
                  resource: 'BILL_LINE_ITEM',
                  ids: [bli.id]
                })
              } else {
                return error
              }
            })
          return result
        })
      )
      return _.compact(billLineItems)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'UPDATE_ERROR', error: err })
      return err
    }
  }
}

export function deleteBillLineItems(
  ids: string[]
): Dispatch => Promise<string[]> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      await Promise.all(
        _.map(ids, async id => {
          await apiService.billLineItems.remove(id).catch(error => {
            if (error.code === 404) {
              console.warn('Gracefully handled error:', error)
              // If not found we assume it's already deleted
              dispatch({
                type: 'DELETE_RESOURCES_SUCCESS',
                resource: 'BILL_LINE_ITEM',
                ids: [id]
              })
            } else {
              return error
            }
          })
        })
      )
      return ids
    } catch (err) {
      console.error(err)
      dispatch({ type: 'DELETE_ERROR', message: err.message })
      return err
    }
  }
}

/* ------------------
    OTHER OPERATIONS
   ------------------ */

const newGroupingParent = (
  product: Product,
  metadata: $Shape<BillLineItem>
): BillLineItem => ({
  ...metadata,
  id: uuid(),
  priceUnit: product.priceUnit,
  standardPrice: 1,
  productCode: product.productCode,
  productId: product.id,
  description: product.name,
  productGroupId: product.productGroupId,
  taxPercentage: Number(product.vatPercentage),
  costPrice: 0, // will get set by children
  price: 0, // will get set by children
  quantity: product.priceUnit === 100 ? 100 : 1,
  discountPercentage: 0
})

export function groupBillLineItems(
  ids: string[]
): (Dispatch, GetState) => Promise<void> {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { billLineItems } = getState().resources
    const items: BillLineItem[] = ids.map(id => billLineItems.byId[id])
    try {
      await dispatch(
        findProducts({ ids: { $in: items.map(d => d.productId) } })
      )
      const { products } = getState().resources
      const productGroups = _.map(items, 'productGroupId')

      let groupingProducts = _.keyBy(
        await dispatch(
          findProducts({
            query: {
              isGroupingParent: true,
              productGroupId: { $in: productGroups }
            }
          })
        ),
        'productGroupId'
      )

      // Business logic:
      // - Add ids together based on their productCategoryId
      // - The BLI to use as a parent is connected to a groupingProduct
      // - This groupingProduct is determined by the productCategory and
      //   passed on here
      // - If no such groupingProduct exists, only add items with the same
      //   product together
      // - If an already grouped item exists, keep it, since it might contain
      //   changes made by the user. Add any new to-be-grouped items with this
      //   groupingProduct to this BLI, and update its values accordingly.
      //
      // Implementation:
      // - Separate parent BLIs from non-parent BLIs
      // - Combine existing parents with the same product
      // - For each non-parent, find its groupingProduct. If none exists, use
      //   the BLIs existing associated productl
      // - Group the non-parents on their groupingProduct
      // - Check if there already is a parent with this groupingProduct. If so,
      //   add the items to this parent and update
      // - If no such parent exists, create one

      const [parents, toGroupItems]: [
        BillLineItem[],
        BillLineItem[]
      ] = _.partition(items, d => billLineItems.idsByParent[d.id] != null)

      let updatedChildren: StoreSlice<BillLineItem[]> = {}
      let updatedParents: StoreSlice<BillLineItem> = {}
      let createdParents: StoreSlice<BillLineItem> = {}
      let removedParents: string[] = []

      // Combine existing parents with same productId
      const mergedParents: StoreSlice<BillLineItem> = _(parents)
        .groupBy('productId')
        .mapValues((group: BillLineItem[]) => {
          if (group.length === 1) return _.first(group)
          const parent = {
            ..._.first(group), // copy most from the first item
            costPrice: _.sumBy(group, breakEvenPrice),
            price: _.sumBy(group, netTotal)
          }
          const allChildren = _.flatMap(
            group,
            d => billLineItems.idsByParent[d.id]
          ).map(id => billLineItems.byId[id])

          // 1. assemble the new children
          updatedChildren[parent.id] = allChildren
          // 2. set this parent to be updated later
          updatedParents[parent.id] = parent
          // remove all parents except the first
          removedParents = [...removedParents, ...group.slice(1).map(d => d.id)]
          return parent
        })
        .value()

      // Group items based on the grouping product of the productGroup
      // if this productGroup has no groupingProduct, group by actual product
      const groups: StoreSlice<BillLineItem[]> = _.groupBy(toGroupItems, d => {
        const groupingProduct = groupingProducts[d.productGroupId]
        return groupingProduct != null ? groupingProduct.id : d.productId
      })

      // For each group of children, add them to a parent and update and update
      // the parent
      // 0. If the group is just one item, it should stay as is
      // 1. Find a parent or create one
      // 2. Keep track of which parentId to add to the children later
      // 3. Update the parent fields that depend on children fields
      _.forEach(groups, (group: BillLineItem[], groupProductId: string) => {
        if (group.length === 1 && mergedParents[groupProductId] == null) return

        // Find parent or create one
        let parent = mergedParents[groupProductId]
        if (parent == null) {
          const metadata = _.pick(group[0], ['billId', 'boatId', 'customerId'])
          const parentProduct = products[groupProductId]
          parent = newGroupingParent(parentProduct, metadata)
          createdParents[parent.id] = parent
        }

        // Keep track of parentIds to update for these children
        updatedChildren[parent.id] = [
          ...(updatedChildren[parent.id] || []),
          ...group
        ]
        // Gather all the new and old children of this parent
        const children = _.union(
          selectBLIsByParentId(getState(), { parentId: parent.id }),
          updatedChildren[parent.id]
        )
        // update values of the parent based on the new children
        // - add breakEvenPrice from NEW children to current costPrice
        // - add netTotals from NEW children to current price
        // - use the lowest bookedAt date from ALL children as bookedAt date
        updatedParents[parent.id] = {
          ...parent,
          costPrice: parent.costPrice + _.sumBy(group, breakEvenPrice),
          price: parent.price + _.sumBy(group, netTotal),
          bookedAt: _.minBy(children, 'bookedAt').bookedAt,
          workOrderId:
            _.uniqBy(children, 'workOrderId').length === 1
              ? children[0].workOrderId
              : null,
          workOrderTaskId:
            _.uniqBy(children, 'workOrderTaskId').length === 1
              ? children[0].workOrderTaskId
              : null
        }
      })

      // Now we're ready to save everything
      const toPatchParentIds = _.flatMap(
        updatedChildren,
        (children: BillLineItem[], parentId: string) =>
          children.map(d => ({ id: d.id, parentId }))
      )
      await dispatch(createBillLineItems(_.values(createdParents)))
      await dispatch(patchBillLineItems(_.values(updatedParents)))
      await dispatch(patchBillLineItems(toPatchParentIds))
      await dispatch(deleteBillLineItems(removedParents))
    } catch (err) {
      console.error(err)
      dispatch({ type: 'PATCH_ERROR', error: err })
      return err
    }
  }
}

export function groupBillLineItemsByTask(
  ids: string[]
): (Dispatch, GetState) => Promise<void> {
  return async (dispatch: Dispatch, getState: GetState) => {
    let { billLineItems } = getState().resources

    // When grouping by task, we do:
    // - first remove all existing parents
    // - then group items by task
    // - group items as normal per task group
    const allChildIds: string[] = _.compact(
      _.flatMap(ids, id => billLineItems.idsByParent[id])
    )
    const [nonParentIds, parentIds] = _.partition(ids, id =>
      _.isEmpty(billLineItems.idsByParent[id])
    )
    await dispatch(
      patchBillLineItems(allChildIds.map(id => ({ id, parentId: null })))
    )
    await dispatch(deleteBillLineItems(parentIds))
    const toGroupIds = allChildIds.concat(nonParentIds)
    const tasks = _.groupBy(
      toGroupIds,
      id => billLineItems.byId[id].workOrderTaskId
    )
    await Promise.all(
      _.map(tasks, group => dispatch(groupBillLineItems(group)))
    )
    return
  }
}

export function splitBillLineItem(
  id: string
): (Dispatch, GetState) => Promise<void> {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { billLineItems } = getState().resources
    const childIds = billLineItems.idsByParent[id]
    if (childIds == null || childIds.length === 0) return
    try {
      await dispatch(
        patchBillLineItems(childIds.map(id => ({ id, parentId: null })))
      )
      await dispatch(deleteBillLineItems([id]))
    } catch (err) {
      console.error('splitBillLineItem', err)
      return err
    }
  }
}

export function resetBLIDescription(
  bli: BillLineItem
): (Dispatch, GetState) => Promise<void> {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (bli != null) {
      const { productId } = bli
      let product = getState().resources.products[productId]
      if (product == null) {
        product = await dispatch(getProduct(productId))
      }
      if (product != null) {
        const { name: description } = product
        await apiService.billLineItems.patch(bli.id, { description })
      }
    }
  }
}

export function changeBLIField(
  id: string,
  field: $Keys<BillLineItem>,
  value: $Values<BillLineItem>
): (Dispatch, GetState) => Promise<void> {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { billLineItems } = getState().resources
    try {
      let bli = billLineItems[id]
      if (bli == null) {
        bli = await apiService.billLineItems.get(id)
      }
      if (field === 'description') {
        if (_.isEmpty(value)) {
          return dispatch(resetBLIDescription(bli))
        } else if (String(value).length > MAX_BLI_DESC_LENGTH) {
          toast.warning(
            `De omschrijving mag maximaal ${MAX_BLI_DESC_LENGTH} tekens lang zijn.`
          )
          await apiService.billLineItems.patch(id, {
            description: String(value).substring(0, MAX_BLI_DESC_LENGTH)
          })
          return
        }
      }
      if (field === 'price') {
        let price
        if (value == null) {
          // reset price
          const children = selectBLIsByParentId(getState(), { parentId: id })
          if (children != null && !_.isEmpty(children)) {
            price = _.sumBy(children, netTotal)
          } else {
            price = bli.standardPrice
          }
        } else {
          price = _.round(Number(value) / (1 + Number(bli.taxPercentage)), 4)
        }
        await apiService.billLineItems.patch(id, { price })
        return
      }
      await apiService.billLineItems.patch(id, {
        // $FlowFixMe
        [field]: value
      })
    } catch (error) {
      console.error(error)
      return error
    }
  }
}

export function addBillLineItemToBill(
  bli: BillLineItem,
  billId: string
): (Dispatch, GetState) => Promise<void> {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { bills } = getState().resources
    const bill = bills[billId]
    const { boatId, customerId } = bill
    return dispatch(
      createBillLineItems([
        {
          ...bli,
          boatId,
          customerId,
          billId
        }
      ])
    )
  }
}

export function addBillLineItemToTask(
  bli: BillLineItem,
  workOrderTaskId: string
): (Dispatch, GetState) => Promise<void> {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { workOrders, workOrderTasks } = getState().resources
    const task = workOrderTasks[workOrderTaskId]
    const { workOrderId } = task
    const workOrder = workOrders[task.workOrderId]
    const { boatId, customerId, billId } = workOrder
    return dispatch(
      createBillLineItems([
        {
          ...bli,
          workOrderTaskId,
          workOrderId,
          boatId,
          customerId,
          billId
        }
      ])
    )
  }
}
