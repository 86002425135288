// @flow
import * as React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberInput from '../Input/NumberInput'
import TextInput from '../Input/TextInput'
import SelectInput from '../Input/SelectInput'
import StandSelect from '../Select/StandSelect'

import TT from '../Tooltip'
import { patchBoat } from '../../actions'
import {
  boatCategory,
  boatSurfaceArea,
  boatMaterial,
  isAdmin
} from '../../utils'

import type { Boat, Dispatch, State as ReduxState } from '../../types'
type OwnProps = {
  boat: Boat
}
type M = {
  admin: boolean
}
type D = {
  patchBoat: ($Shape<Boat>) => Promise<void>
}
type Props = OwnProps & M & D

const EditField = ({
  label,
  children,
  className
}: {
  label: string,
  children: React.Node,
  className?: string
}) => (
  <React.Fragment>
    <dt className={['info-block-key editable', className].join(' ')}>
      {label}
    </dt>
    <dd className={['info-block-value mb-0', className].join(' ')}>
      {children}
    </dd>
  </React.Fragment>
)

const BoatEditable = ({ boat, patchBoat, admin }: Props) => {
  const disabled = !admin
  return (
    <div className={`boat-info-block info-block`}>
      <div className="info-block-title">
        <div className="icon-container">
          <div className="icon">
            <i className="material-icons">directions_boat</i>
          </div>
        </div>
        <div className="title">
          <h4>Details wijzigen</h4>
        </div>
      </div>
      <div className="info-block-body">
        <dl className="row">
          <EditField label={'Naam'}>
            <TextInput
              onBlur={name => patchBoat({ name })}
              disabled={disabled}
              value={boat.name}
            />
          </EditField>
          <EditField label={'Type'}>
            <TextInput
              onBlur={boatType => patchBoat({ boatType })}
              disabled={disabled}
              value={boat.boatType}
            />
          </EditField>
          <EditField label={'Categorie / Soort boot'}>
            <SelectInput
              options={['sail', 'motor', 'open'].map(category => ({
                label: boatCategory({ boatCategory: category }),
                value: category
              }))}
              disabled={disabled}
              value={boat.boatCategory}
              // $FlowFixMe - typing this dynamically is beyond scope atm
              onChange={boatCategory => patchBoat({ boatCategory })}
              className="form-control"
              placeholder="Kies een type"
            />
          </EditField>
          <EditField label={'Afmetingen (in m)'}>
            <div className="d-flex align-items-center">
              <div className="mr-2 w-40px">
                <NumberInput
                  tip="Lengte"
                  disabled={disabled}
                  value={boat.length}
                  onBlur={length =>
                    length != null ? patchBoat({ length }) : Promise.resolve()
                  }
                  allowNull={false}
                  className="text-center"
                />
              </div>
              x
              <div className="mx-2 w-40px">
                <NumberInput
                  tip="Breedte"
                  disabled={disabled}
                  value={boat.beam}
                  onBlur={beam =>
                    beam != null ? patchBoat({ beam }) : Promise.resolve()
                  }
                  allowNull={false}
                  className="text-center"
                />
              </div>
              x
              <div className="ml-2 w-40px">
                <NumberInput
                  tip="Diepte"
                  disabled={disabled}
                  value={boat.draught}
                  onBlur={draught =>
                    draught != null ? patchBoat({ draught }) : Promise.resolve()
                  }
                  allowNull={false}
                  className="text-center"
                />
              </div>
              <TT
                tip={`Oppervlakte`}
                delay={0}
                render={id => (
                  <span id={id} className="ml-2 text-muted">
                    ({boatSurfaceArea(boat)})
                  </span>
                )}
              />
            </div>
          </EditField>
          <EditField label={'Gewicht (kg)'}>
            <NumberInput
              disabled={disabled}
              value={boat.displacement}
              onBlur={displacement =>
                displacement != null
                  ? patchBoat({ displacement })
                  : Promise.resolve()
              }
              allowNull={false}
            />
          </EditField>
          <EditField label={'Bouwjaar'}>
            <NumberInput
              disabled={disabled}
              value={boat.yearOfManufacture}
              onBlur={yearOfManufacture => patchBoat({ yearOfManufacture })}
            />
          </EditField>
          <EditField label={'Opmerkingen'}>
            <TextInput
              textarea
              onBlur={notes => patchBoat({ notes })}
              disabled={disabled}
              value={boat.notes}
            />
          </EditField>
          <EditField label={'Materiaal'}>
            <SelectInput
              options={['steel', 'wood', 'polyester', 'aluminium', 'other'].map(
                material => ({
                  label: boatMaterial({ hullMaterial: material }),
                  value: material
                })
              )}
              disabled={disabled}
              value={boat.hullMaterial}
              // $FlowFixMe - typing this dynamically is beyond scope atm
              onChange={hullMaterial => patchBoat({ hullMaterial })}
              className="form-control"
              placeholder="Kies een type"
            />
          </EditField>
          <EditField label={'Verzekering'}>
            <div className="d-flex align-items-center">
              <div style={{ width: '120px' }}>
                <SelectInput
                  options={['wa', 'all risk', 'wa casco', 'other'].map(
                    insuranceType => ({
                      label: insuranceType,
                      value: insuranceType
                    })
                  )}
                  disabled={disabled}
                  value={boat.insuranceType || ''}
                  // $FlowFixMe - typing this dynamically is beyond scope atm
                  onChange={insuranceType => patchBoat({ insuranceType })}
                  className="form-control"
                  placeholder="Kies een type"
                />
              </div>
              <div className="mx-2" style={{ width: '70px' }}>
                <TextInput
                  tip="Polisnummer"
                  disabled={disabled}
                  value={boat.insurancePolicyNumber}
                  onBlur={insurancePolicyNumber =>
                    patchBoat({ insurancePolicyNumber })
                  }
                />
              </div>
              <TextInput
                onBlur={insuranceCompany => patchBoat({ insuranceCompany })}
                disabled={disabled}
                value={boat.insuranceCompany}
                placeholder="Verzekeraar"
              />
            </div>
          </EditField>
          <EditField label={'Hijsinstructies'}>
            <TextInput
              textarea
              onBlur={slipInstructions => patchBoat({ slipInstructions })}
              disabled={disabled}
              value={boat.slipInstructions}
              placeholder="Hijsinstructies"
            />
          </EditField>
          <EditField label="Gewenste bok">
            <StandSelect
              disabled={disabled}
              value={boat.standId}
              forBoatCategory={boat.boatCategory}
              onSelect={standId => patchBoat({ standId })}
            />
          </EditField>
          <EditField label={'Verf'} className="pt-1">
            <Link to={`/boats/${boat.id}/verfgeschiedenis`}>
              Verfgeschiedenis &gt;
            </Link>
          </EditField>
          <EditField label={'Onderhoud'} className="pt-1">
            <Link to={`/boats/${boat.id}/onderhoud`}>
              Onderhoudsgeschiedenis &gt;
            </Link>
          </EditField>
        </dl>
      </div>
    </div>
  )
}

const mapState = (state: ReduxState): M => {
  return {
    admin: isAdmin(state)
  }
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  const { boat } = ownProps
  return {
    patchBoat: (updates: $Shape<Boat>) => {
      if (_.isEmpty(updates)) return Promise.resolve()
      if (_.every(updates, (value, key) => boat[key] === value))
        return Promise.resolve()
      return dispatch(patchBoat(boat.id, updates))
    }
  }
}

export default connect(
  mapState,
  mapDispatch
)(BoatEditable)
