// @flow
import * as React from 'react'
import _ from 'lodash'
import { currencyString } from '../utils'

export const Newlines = ({ children }: { children: ?string }) =>
  children && children.length > 0
    ? _(children)
        .split('\n')
        .flatMap((line: string, i: number) => [line, <br key={i} />])
        .value()
    : null

export const Currency = ({
  children,
  factor,
  currency
}: {
  children: ?string | ?number,
  factor?: ?number,
  currency?: string
}) => {
  if (children == null) return ''
  const amount = Number(children)
  if (_.isNaN(amount)) return ''
  factor = factor != null && _.isNumber(factor) ? factor : 1
  return currencyString(amount * factor, { currency, locale: 'NL-nl' })
}

export const DateString = ({
  children,
  format = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
}: {
  children: ?string,
  format?: Object
}) => (children ? new Date(children).toLocaleDateString('nl-NL', format) : '')
