// @flow
import * as React from 'react'
import WorkOrderList from '../components/WorkOrder/WorkOrderList'
import WorkOrderSideList from '../components/WorkOrder/WorkOrderSideList'
import WorkOrderDetail from '../components/WorkOrder/WorkOrderDetail'

type Props = {
  workOrderId: ?string
}

const WorkOrders = ({ workOrderId }: Props) => {
  if (workOrderId == null) return <WorkOrderList />
  return (
    <div className="list-and-detail-container">
      <div className="side-list d-print-none">
        <WorkOrderSideList activeWorkOrderId={workOrderId} />
      </div>
      {workOrderId != null ? (
        <div className="detail-page">
          <WorkOrderDetail workOrderId={workOrderId} />
        </div>
      ) : null}
    </div>
  )
}

export default WorkOrders
