// @flow
import apiService from '../services/feathers'
import type {
  Bill,
  BillLineItem,
  Boat,
  Customer,
  Dispatch,
  WorkOrder
} from '../types'

/* --------------------
    REGULAR OPERATIONS
   -------------------- */

export function getBill(billId: string): Dispatch => Promise<Bill> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const bill: Bill = await apiService.bills.get(billId)
      const boatId = bill.boatId
      const customerId = bill.customerId

      const boat = boatId != null ? await apiService.boats.get(boatId) : null
      const customer =
        customerId != null ? await apiService.customers.get(customerId) : null
      const billLineItems: BillLineItem[] = await apiService.billLineItems.find(
        { query: { billId } }
      )
      const workOrders: WorkOrder[] = await apiService.workOrders.find({
        query: { billId }
      })
      dispatch({
        type: 'FIND_RESOURCES_SUCCESS',
        resource: 'BILL',
        data: (bill: Bill)
      })
      if (billLineItems != null) {
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'BILL_LINE_ITEM',
          data: billLineItems.map(bli => (bli: BillLineItem))
        })
      }
      if (boat != null)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'BOAT',
          data: (boat: Boat)
        })
      if (customer != null)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'CUSTOMER',
          data: (customer: Customer)
        })
      if (workOrders != null)
        dispatch({
          type: 'FIND_RESOURCES_SUCCESS',
          resource: 'WORK_ORDER',
          data: (workOrders: WorkOrder[])
        })
      return bill
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_ERROR' })
      return err
    }
  }
}

export function patchBill(
  id: string,
  update: $Shape<Bill>
): Dispatch => Promise<Bill> {
  return async (dispatch: Dispatch) => {
    try {
      await apiService.ready
      const result = apiService.bills.patch(id, update)
      return result
    } catch (err) {
      console.error(err)
      dispatch({ type: 'UPDATE_ERROR' })
      return err
    }
  }
}
