// @flow
import * as React from 'react'
import _ from 'lodash'

type Props = {
  className?: string,
  placeholder?: string,
  options: { value: string, label: string }[],
  onChange: (value: string) => Promise<void>,
  value?: string,
  disabled?: boolean
}

type State = {
  selected: ?string
}

class SelectInput extends React.Component<Props, State> {
  state = {
    selected: null
  }

  onChange({ target }: SyntheticInputEvent<HTMLInputElement>) {
    this.props.onChange(target.value)
  }

  render() {
    const currentOption = _.get(
      _.find(this.props.options, { value: this.props.value }),
      'label'
    )
    return (
      <React.Fragment>
        <select
          className={[this.props.className, 'd-print-none'].join(' ')}
          placeholder={this.props.placeholder}
          onChange={this.onChange.bind(this)}
          value={this.props.value}
          disabled={this.props.disabled}
        >
          {_.map(this.props.options, option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <h6 className="d-none d-print-block m-0 text-muted">{currentOption}</h6>
      </React.Fragment>
    )
  }
}

export default SelectInput
