// @flow
import * as React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ListStateIcon } from '../ListStateIcon'
import { DateString } from '../TextUtils'
import { customerName } from '../../utils'
import {
  workOrderTaskCategories,
  shortWOTCategories
} from '../WorkOrderTask/WorkOrderTask'

import type {
  Boat,
  Customer,
  WorkOrderTask,
  WorkOrder as WorkOrderType,
  WorkOrderProgress
} from '../../types'

type Props = {
  workOrder: WorkOrderType,
  workOrderTasks: WorkOrderTask[],
  boat: Boat,
  customer: Customer,
  progress: WorkOrderProgress
}

type Category = {
  category: string,
  label: string
}

const categoryMap: Category[] = _.map(
  workOrderTaskCategories,
  (value, key) => ({
    category: key,
    label: shortWOTCategories[key]
  })
)

const progressState = (progress: WorkOrderProgress) => {
  if (progress === 'toDo')
    return {
      color: 'primary',
      icon: 'build',
      tip: 'Openstaande taken'
    }
  if (progress === 'readyToInvoice')
    return {
      color: 'sunset',
      icon: 'euro_symbol',
      tip: 'Klaar om te factureren'
    }
  if (progress === 'invoiced')
    return {
      color: 'secondary',
      icon: 'check',
      tip: 'Gefactureerd'
    }
  if (progress === 'empty')
    return {
      color: 'dark',
      icon: 'more_horiz',
      tip: 'Nog geen taken op de werkopdracht'
    }
  return {
    color: 'coral',
    icon: 'warning',
    tip: 'Hier klopt iets niet :('
  }
}

const WorkOrderListItem = (props: Props) => {
  const {
    boat,
    customer,
    workOrderTasks,
    workOrder: { name, id, plannedCompletionAt, openedAt },
    progress
  } = props
  const categories: Category[] = categoryMap
  const allTasks = _.countBy(workOrderTasks, 'category')

  const openTasks = _.countBy(
    _.filter(workOrderTasks, { state: 'open' }),
    'category'
  )
  return (
    <Link
      className={`list-item work-order-list-item state-${progress ||
        'unknown'}`}
      to={`/work-orders/${id}`}
    >
      <div className="col">
        <h4>{name}</h4>
        <div className="d-flex align-items-center">
          <i className="material-icons inline">directions_boat</i>
          <strong>{boat != null ? boat.name : '...'}</strong>
          <span className="ml-1 mr-2">
            {boat != null ? boat.boatType : '...'}
          </span>
          <i className="material-icons inline">person</i>
          {customerName(customer, { withCity: true })}
        </div>
      </div>
      <div className="col-2 px-0 text-right">
        <div className="row mx-0">
          <div className="col">
            {openedAt != null ? <DateString>{openedAt}</DateString> : `—`}
          </div>
          <div className="col">
            {plannedCompletionAt != null ? (
              <DateString>{plannedCompletionAt}</DateString>
            ) : (
              '—'
            )}
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="row">
          {categories.map((category: Category) =>
            allTasks[category.category] != null ? (
              <div className="col px-0 lead text-center" key={category.label}>
                <div className={`category-badge ${category.category}`}>
                  {category.label}
                </div>
                <div className="category-amount">
                  {openTasks[category.category] ? (
                    <span>
                      {openTasks[category.category]}{' '}
                      <span className="text-muted small">
                        / {allTasks[category.category]}
                      </span>
                    </span>
                  ) : (
                    <span>
                      <i className="material-icons inline">check</i>
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div className="col px-0 lead text-center" key={category.label}>
                <div className={`category-badge white text-light`}>
                  {category.label}
                </div>
                <div className="category-amount text-light">0</div>
              </div>
            )
          )}
        </div>
      </div>
      <ListStateIcon state={progress} {...progressState(progress)} />
    </Link>
  )
}

export default WorkOrderListItem
