// @flow
import { decode } from 'jsonwebtoken'
import authentication from '../services/authentication'
import api from '../services/api'
import feathers from '../services/feathers'
import { purge } from '../utils/persist'
import {
  setupServiceListeners,
  reloadRemote,
  loadRemote
} from './database-sync'
import { endpoint } from '..'
import { persistor } from '../store'
import type { Dispatch } from '../types'

const tokenStorageConfig: { storage: Storage, storageKey: string } = {
  storage: window.localStorage,
  storageKey: 'jachthaven-accessToken'
}

export function setUser(token: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: 'SET_TOKEN', token })
    const result = decode(token)
    if (typeof result !== 'object') throw new Error('incorrect token format')
    if (result == null) throw new Error('invalid token')
    const user = result.fields
    dispatch({ type: 'SET_USER', user })
  }
}

export function checkLogin(persistor: any) {
  return async (dispatch: Dispatch) => {
    const token = authentication.connect(
      endpoint,
      tokenStorageConfig
    )
    if (token != null) {
      dispatch(setUser(token))
      try {
        await api.connect(
          token,
          endpoint
        )
        await feathers.connect(
          endpoint,
          tokenStorageConfig
        )
        await dispatch(setupServiceListeners(feathers.app))
        purge(persistor)
        dispatch(reloadRemote(feathers.app))
      } catch (error) {
        console.error(error)
        dispatch({ type: 'LOGIN_FAILED' })
        return false
      }
    }
  }
}

export function login(email: string, password: string) {
  return async (dispatch: Dispatch) => {
    try {
      const success = await authentication.login({ email, password })
      if (!success) {
        dispatch({ type: 'LOGIN_FAILED' })
        return false
      }
      const token: ?string = authentication.getToken()
      if (token == null) throw new Error('Could not find token')
      await api.connect(
        token,
        endpoint
      )
      await feathers.connect(
        endpoint,
        tokenStorageConfig
      )
      dispatch(setUser(token))
      dispatch(setupServiceListeners(feathers.app))
      dispatch(loadRemote(feathers.app))
      return true
    } catch (error) {
      console.error(error)
      dispatch({ type: 'LOGIN_FAILED' })
      return false
    }
  }
}

export function logout() {
  return async (dispatch: Dispatch) => {
    try {
      persistor.pause()
      await persistor.purge()
      await feathers.app.logout()
      await authentication.logout()
    } catch (error) {
      console.error(error)
      dispatch({ type: 'LOGOUT_ERROR' })
    } finally {
      dispatch({ type: 'LOGOUT' })
    }
  }
}
