// @flow
import * as React from 'react'
import _ from 'lodash'
import moment from 'moment'
import DatePicker from 'react-datepicker'

type Props = {|
  placeholder: string,
  dateFormat: string,
  className?: string,
  disabled: boolean,
  onBlur: (date: string | null) => Promise<void>,
  value: ?string
|}

export default class DateInput extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
    dateFormat: 'DD-MM-YYYY',
    placeholder: 'Klik om te wijzigen'
  }

  render() {
    const {
      className,
      dateFormat,
      value,
      placeholder,
      onBlur,
      ...props
    } = this.props
    return (
      <React.Fragment>
        <div className="d-print-none">
          <DatePicker
            className={_.compact(['form-control date-picker', className]).join(
              ' '
            )}
            dateFormat={dateFormat}
            selected={value ? moment(value) : null}
            placeholderText={placeholder}
            onChange={e => {
              if (e == null) {
                return onBlur(null)
              }
              return onBlur(e.toDate().toISOString())
            }}
            {...props}
          />
        </div>
        <div className="d-none d-print-inline-block">
          {moment(value).format(dateFormat)}
        </div>
      </React.Fragment>
    )
  }
}
