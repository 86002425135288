// @flow
import * as React from 'react'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import TT from '../Tooltip'
import Print from '../Print'
import { grossTotal, currencyString } from '../../utils'

import type { BillLineItem, Attachment } from '../../types'

// Types
type Props = {
  billLineItems: BillLineItem[],
  files: Attachment[],
  openFileUpload: Function
}

export default class TaskSummary extends React.Component<Props> {
  total: () => number

  constructor(props: Props) {
    super(props)
    this.total = memoizeOne(this.total.bind(this))
  }

  total() {
    return _.sumBy(
      this.props.billLineItems.filter(d => d.parentId == null),
      grossTotal
    )
  }
  render() {
    const numberOfLines = this.props.billLineItems.length
    const total = this.total()
    const numberOfFiles = this.props.files.length

    return (
      <Print className="bli-summary my-2" print={false}>
        <div className="d-flex justify-content-between align-items-start mx-3">
          <TT
            tip={
              numberOfLines === 1
                ? `Er is 1 boekregel ingevoerd`
                : `Er zijn ${numberOfLines} boekregels ingevoerd`
            }
            render={id => (
              <div id={id} className="d-flex align-items-center mr-4">
                <i className="material-icons mr-1">playlist_add_check</i>
                <span className="text-small">{numberOfLines}</span>
              </div>
            )}
          />

          <TT
            tip={`Er is voor ${currencyString(
              total
            )} aan producten en diensten gebruikt`}
            render={id => (
              <div id={id} className="d-flex align-items-center mr-4">
                <i className="material-icons mr-1">euro_symbol</i>
                <span className="text-small">
                  {currencyString(total, { currency: 'none' })}
                </span>
              </div>
            )}
          />

          <TT
            tip={
              numberOfFiles === 1
                ? `Er is 1 bestand geüpload`
                : `Er zijn ${numberOfFiles} bestanden geüpload`
            }
            render={id => (
              <div id={id} className="d-flex align-items-center mr-4">
                <i className="material-icons mr-1">photo</i>
                <span className="text-small">{numberOfFiles}</span>
              </div>
            )}
          />

          <TT
            tip="Upload bestanden (of sleep ze hier heen)"
            render={id => (
              <div
                id={id}
                className="ml-auto d-flex align-items-center task-action ml-2 clickable"
                onClick={this.props.openFileUpload}
              >
                <i className="material-icons">attach_file</i>
              </div>
            )}
          />
        </div>
      </Print>
    )
  }
}
