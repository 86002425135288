// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { ActionButtons } from './ActionButtons'
import BillLineItem, { LineItemsHeader } from './BillLineItem'
import { TotalRow } from './BillLineItemRows'
import NewBillLineItem from './NewBillLineItem'
import { LoadingOverlay } from '../Loading'

import { isAdmin } from '../../utils'
import {
  addBillLineItemToBill,
  groupBillLineItems,
  groupBillLineItemsByTask
} from '../../actions'

import type {
  Dispatch,
  State as ReduxState,
  BillLineItem as BLIType,
  WithRouterProps as R
} from '../../types'

// Types
type OwnProps = {
  billLineItems: BLIType[],
  createInvoice: string => Promise<void>,
  selected: Set<string>,
  selectBLIs: (ids: Set<string>) => void,
  billId: string
}
type M = {
  admin: boolean
}
type D = {
  addBillLineItem: BLIType => void,
  groupItems: (string[]) => void,
  groupItemsByTask: (string[]) => void
}
type Props = OwnProps & M & D & R
type State = {
  viewInvoiced: boolean,
  saving: boolean
}

class BillLineItems extends React.Component<Props, State> {
  state = {
    viewInvoiced: true,
    saving: false
  }

  toggleSelect(id, event) {
    if (event != null && (event.ctrlKey || event.metaKey)) {
      const current = this.props.selected.has(id)
      if (current) {
        // currently selected, should deselect
        const deselect = new Set(this.props.selected)
        deselect.delete(id)
        return this.props.selectBLIs(deselect)
      } else {
        // currently not selected, should select
        const select = new Set(this.props.selected).add(id)
        return this.props.selectBLIs(select)
      }
    }
  }

  selectAll() {
    const selected = new Set(
      _(this.props.billLineItems)
        .filter(d => d.state !== 'invoiced')
        .map('id')
        .value()
    )
    this.props.selectBLIs(selected)
  }

  selectNone() {
    return this.props.selectBLIs(new Set())
  }

  async groupItems(byTask?: boolean) {
    this.setState({ saving: true })
    if (byTask) {
      await this.props.groupItemsByTask([...this.props.selected])
    } else {
      await this.props.groupItems([...this.props.selected])
    }
    this.selectNone()
    this.setState({ saving: false })
  }

  async createInvoice(orderStatus: string) {
    this.setState({ saving: true })
    await this.props.createInvoice(orderStatus)
    this.selectNone()
    this.setState({ saving: false })
  }

  render() {
    const { billLineItems, addBillLineItem } = this.props
    const lineItemMetaData = _.pick(this.props, [
      'billId',
      'boatId',
      'customerId'
    ])
    return (
      <React.Fragment>
        <div className="my-2 d-flex align-items-end">
          {this.props.admin ? (
            <ActionButtons
              selectAll={this.selectAll.bind(this)}
              selectNone={this.selectNone.bind(this)}
              group={this.groupItems.bind(this)}
              groupAvailable={this.props.selected.size >= 2}
              canInvoice={this.props.selected.size > 0}
              createInvoice={this.createInvoice.bind(this)}
            />
          ) : null}
          <div
            className="clickable-text ml-auto"
            onClick={() =>
              this.setState({ viewInvoiced: !this.state.viewInvoiced })
            }
          >
            {`${
              this.state.viewInvoiced ? 'Verberg' : 'Toon'
            } reeds gefactureerde regels`}
          </div>
        </div>

        <div
          className="my-2 d-flex align-items-end"
          style={{ position: 'relative' }}
        >
          {this.state.saving ? <LoadingOverlay /> : null}
          <table className="table bill-line-items">
            <LineItemsHeader taskLink />
            <tbody>
              {_.map(
                _.filter(
                  billLineItems,
                  d => this.state.viewInvoiced || d.state !== 'invoiced'
                ),
                bli => (
                  <BillLineItem
                    key={bli.id}
                    billLineItem={bli}
                    onClick={event => this.toggleSelect(bli.id, event)}
                    selected={this.props.selected.has(bli.id)}
                    showTaskLink
                    startSaving={() => this.setState({ saving: true })}
                    stopSaving={() =>
                      this.setState({ saving: false }, this.selectNone())
                    }
                  />
                )
              )}
              <TotalRow
                columns={11}
                billLineItems={_.filter(
                  billLineItems,
                  d => this.state.viewInvoiced || d.state !== 'invoiced'
                )}
              />
              {this.props.admin ? (
                <NewBillLineItem
                  loadProducts={false}
                  onCreate={addBillLineItem}
                  requiredData={lineItemMetaData}
                  columns={11}
                />
              ) : null}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }
}

// Redux connect
const mapState = (state: ReduxState): M => {
  return { admin: isAdmin(state) }
}

const mapDispatch = (dispatch: Dispatch, ownProps: OwnProps): D => {
  return {
    addBillLineItem: (bli: BLIType) =>
      dispatch(addBillLineItemToBill(bli, ownProps.billId)),
    groupItems: (ids: string[]) => dispatch(groupBillLineItems(ids)),
    groupItemsByTask: (ids: string[]) => dispatch(groupBillLineItemsByTask(ids))
  }
}
export default withRouter<OwnProps>(
  connect(
    mapState,
    mapDispatch
  )(BillLineItems)
)
