// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import Button from '../Button'
import Print from '../Print'
import {
  WorkOrderTask as Task,
  workOrderTaskCategories
} from '../WorkOrderTask/WorkOrderTask'

import { isAdmin } from '../../utils'
import { addWorkOrderTask } from '../../actions'

import type {
  WorkOrder,
  WorkOrderTask,
  Dispatch,
  State as ReduxState
} from '../../types'

type OwnProps = {
  workOrder: WorkOrder,
  workOrderTasks: ?(WorkOrderTask[]),
  category: string
}
type S = {
  admin: boolean
}
type D = {
  addTask: (category: string) => void
}

type Props = OwnProps & S & D

const WorkOrderCategory = ({
  category,
  workOrder,
  workOrderTasks,
  addTask,
  admin
}: Props) => {
  if (workOrder == null || category == null) return null
  return (
    <Print
      className="container category-container"
      print={workOrderTasks != null && workOrderTasks.length > 0}
    >
      <div className="category-title d-flex my-2 align-item-center">
        <h3 className="d-inline-block m-0 text-surf">
          {_.get(workOrderTaskCategories, category, category)}
        </h3>
        {admin ? (
          <Button
            containerClassName="ml-3"
            onClick={() => {
              if (workOrder.state === 'closed')
                toast.error('Deze werkopdracht is reeds gesloten')
              else addTask(category)
            }}
            className="btn btn-secondary btn-sm text-white"
            innerClassName="d-flex align-items-center"
          >
            <i className="material-icons small mr-1">add</i>
            Nieuw
          </Button>
        ) : null}
      </div>
      {_.map(workOrderTasks, task => (
        <Task key={task.id} task={task} />
      ))}
    </Print>
  )
}

const mapStateToProps = (state: ReduxState): S => {
  return {
    admin: isAdmin(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): D => {
  const {
    workOrder: { id }
  } = ownProps
  return {
    addTask: (category: string) => dispatch(addWorkOrderTask(category, id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderCategory)
