// @flow
import * as React from 'react'

import TextInput from '../Input/TextInput'

// Types
import type { Boat } from '../../types'

type OwnProps = {
  boatId: number,
  boat: Boat,
  patchBoat: ($Shape<Boat>) => Promise<void>,
  admin: boolean
}
type Props = OwnProps

const MaintenanceHistory = (props: Props) => {
  const {
    boat: { maintenanceHistory },
    patchBoat
  } = props
  return (
    <React.Fragment>
      <div className={`maintenance-info-block info-block mb-5`}>
        <div className="info-block-title">
          <div className="icon-container">
            <div className="icon">
              <i className="material-icons">notes</i>
            </div>
          </div>
          <div className="title">
            <h4>Notities onderhoud</h4>
          </div>
        </div>
        <div className="info-block-body">
          <TextInput
            textarea
            onBlur={maintenanceHistory => patchBoat({ maintenanceHistory })}
            value={maintenanceHistory}
            disabled={!props.admin}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MaintenanceHistory
